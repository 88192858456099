import {Card, Breadcrumb} from "antd";
import {Layout} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {Col, Divider, Modal, Input, message, Button} from "antd";
import {useForm, Controller} from "react-hook-form";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import "antd/dist/antd.css";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useMutation} from "react-query";
import {changePassword} from "../services/changePassword";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";

const schema = yup.object().shape({
  oldPass: yup.string().required("Current Password is required").min(8),
  pass: yup.string().required("New Password is required").min(8),
  confirmPass: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("pass"), null], "Passwords must match New Password"),
});

const {Header, Content} = Layout;

export default function ServiceProviderDetails() {
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Change Password");

  useEffect(() => {
    setLoadingText(loading ? "Changing Password..." : "Change Password");
  }, [loading]);

  const history = useHistory();

  const changePasswordMutitaion = useMutation(changePassword, {
    async onSuccess() {
      message.success("Password updated successfully");
      setLoading(false);
      setLoadingText("Change Password");
      history.goBack();
    },
    onError(error) {
      setLoading(false);
      setLoadingText("Change Password");
      message.error(error.response?.data?.error?.message);
    },
  });

  const {handleSubmit, errors, control, clearErrors} = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setLoadingText("Changing Password...");
    await changePasswordMutitaion.mutate(data);
  };

  return (
    <Layout>
      <Header className="breadcrumb-header">
        <Breadcrumb style={{margin: "16px 0"}}>
          <Breadcrumb.Item>
            <Link
              to="/account"
              onClick={() => {
                clearErrors();
              }}
            >
              Account
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Change Password</Breadcrumb.Item>
        </Breadcrumb>
        <h3>
          <ArrowLeftOutlined
            onClick={() => {
              history.goBack();
            }}
          />
          <span style={{marginLeft: 8}}>Change Password</span>
        </h3>
      </Header>

      <div style={{marginTop: "14px"}}>
        <Button
          disabled={loading}
          type="primary"
          onClick={() => {
            Modal.confirm({
              icon: <ExclamationCircleOutlined />,
              title: "Are you sure you want to save changes?",
              okButtonProps: {form: "passForm", key: "submit", htmlType: "submit"},
              async onOk() {},
              onCancel() {},
            });
          }}
          style={{float: "right"}}
        >
          {loadingText}
        </Button>
      </div>

      <Content style={{margin: "24px 0px 0"}}>
        <Card className="site-layout-background" style={{padding: 24, minHeight: "77vh"}}>
          <div>
            <Divider orientation="left">Change Password</Divider>

            <form id="passForm" onSubmit={handleSubmit(onSubmit)}>
              <Col sm={20} md={14} lg={10}>
                <div className="row-padding">
                  <Controller
                    as={
                      <label>
                        Current Password
                        <Input.Password
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </label>
                    }
                    name="oldPass"
                    control={control}
                  />
                  <p className="invalid">{errors.oldPass?.message}</p>
                </div>

                <div className="row-padding">
                  <Controller
                    as={
                      <label>
                        New Password
                        <Input.Password
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </label>
                    }
                    name="pass"
                    control={control}
                  />
                  <p className="invalid">{errors.pass?.message}</p>
                </div>
                <div className="row-padding">
                  <Controller
                    as={
                      <label>
                        Confirm Password
                        <Input.Password
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </label>
                    }
                    name="confirmPass"
                    control={control}
                  />
                  <p className="invalid">{errors.confirmPass?.message}</p>
                </div>
              </Col>
            </form>
          </div>
        </Card>
      </Content>
    </Layout>
  );
}
