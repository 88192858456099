import React, {useState, useEffect} from "react";
import styles from "./Map.module.css";

export default function SearchInput(props) {
  const input = React.useRef(null);
  const [autocomplete, setAutocomplete] = useState(null);
  // if you need to restrict the search result you can use this object
  const options = {
    componentRestrictions: {country: "om"},
  };

  /* istanbul ignore next */
  const handleOnPlacesChanged = React.useCallback(() => {
    if (!autocomplete) return null;
    const place = autocomplete.getPlace();
    if (props.onPlacesChanged) {
      props.onPlacesChanged(place);
    }
  }, [props.onPlacesChanged, autocomplete]);

  useEffect(() => {
    if (input && input.current && props.maps.places) {
      setAutocomplete(new props.maps.places.Autocomplete(input.current, options));
    }
  }, [input]);
  useEffect(() => {
    if (input && input.current && props.maps.places) {
      setAutocomplete(new props.maps.places.Autocomplete(input.current, options));
    }
  }, [props.maps.places]);

  useEffect(() => {
    if (autocomplete && handleOnPlacesChanged) {
      props.maps.event.addListener(autocomplete, "place_changed", handleOnPlacesChanged);
    }
  }, [autocomplete, handleOnPlacesChanged]);

  return (
    <input
      className={props.isOTSP ? styles.searchInputOTSP : styles.searchInput}
      ref={input}
      placeholder="Where are you located?"
    />
  );
}
