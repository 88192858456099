import React from "react";
import ProtectedRoute from "../protectedRoutes";
import {Redirect, Switch} from "react-router-dom";
import Devices from "../views/devices";
import SRPage from "../views/serviceRequest/serviceRequestList";
import SRdetails from "../views/serviceRequest/serviceRequestDetails";
import Account from "../views/account";
import ChangePassword from "../views/changePassword";

const ClientRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute path="/service-requests" exact component={SRPage} />
      <ProtectedRoute
        path="/service-requests/:serviceRequestNumber"
        component={SRdetails}
      />
      <ProtectedRoute path="/devices" exact component={Devices} />
      <ProtectedRoute path="/account" exact component={Account} />
      <ProtectedRoute path="/account/change-password" exact component={ChangePassword} />
      <Redirect from="/" to="service-requests" />
    </Switch>
  );
};
export default ClientRoutes;
