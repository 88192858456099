import ActiveLabel from "./ActiveLabel";
import React from "react";
import {isAdmin} from "../utils/check-role";
const extraAdminFields = [
  {
    title: "Batch",
    dataIndex: "batch",
    key: "batch",
    searchable: true,
  },
];
const columns = [
  {
    title: "Serial Number",
    dataIndex: "serial_number",
    key: "serial_number",
    searchable: true,
  },
  {
    title: "Device Name",
    dataIndex: "device_name",
    key: "age",
    searchable: true,
  },
  {
    title: "SKU",
    dataIndex: "sku",
    key: "sku",
    searchable: true,
  },
  {
    title: "LTE?",
    dataIndex: "is_lte",
    key: "is_lte",
    filters: [
      {text: "Yes", value: true},
      {text: "No", value: false},
    ],
    filterMultiple: false,
    onFilter: (value, record) => record.is_lte === value,
    render: (isLte) => (
      <ActiveLabel active_label={"Yes"} inactive_label={"No"} is_active={isLte} />
    ),
  },
  {
    title: "Warranty start date",
    dataIndex: "warranty_start_date",
    key: "warranty_start_date",
  },
  {
    title: "Warranty end date",
    dataIndex: "warranty_end_date",
    key: "warranty_end_date",
    searchable: true,
  },
];
const exported = () => {
  return isAdmin() ? [...columns, ...extraAdminFields] : columns;
};
export default exported;
