import {useLocation, useHistory} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import React, {useEffect, useState} from "react";
import styles from "./reset.module.css";
import onsorLogo from "../../assets/images/onsor-technologies.svg";
import {message, Button, Input} from "antd";
import {checkVerifyCode, verifyCode} from "../../services/reset-password";
import {navigateToLogin} from "../../navigator";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  password: yup.string().min(8).required(),
  confirm: yup.string().min(8).required(),
});

const Verification = () => {
  const {handleSubmit, errors, control} = useForm({
    resolver: yupResolver(schema),
  });
  function useUrlQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useUrlQuery();
  const code = query.get("code");
  const history = useHistory();
  const {error, isLoading: isVerifyingCode} = useQuery("checkVerifyCode", () =>
    checkVerifyCode({code: code})
  );
  const [buttonText, setButtonText] = useState("Reset password");
  const [isLoading, setLoading] = useState(false);
  const verify = useMutation(verifyCode, {
    onSuccess() {
      finish();
      message.success("Success");
    },
    onError() {
      finish();
    },
  });
  useEffect(() => {
    setButtonText(isLoading ? "Resetting password..." : "Reset password");
  }, [isLoading]);
  if (isVerifyingCode) {
    return <div>Loading...</div>;
  }

  if (error) {
    message.error("Expired or invalid verification code.");
    navigateToLogin(history);
    return null;
  }

  const handleButtonClick = (data) => {
    setLoading(true);
    if (data && data.password !== null && data.password === data.confirm) {
      verify.mutate({
        password: data.password,
        code: code,
      });
    } else {
      message.error("Your passwords do not match.");
      setLoading(false);
    }
  };
  const finish = () => {
    setLoading(false);
    navigateToLogin(history);
  };

  return (
    <div className={styles.resetRoot}>
      <img alt={""} src={onsorLogo} />
      <div className={styles.inputFieldRoot}>
        <div className={styles.passwordResetTitle}>Password Reset</div>
        <div className={styles.passwordResetExplanation}>
          Enter your email address that you used to register and we will send you an email
          with a link to reset you password.
        </div>
        <form onSubmit={handleSubmit(handleButtonClick)}>
          <div className={styles.inputDiv}>
            <Controller
              as={
                <Input
                  placeholder={"New password"}
                  type={"password"}
                  className={styles.inputField}
                />
              }
              name={"password"}
              control={control}
            />
            {errors.password && (
              <span className="invalid align-left">{errors.password.message}</span>
            )}
          </div>
          <div className={styles.inputDiv}>
            <Controller
              as={
                <Input
                  placeholder={"Confirm new password"}
                  type={"password"}
                  className={styles.inputField}
                />
              }
              name={"confirm"}
              control={control}
            />
            {errors.confirm && <span className="invalid">{errors.confirm.message}</span>}
          </div>
          <Button htmlType={"submit"} disabled={isLoading} className={styles.resetButton}>
            {buttonText}
          </Button>
        </form>
      </div>
    </div>
  );
};
export default Verification;
