import {useForm, Controller} from "react-hook-form";
import {useHistory} from "react-router-dom";
import ImgCrop from "antd-img-crop";
import {
  Input,
  InputNumber,
  Select,
  Upload,
  message,
  Button,
  Col,
  Row,
  Radio,
  Tooltip,
  Modal,
} from "antd";
import api from "../../api";
import "antd/dist/antd.css";
import "./forms.css";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {
  LoadingOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {useQuery, useMutation} from "react-query";
import React, {useState, useEffect} from "react";
import {getProvidersList} from "../../services/providers";
import {getShopInventories, getMyPartsListFromCreate} from "../../services/inventories";
import {getTicketStatics, createServiceRequest} from "../../services/tickets";
import {getTechnicianList} from "../../services/technician";
import {isAdmin, isOwner} from "../../utils/check-role";
import {
  beforeImageUpload,
  onPreview,
  SUPPORTED_MIME_TYPES_IMAGE_UPLOAD,
  getImageTooltip,
} from "../../file-upload";

const {Option} = Select;
const {TextArea} = Input;
const Others = "others";

const schema = yup.object().shape({
  title: yup.array().min(1).required("Title is required"),
  status: yup.string().required("Status is required"),
  isOnWarranty: yup.bool().required("Is on warranty is required"),
  defectCause: yup.string().required("Defect cause is required"),
  assigned: yup.string().required("Assigned is required"),
  comments: yup.string().required("Comments is required"),
  services: yup.array().min(1).required("Service is required"),
  description: yup.string().when("services", {
    is: (value) => value.includes(Others),
    then: yup.string().required("Description is Required"),
  }),
  price: yup.string().when("services", {
    is: (value) => value.includes(Others),
    then: yup
      .string()
      .required("Price is required")
      .test(
        "is-decimal",
        "Price Must be a number with 3 decimal places at most",
        (value) => (value + "").match(/^\d*\.?\d{3}$/)
      ),
  }),
});

function Step2(props) {
  const formData = props.formData;
  const history = useHistory();
  const [isOther, setIsOther] = useState(formData.services?.includes(Others));
  const [imgObj, setImgObj] = useState(null);
  const [visible, setVisible] = useState(false);
  const [othersId, setOthersId] = useState();
  const [imageList, setImageList] = useState(
    formData.imageList ? formData.imageList : []
  );
  const [partsList, setPartsList] = useState(
    formData.partsList ? formData.partsList : []
  );
  const assignedUsersCall = isAdmin()
    ? getProvidersList
    : isOwner()
    ? getTechnicianList
    : null;
  const {data: assignedUsers, isLoading: isSPorTechLoading} = useQuery(
    "userList",
    assignedUsersCall
  );
  const {
    isLoading: isLoadingServiceRequestStaticData,
    error: errorLoadingServiceRequestStaticData,
    data: serviceRequestStaticData,
  } = useQuery("inventoryList", getTicketStatics);
  const getPartsCall = isAdmin() ? getShopInventories : getMyPartsListFromCreate;
  const PartsMutation = useMutation(getPartsCall, {
    onSuccess: (data) => {
      if (data.status >= 200 && data.status < 300) {
        setAllPartsList(data.data);
      }
    },
    onError: (error) => {
      message.error(error.response.data.error.message);
    },
  });

  const createRequestMutation = useMutation(createServiceRequest, {
    onSuccess: (data) => {
      message.success("Service Request created successfully");
      history.push("/service-requests");
    },
    onError: (error) => {
      message.error(error.response.data.error.message);
    },
  });

  const [allPartsList, setAllPartsList] = useState([]);
  const [isUploading, setUploading] = useState(false);

  useEffect(() => {
    if (isAdmin() && formData.assigned) {
      PartsMutation.mutate(formData.assigned);
    }
    if (localStorage.getItem("role") === "owner") {
      PartsMutation.mutate();
    }
  }, [formData.assigned]);

  const getServicesOptions = () => {
    let options = [];
    if (serviceRequestStaticData?.data && serviceRequestStaticData.data.services) {
      serviceRequestStaticData.data.services.forEach((service) => {
        if (service.cost) {
          options.push({label: service.name, value: service.id});
        } else {
          options.push({label: service.name, value: Others});
          setOthersId(service.id);
        }
      });
    }
    return options;
  };
  const {setValue, getValues, handleSubmit, errors, control} = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    console.log(data);
    if (data.services.includes(Others)) {
      let temp = data;
      temp.services = [othersId];
      data = temp;
    }
    let f2data = {...data, partsList};
    let f1data = props.form1Data;
    let allData = {...f2data, ...f1data, imageList};
    createRequestMutation.mutate(allData);
  };

  const handlePreview = async (file) => {
    await onPreview(file).then((ImgObj) => {
      setVisible(true);
      setImgObj(ImgObj);
    });
  };

  const MAX_IMAGE_COUNT = 8;
  const uploadButton = imageList.length < MAX_IMAGE_COUNT && (
    <div>
      {isUploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{marginTop: 8}}>Upload{isUploading ? "ing..." : ""}</div>
    </div>
  );

  const handleUpload = async (options) => {
    setUploading(true);
    const {onSuccess, onError, file} = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    fmData.append("file", file);
    try {
      const res = await api.post("/media", fmData, config);
      setUploading(false);
      onSuccess("Ok");
      let temp = JSON.parse(JSON.stringify(imageList));
      temp.push(res.data[0]);
      setImageList(temp);
    } catch (err) {
      setUploading(false);
      message.error("Problem uploading");
      onError({err});
    }
  };

  const handleOnChange = ({file}) => {
    if (file.status === "removed") {
      let temp = imageList.filter((item) => item.guid !== file.guid);
      setImageList(temp);
    }
  };
  function onChangeRadioWarenty(e) {
    setValue("isOnWarranty", e.target.value);
  }
  function onChangeRadioCase(e) {
    setValue("defectCause", e.target.value);
  }
  function isFilled() {
    let filled = true;
    partsList.forEach((element) => {
      if (!element.item) filled = false;
    });
    return filled;
  }
  function getCost() {
    let cost = 0;
    cost = getPartCost() + getVATCost() + servicePrice;
    return Math.round((cost + Number.EPSILON) * 100) / 100;
  }

  function getPartCost() {
    let cost = 0;
    partsList.forEach((element) => {
      if (element.item) cost += element.quantity * element.item.price;
    });
    return Math.round((cost + Number.EPSILON) * 100) / 100;
  }
  const [servicePrice, setServicePrice] = useState(getServiceCost());
  function getServiceCost() {
    if (getValues().services?.includes(Others)) {
      if (parseFloat(getValues().price)) {
        return Math.round((parseFloat(getValues().price) + Number.EPSILON) * 100) / 100;
      }
      return 0;
    }
    let cost = 0;
    if (getValues().services && getValues().services.length > 0) {
      serviceRequestStaticData.data?.services?.forEach((element) => {
        if (getValues().services?.includes(element.id)) cost += element.cost;
      });
    }
    return Math.round((cost + Number.EPSILON) * 100) / 100;
  }

  function getVATCost() {
    let cost = 0;
    if (getPartCost() > 0) cost = getPartCost() * 0.05;
    return Math.round((cost + Number.EPSILON) * 100) / 100;
  }

  function getPartOptions(id) {
    let list = [];
    allPartsList.length > 0 &&
      allPartsList.forEach((item) => {
        let x = true;
        partsList &&
          partsList.forEach((elm) => {
            if (elm.id === item.id) x = false;
          });
        if (x || (id && id === item.id))
          list.push({
            label: item.name,
            value: item.id,
          });
      });
    return list ? list : [];
  }
  return (
    <div className="steps-div">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={24} md={12}>
            <div style={{margin: "40px"}}>
              <Row className="row-padding">
                <label>Issue Title*</label>
                <Controller
                  render={() => {
                    return (
                      <Select
                        mode="multiple"
                        style={{width: "100%"}}
                        options={
                          errorLoadingServiceRequestStaticData
                            ? [...[], {label: "Failed to load", value: null}]
                            : isLoadingServiceRequestStaticData
                            ? [...[], {label: "Loading", value: null}]
                            : serviceRequestStaticData?.data.issue_types.map((issue) => {
                                return {label: issue.value, value: issue.id};
                              })
                        }
                        defaultValue={formData.title}
                        onChange={(e) => {
                          setValue("title", e);
                        }}
                      />
                    );
                  }}
                  defaultValue={formData.title}
                  name="title"
                  control={control}
                />
                <span className="invalid">{errors.title?.message}</span>
              </Row>

              <Row className="row-padding">
                <label>
                  {isAdmin() ? "Assigned service provider*" : "Assigned Technician*"}
                </label>
                <Controller
                  render={() => {
                    return (
                      <Select
                        disabled={isSPorTechLoading}
                        style={{width: "100%"}}
                        onChange={(e) => {
                          if (isAdmin()) {
                            setPartsList([{id: null, quantity: 1, item: null}]);
                            PartsMutation.mutate(e);
                          }
                          setValue("assigned", e);
                        }}
                      >
                        {assignedUsers?.data.map((user) => {
                          return (
                            <Option value={user.id}>
                              {isOwner()
                                ? user.first_name + " " + user.last_name
                                : isAdmin()
                                ? user.shop_name
                                : "weird"}
                            </Option>
                          );
                        })}
                      </Select>
                    );
                  }}
                  name="assigned"
                  defaultValue={formData.assigned}
                  control={control}
                />
                <span className="invalid">{errors.assigned?.message}</span>
              </Row>

              <Row>
                <Col span={17} style={{padding: "0px 5px"}}>
                  {isAdmin() ? (
                    <Tooltip title="You must select provider to show options">
                      <label>Part(s) to be Replaced</label>
                    </Tooltip>
                  ) : (
                    <label>Part(s) to be Replaced</label>
                  )}
                </Col>
                <Col span={6} style={{padding: "0px 5px"}}>
                  <label>Quantity</label>
                </Col>
              </Row>
              {partsList.map((part, index) => {
                return (
                  <Row>
                    <Col span={17} style={{padding: "0px 5px"}}>
                      <Select
                        style={{width: "100%"}}
                        value={part.id}
                        onChange={(e) => {
                          let temp = [...partsList];
                          temp[index].id = e;
                          allPartsList.forEach((element) => {
                            if (element.id === e) temp[index].item = element;
                          });
                          setPartsList(temp);
                        }}
                        options={getPartOptions(part.id)}
                      />
                    </Col>
                    <Col span={6} style={{padding: "0px 5px"}}>
                      <InputNumber
                        type="number"
                        min={1}
                        style={{width: "100%"}}
                        value={part.quantity}
                        onChange={(e) => {
                          allPartsList.forEach((element) => {
                            if (element.id === part.id) {
                              if (element.quantity >= e) {
                                let temp = [...partsList];
                                temp[index].quantity = parseInt(e);
                                setPartsList(temp);
                              } else message.warn("not having enough supplies");
                            }
                          });
                        }}
                      />
                    </Col>
                    <Col span={1}>
                      <MinusCircleOutlined
                        onClick={() => {
                          let temp = [...partsList];
                          temp.splice(index, 1);
                          setPartsList(temp);
                        }}
                        style={{paddingTop: "8px", position: "relative", right: 0}}
                      />
                    </Col>
                  </Row>
                );
              })}
              <Row className="row-padding">
                <Button
                  type="dashed"
                  block
                  disabled={!isFilled() || allPartsList.length === partsList.length}
                  onClick={() => {
                    let temp = [...partsList];
                    temp.push({item: null, quantity: 1});
                    setPartsList(temp);
                  }}
                >
                  <PlusCircleOutlined />
                  Add another part
                </Button>
              </Row>

              <Row className="row-padding">
                <label>Services</label>
                <Row
                  style={!isOther ? {width: "100%", display: "none"} : {width: "100%"}}
                >
                  <Col span="17" style={{padding: "0px 5px"}}>
                    <Controller
                      as={<Input placeholder="Description" />}
                      name="description"
                      defaultValue={formData.description}
                      control={control}
                    />
                    <span className="invalid">{errors.description?.message}</span>
                  </Col>
                  <Col span="6" style={{padding: "0px 5px"}}>
                    <Controller
                      render={() => {
                        return (
                          <Input
                            defaultValue={formData.price}
                            value={getValues().price}
                            placeholder="price"
                            onChange={(e) => {
                              setValue("price", e.target.value);
                              setServicePrice(getServiceCost());
                            }}
                          />
                        );
                      }}
                      name="price"
                      defaultValue={formData.price}
                      control={control}
                    />
                    <span className="invalid">{errors.price?.message}</span>
                  </Col>
                  <Col span="1">
                    <MinusCircleOutlined
                      style={{paddingTop: "8px", position: "relative", right: 0}}
                      onClick={() => {
                        setIsOther(false);
                        setValue("services", undefined);
                        setValue("price", undefined);
                        setValue("description", undefined);
                        setServicePrice(getServiceCost());
                      }}
                    />
                  </Col>
                </Row>

                <div style={isOther ? {width: "100%", display: "none"} : {width: "100%"}}>
                  <Controller
                    render={() => {
                      return (
                        <Select
                          style={{width: "100%"}}
                          mode="multiple"
                          value={getValues().services}
                          options={getServicesOptions()}
                          onChange={(e) => {
                            if (e.includes(Others)) {
                              setValue("services", [Others]);
                              setIsOther(true);
                              setServicePrice(getServiceCost());
                            } else {
                              setValue("services", e);

                              setServicePrice(getServiceCost());
                            }
                          }}
                        />
                      );
                    }}
                    name="services"
                    defaultValue={formData.services}
                    control={control}
                  />
                  <span className="invalid">{errors.services?.message}</span>
                </div>
              </Row>

              <Row className="row-padding">
                <label>Covered by Warranty?*</label>
                <Controller
                  render={() => {
                    return (
                      <Radio.Group
                        defaultValue={formData.isOnWarranty}
                        onChange={onChangeRadioWarenty}
                        style={{width: "100%"}}
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    );
                  }}
                  name="isOnWarranty"
                  defaultValue={formData.isOnWarranty}
                  control={control}
                />
                <span className="invalid">{errors.isOnWarranty?.message}</span>
              </Row>
              <Row className="row-padding">
                <label>Cause of Defect*</label>
                <Controller
                  render={() => {
                    return (
                      <Radio.Group
                        onChange={onChangeRadioCase}
                        defaultValue={formData.defectCause}
                        style={{width: "100%"}}
                      >
                        <Radio value={1}>Manufacturing</Radio>
                        <Radio value={2}>External</Radio>
                      </Radio.Group>
                    );
                  }}
                  name="defectCause"
                  defaultValue={formData.defectCause}
                  control={control}
                />
                <span className="invalid">{errors.defectCause?.message}</span>
              </Row>

              <Row className="row-padding">
                <label>Spare Part Cost</label>
                <Input disabled={true} value={getPartCost()} />
              </Row>
              <Row className="row-padding">
                <label>Service Cost</label>
                <Input disabled={true} value={servicePrice} />
              </Row>
              <Row className="row-padding">
                <label>VAT Cost</label>
                <Input disabled={true} value={getVATCost()} />
              </Row>
              <Row className="row-padding">
                <label>Total Cost</label>
                <Input disabled={true} value={getCost()} />
              </Row>
            </div>
          </Col>

          <Col sm={24} md={12}>
            <div style={{margin: "40px"}}>
              <Row className="row-padding">
                <label>Overall Status*</label>
                <Controller
                  render={() => {
                    return (
                      <Select
                        style={{width: "100%"}}
                        options={
                          errorLoadingServiceRequestStaticData
                            ? [...[], {label: "Failed to load", value: null}]
                            : isLoadingServiceRequestStaticData
                            ? [...[], {label: "Loading", value: null}]
                            : serviceRequestStaticData?.data.overall_statuses.map(
                                (status) => {
                                  return {label: status.value, value: status.id};
                                }
                              )
                        }
                        defaultValue={formData.status}
                        onChange={(e) => {
                          setValue("status", e);
                        }}
                      />
                    );
                  }}
                  name="status"
                  defaultValue={formData.status}
                  control={control}
                />
                <span className="invalid">{errors.status?.message}</span>
              </Row>
              <Row className="row-padding">
                <label>Comments*</label>
                <Controller
                  as={<TextArea rows={4} />}
                  name="comments"
                  defaultValue={formData.comments}
                  control={control}
                />
                <span className="invalid">{errors.comments?.message}</span>
              </Row>
              <Row className="row-padding">
                <label>Upload Images</label>
                <ImgCrop>
                  <Tooltip title={getImageTooltip(8)}>
                    <Upload
                      name={"file"}
                      accept={SUPPORTED_MIME_TYPES_IMAGE_UPLOAD.join(", ")}
                      customRequest={handleUpload}
                      onChange={handleOnChange}
                      listType="picture-card"
                      onPreview={handlePreview}
                      beforeUpload={beforeImageUpload}
                      fileList={imageList}
                      multiple={true}
                    >
                      {uploadButton}
                    </Upload>
                  </Tooltip>
                </ImgCrop>
              </Row>
            </div>
          </Col>
        </Row>
        <div className="steps-action">
          <Button type="primary" htmlType="submit" style={{float: "right"}}>
            Done
          </Button>
          <Button
            style={{margin: "0 8px"}}
            onClick={() => {
              let data = getValues();
              let mydata = {...data, partsList, imageList};
              props.prev(mydata);
            }}
          >
            Back
          </Button>
        </div>
      </form>
      <Modal
        visible={visible}
        title="Image Preview"
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <img alt="example" style={{width: "100%"}} src={imgObj} />
      </Modal>
    </div>
  );
}

export default Step2;
