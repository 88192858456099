import {useQuery} from "react-query";
import {useState, useEffect} from "react";
import {getServiceProviderTechsList} from "../../services/dashboard";
import DashboardComponent from "./DashboardComponent";
import {isAdmin} from "../../utils/check-role";
import columns from "./models/service-provider";
import DashboardTable from "./dashboardTable/DashboardTable";
import React from "react";

const ServiceProvider = (props) => {
  const [dates, setDates] = useState();
  const {
    isFetching: isLoadingServiceProviderTechs,
    data: serviceProviderTechs,
    refetch: refetchServiceProviderTechs,
  } = useQuery(
    "serviceProviderTechsList",
    () => {
      return getServiceProviderTechsList({
        start: dates && dates[0]?.format("YYYY-MM-DD"),
        end: dates && dates[1]?.format("YYYY-MM-DD"),
      });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    refetchServiceProviderTechs({
      start: dates && dates[0].format("YYYY-MM-DD"),
      end: dates && dates[1].format("YYYY-MM-DD"),
    });
  }, [dates]);
  return (
    <DashboardComponent
      dates={dates}
      setDates={setDates}
      style={props.style}
      title={`Who are the busiest ${isAdmin() ? "service providers" : "technicians"}?`}
    >
      <DashboardTable
        data={serviceProviderTechs?.data}
        columns={columns(isAdmin())}
        isLoading={isLoadingServiceProviderTechs}
      />
    </DashboardComponent>
  );
};

export default ServiceProvider;
