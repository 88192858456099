import React from "react";
import {Layout} from "antd";
import "./mainPage.css";
import PageMenu from "./sideMenu";
import SuperAdminRoutes from "../routes/SuperAdminRoutes";
import AdminRoutes from "../routes/AdminRoutes";
import TechnicianRoutes from "../routes/TechnicianRoutes";
import {BrowserRouter as Router} from "react-router-dom";
import {useState, useEffect} from "react";
import MyHeader from "./header";
import {isAdmin, isOwner, isTech} from "../utils/check-role";
import ClientRoutes from "../routes/ClientRoutes";

export default function MainLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const handleResize = (e) => {
    setWindowSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (windowSize > 700) setCollapsed(false);
    else setCollapsed(true);
  }, [windowSize]);
  return (
    <Layout>
      <MyHeader />
      <Router>
        <Layout style={{height: "92vh"}}>
          <Layout.Sider
            width={200}
            className="site-layout-background page-menu"
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <PageMenu toggleAction={toggle} isCollapsed={collapsed} />
          </Layout.Sider>
          <Layout style={{padding: "24px", overflow: "auto"}}>
            <Layout.Content
              className="site-layout-background"
              style={{
                margin: "0",
                height: "92vh",
              }}
            >
              {isAdmin() ? (
                <SuperAdminRoutes />
              ) : isOwner() ? (
                <AdminRoutes />
              ) : isTech() ? (
                <TechnicianRoutes />
              ) : (
                <ClientRoutes />
              )}
            </Layout.Content>
          </Layout>
        </Layout>
      </Router>
    </Layout>
  );
}
