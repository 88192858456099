import React from "react";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {isAdmin, isOwner, isTech} from "../../utils/check-role";
import {Table, Tag} from "antd";
import "antd/dist/antd.css";
import {colors} from "../../views/serviceRequest/colors";
import searchNow from "../../utils/search-helper";
import styles from "./ServiceRequest.module.css";
import ticketStatusResolver from "../../ticketStatusResolver";

export default function ServiceRequestTable({
  admin,
  filtration,
  data,
  isLoading = false,
}) {
  const history = useHistory();

  const columnsAdmin = [
    {
      title: "Ticket Number",
      dataIndex: "id",
      searchable: true,
      sorter: (record, nextRecord) => record.id < nextRecord.id,
    },
    {
      title: "Issue Title",
      dataIndex: "issue_type_title",
      searchable: true,
      sorter: (record, nextRecord) =>
        record.issue_type_title > nextRecord.issue_type_title,
    },
    {
      title: "Service Provider",
      dataIndex: "shop_name",
      searchable: true,
      sorter: (record, nextRecord) => record.shop_name > nextRecord.shop_name,
    },
    {
      title: "Serial Number",
      dataIndex: "serial_number",
      searchable: true,
      sorter: (record, nextRecord) => record.serial_number > nextRecord.serial_number,
    },
    {
      title: "Status",
      dataIndex: "ticket_status",
      sorter: (record, nextRecord) =>
        record.ticket_status.key > nextRecord.ticket_status.key,
      render: (ticket_status, record) => {
        const status = ticketStatusResolver(record);
        return <Tag color={colors[status]}>{status}</Tag>;
      },
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      render: (date) => {
        return moment(date).format("DD/MM/YYYY");
      },
      sorter: (record, nextRecord) => record.updated_at < nextRecord.updated_at,
    },
  ];
  const columnsClient = [
    {
      title: "Ticket Number",
      dataIndex: "id",
      searchable: true,
      sorter: (record, nextRecord) => record.id < nextRecord.id,
    },
    {
      title: "Issue Title",
      dataIndex: "issue_type_title",
      searchable: true,
      sorter: (record, nextRecord) =>
        record.issue_type_title > nextRecord.issue_type_title,
    },
    {
      title: "Service Provider",
      dataIndex: "shop_name",
      searchable: true,
      sorter: (record, nextRecord) => record.shop_name > nextRecord.shop_name,
    },
    {
      title: "Serial Number",
      dataIndex: "serial_number",
      searchable: true,
      sorter: (record, nextRecord) => record.serial_number > nextRecord.serial_number,
    },
    {
      title: "Status",
      dataIndex: "ticket_status",
      sorter: (record, nextRecord) =>
        record.ticket_status.key > nextRecord.ticket_status.key,
      render: (ticket_status, record) => {
        const status = ticketStatusResolver(record);
        return <Tag color={colors[status]}>{status}</Tag>;
      },
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      render: (date) => {
        return moment(date).format("DD/MM/YYYY");
      },
      sorter: (record, nextRecord) => record.updated_at < nextRecord.updated_at,
    },
  ];
  const columnsOwner = [
    {
      title: "Ticket Number",
      dataIndex: "id",
      searchable: true,
      sorter: (record, nextRecord) => record.id < nextRecord.id,
    },
    {
      title: "Issue Title",
      dataIndex: "issue_type_title",
      searchable: true,
      sorter: (record, nextRecord) =>
        record.issue_type_title > nextRecord.issue_type_title,
    },
    {
      title: "Technician Name",
      dataIndex: "technician_name",
      searchable: true,
      sorter: (record, nextRecord) => record.technician_name > nextRecord.technician_name,
    },
    {
      title: "Serial Number",
      dataIndex: "serial_number",
      searchable: true,
      sorter: (record, nextRecord) => record.serial_number > nextRecord.serial_number,
    },
    {
      title: "Owner Name",
      dataIndex: "customer_name",
      searchable: true,
      sorter: (record, nextRecord) => record.customer_name > nextRecord.customer_name,
    },
    {
      title: "Status",
      dataIndex: "ticket_status",
      sorter: (record, nextRecord) =>
        record.ticket_status.key > nextRecord.ticket_status.key,
      render: (ticket_status, record) => {
        const status = ticketStatusResolver(record);
        return <Tag color={colors[status]}>{status}</Tag>;
      },
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      render: (date) => {
        return moment(date).format("DD/MM/YYYY");
      },
      sorter: (record, nextRecord) => record.updated_at < nextRecord.updated_at,
    },
  ];
  const columnsTech = [
    {
      title: "Ticket Number",
      dataIndex: "id",
      searchable: true,
      sorter: (record, nextRecord) => record.id < nextRecord.id,
    },
    {
      title: "Issue Title",
      dataIndex: "issue_type_title",
      searchable: true,
      sorter: (record, nextRecord) =>
        record.issue_type_title > nextRecord.issue_type_title,
    },
    {
      title: "Serial Number",
      dataIndex: "serial_number",
      searchable: true,
      sorter: (record, nextRecord) => record.serial_number > nextRecord.serial_number,
    },
    {
      title: "Owner Name",
      dataIndex: "customer_name",
      searchable: true,
      sorter: (record, nextRecord) => record.customer_name > nextRecord.customer_name,
    },
    {
      title: "Status",
      dataIndex: "ticket_status",
      sorter: (record, nextRecord) =>
        record.ticket_status.key > nextRecord.ticket_status.key,
      render: (ticket_status) => {
        return <Tag color={colors[ticket_status.key]}>{ticket_status.key}</Tag>;
      },
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      render: (date) => {
        return moment(date).format("DD/MM/YYYY");
      },
      sorter: (record, nextRecord) => record.updated_at < nextRecord.updated_at,
    },
  ];
  let selectedColumns = [];
  if (isAdmin() && !admin) {
    selectedColumns = columnsAdmin;
  } else if (isOwner() || admin) {
    selectedColumns = columnsOwner;
  } else if (isTech()) {
    selectedColumns = columnsTech;
  } else {
    selectedColumns = columnsClient;
  }
  const {status, search} = filtration;
  let filteredData = data?.filter((record) => {
    if (status === "all") {
      return true;
    }
    return record.ticket_status.key === status;
  });

  filteredData = searchNow(filteredData, search, selectedColumns);

  return (
    <Table
      onRow={(record) => {
        return {
          onClick: () => {
            history.push(`/service-requests/${record.id}`);
          },
        };
      }}
      rowClassName={styles.serviceRequestRow}
      columns={selectedColumns}
      dataSource={filteredData}
      pagination={{
        pageSizeOptions: ["10", "15", "20"],
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSize: history.location?.state?.currentSize
          ? history.location.state.currentSize
          : 10,
        onShowSizeChange: (page, size) => {
          let state = {...history.location.state};
          state.currentSize = size;
          history.replace({...history.location, state});
        },
        current: history.location?.state?.currentPage
          ? history.location.state.currentPage
          : 1,
        onChange: (currentPage) => {
          let state = {...history.location.state};
          state.currentPage = currentPage;
          history.replace({...history.location, state});
        },
      }}
      loading={isLoading}
    />
  );
}
