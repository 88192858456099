import React from "react";
const ContentWrapper = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        alignItems: "center",
      }}
    >
      {props.children}
    </div>
  );
};
export default ContentWrapper;
