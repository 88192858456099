import {Col, Divider, Input, Row, Select, DatePicker, Card} from "antd";
import moment from "moment";
import {isAdmin} from "../../../utils/check-role";
const {Option} = Select;
const {TextArea} = Input;
export default function CustomerTab(props) {
  const isView = true;
  const selectBeforePhone = (
    <Select disabled={isView} defaultValue="http://" className="select-before">
      <Option value="http://">+968</Option>
    </Select>
  );
  const formData = props.data;

  const transformDate = (date) => {
    if (!date) {
      return null;
    }
    return moment(date, "DD/MMM/YYYY");
  };

  return (
    <div style={isAdmin() ? {paddingTop: "24px"} : {}}>
      <Card className="site-layout-background" style={{minHeight: "77vh"}}>
        <Row
          style={{
            width: "90%",
            marginLeft: 45,
          }}
        >
          <Col sm={23} md={11} style={{marginRight: "3%"}}>
            <Divider orientation="left">Customer Details</Divider>
            <div style={{margin: "30px 25px"}}>
              <Row>
                <Col span={12} style={{padding: "0px 5px"}}>
                  <label>First Name</label>
                  <Input disabled={isView} defaultValue={formData?.customer_first_name} />
                </Col>
                <Col span={12} style={{padding: "0px 5px"}}>
                  <label>Last Name</label>
                  <Input disabled={isView} defaultValue={formData?.customer_last_name} />
                </Col>
              </Row>
              <Row className="row-padding">
                <label>Owner’s Email</label>
                <Input disabled={isView} defaultValue={formData?.customer_email} />
              </Row>
              <Row className="row-padding">
                <label>Owner’s Phone Number</label>
                <Input
                  addonBefore={selectBeforePhone}
                  placeholder="phone"
                  disabled={isView}
                  defaultValue={formData?.customer_phone_number}
                />
              </Row>
              <Row className="row-padding">
                <label>Type of Issue</label>
                <Select
                  mode="multiple"
                  disabled={isView}
                  style={{width: "100%"}}
                  defaultValue={formData?.issue_types_customer.map((issue) => {
                    return issue.value;
                  })}
                />
              </Row>
              <Row className="row-padding">
                <label>Device issues</label>
                <Col span={24}>
                  <TextArea
                    disabled={isView}
                    rows={4}
                    defaultValue={formData?.customer_comments}
                    showCount
                    maxLength={1000}
                  />
                </Col>
              </Row>
            </div>
          </Col>

          <Col sm={23} md={11}>
            <Divider orientation="left">Device Details</Divider>
            <div style={{margin: "30px 25px"}}>
              <Row className="row-padding">
                <label>Serial Number</label>
                <Input disabled={isView} defaultValue={formData?.serial_number} />
              </Row>
              <Row style={{padding: "5px"}}>
                <label>Device</label>
                <Input disabled={isView} defaultValue={formData?.device_name} />
              </Row>

              <Row>
                <Col span={12} style={{padding: "0px 5px"}}>
                  <label>Date of purchase</label>
                  <DatePicker
                    style={{width: "100%"}}
                    disabled={isView}
                    defaultValue={transformDate(formData?.warranty_start_date)}
                  />
                </Col>
                <Col span={12} style={{padding: "0px 5px"}}>
                  <label>Warranty End Date</label>
                  <DatePicker
                    style={{width: "100%"}}
                    disabled={isView}
                    defaultValue={transformDate(formData?.warranty_end_date)}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
