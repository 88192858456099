import React, {useState} from "react";
import {useHistory} from "react-router-dom";

import {Card, Breadcrumb, Tabs, Tag, message, Button, Modal} from "antd";
import {Layout} from "antd";
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import RequestTab from "./tabs/ServiceRequestList";
import TechniciansTab from "./tabs/technicianList";
import Info from "./tabs/Info";

import {useQuery, useMutation} from "react-query";
import {getProviderById} from "../../services/providers";
import {rejectOwner, approveOwner} from "../../services/users";

import {useParams, Link} from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import Inventories from "./tabs/inventories/inventories";
const {Header, Content} = Layout;
const {TabPane} = Tabs;
export default function ServiceProviderDetails() {
  const history = useHistory();
  const {id} = useParams();

  const {
    isLoading: isLoadingServiceProviderInfo,
    error: errorLoadingServiceProviderInfo,
    data: serviceProviderInfo,
    refetch,
  } = useQuery([id], getProviderById);

  const [current, setCurrent] = useState(
    history.location?.state?.currentTab ? history.location.state.currentTab : 1
  );

  const getComponent = (current) => {
    switch (current) {
      case "2":
        return <TechniciansTab />;
      case "3":
        return <RequestTab />;
      case "4":
        return <Inventories />;
      default:
        return <Info refetch={refetch} data={serviceProviderInfo?.data} />;
    }
  };
  function callback(key) {
    setCurrent(key);
    let state = {...history.location.state};
    state.currentTab = key;
    state.currentPage = 1;
    state.currentSize = 10;
    history.replace({...history.location, state});
  }

  const [note, setNote] = useState();
  const [showError, setShowError] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const rejectOptions = {
    async onSuccess() {
      setConfirmLoading(false);
      await refetch();
      setNote();
      setShowError(false);
      hideModal();
    },
    onError() {
      setConfirmLoading(false);
      message.error("Problem in rejecting service provider");
    },
  };
  const approveOptions = {
    async onSuccess() {
      await refetch();
    },
    onError() {
      message.error("Problem in approving service provider");
    },
  };

  const Reject = useMutation(
    "rejected",
    () => rejectOwner(serviceProviderInfo?.data.id, note),
    rejectOptions
  );
  const Approve = useMutation(
    "approved",
    () => approveOwner(serviceProviderInfo?.data.id),
    approveOptions
  );

  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const hideModal = () => {
    setShowError(false);
    setNote();
    setVisible(false);
  };

  if (errorLoadingServiceProviderInfo) {
    message.error("Failed to load service provider detail");
  }

  if (isLoadingServiceProviderInfo) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <Header className="breadcrumb-header">
        <Breadcrumb style={{margin: "16px 0"}}>
          <Breadcrumb.Item>
            <Link to="/providers">Service Provider</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{serviceProviderInfo?.data.shop_name}</Breadcrumb.Item>
        </Breadcrumb>
        <h3>
          <ArrowLeftOutlined
            onClick={() => {
              history.goBack();
            }}
          />{" "}
          {serviceProviderInfo?.data.shop_name} {"  "}
          {serviceProviderInfo?.data.is_approved === 2 ? (
            <Tag color="red">rejected</Tag>
          ) : serviceProviderInfo?.data.is_approved === 1 &&
            serviceProviderInfo?.data.is_active ? (
            <Tag color="green">active</Tag>
          ) : serviceProviderInfo?.data.is_approved === 1 &&
            !serviceProviderInfo?.data.is_active ? (
            <Tag color="orange">disabled</Tag>
          ) : (
            <Tag>pending_approval</Tag>
          )}
        </h3>
        <Tabs defaultActiveKey={current} onChange={callback}>
          <TabPane tab="Info" key="1" />
          <TabPane tab="Technicians" key="2" />
          <TabPane tab="Tickets" key="3" />
          <TabPane tab="Inventories" key="4" />
        </Tabs>
      </Header>
      {serviceProviderInfo?.data.is_approved === 2 && (
        <div style={{marginTop: "24px"}}>
          <Button
            icon={<QuestionCircleOutlined />}
            onClick={() => {
              Modal.info({
                title: "Rejection Note",
                content: (
                  <div>
                    <p>{serviceProviderInfo?.data.reject_note}</p>
                  </div>
                ),
                onOk() {},
              });
            }}
          >
            View Rejection Note
          </Button>
          <Button
            style={{marginLeft: "2%"}}
            icon={<CheckCircleOutlined />}
            type="primary"
            onClick={() => {
              Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: (
                  <span>
                    Are you sure you want to approve{" "}
                    <strong>{serviceProviderInfo.data.shop_name}</strong>?
                  </span>
                ),
                content: "Once approved, the service provider will become active.",
                async onOk() {
                  if (!showError) {
                    await Approve.mutateAsync();
                  }
                },
                onCancel() {},
              });
            }}
          >
            Approve
          </Button>
        </div>
      )}
      {serviceProviderInfo?.data.is_approved === 0 && (
        <div style={{marginTop: "24px", textAlign: "right"}}>
          <Button
            icon={<CloseCircleOutlined />}
            type="primary"
            danger
            onClick={() => {
              showModal();
            }}
          >
            Reject
          </Button>
          <Button
            style={{marginLeft: "2%"}}
            icon={<CheckCircleOutlined />}
            type="primary"
            onClick={() => {
              Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: (
                  <span>
                    Are you sure you want to approve{" "}
                    <strong>{serviceProviderInfo.data.shop_name}</strong>?
                  </span>
                ),
                content: "Once approved, the service provider will become active.",
                async onOk() {
                  if (!showError) {
                    await Approve.mutateAsync();
                  }
                },
                onCancel() {},
              });
            }}
          >
            Approve
          </Button>
        </div>
      )}
      <Content style={{margin: "24px 0px 0"}}>
        <Card className="site-layout-background" style={{padding: 24, minHeight: "77vh"}}>
          {getComponent(current)}
        </Card>
      </Content>
      <Modal
        title={
          <span>
            You are about to reject
            <br /> <strong>{serviceProviderInfo.data.shop_name}</strong>
          </span>
        }
        visible={visible}
        confirmLoading={confirmLoading}
        onOk={async () => {
          if (!showError && note && note?.trim() !== "") {
            setConfirmLoading(true);
            await Reject.mutateAsync();
          } else setShowError(true);
        }}
        onCancel={hideModal}
      >
        <p>Tell them why</p>
        <TextArea
          placeholder="write something"
          value={note}
          onChange={(e) => {
            if (e.target.value.trim() !== "" && showError) {
              setShowError(false);
            }
            setNote(e.target.value);
          }}
          onBlur={() => {
            if (!note || note === "") {
              setShowError(true);
            }
          }}
          maxLength={100}
          showCount
          rows={3}
        />
        <p className="invalid">{showError ? "Rejection note is required" : ""}</p>
      </Modal>
    </Layout>
  );
}
