import api from "../api";
import {isAdmin} from "../utils/check-role";

export const getTicketStatics = async () => {
  return await api.get(`/static/ticket-statics`);
};

export const getServiceRequestList = async () => {
  return await api.get(`/tickets`);
};

export const createServiceRequest = async (ticket) => {
  let parts = [];
  if (ticket.partsList) {
    parts = ticket.partsList.map((part) => {
      return {id: part.id, quantity: part.quantity};
    });
  }
  let images = [];
  if (ticket.imageList) {
    images = ticket.imageList.map((img) => {
      return img.guid;
    });
  }
  let body = {
    spare_parts: parts,
    customer_first_name: ticket.firstName,
    customer_last_name: ticket.lastName,
    customer_email: ticket.email,
    customer_phone_area_code: "968", //fixed
    customer_phone_number: ticket.phone,
    customer_comments: ticket.deviceIssue,
    shop_comments: ticket.comments,
    customer_images: [],
    admin_images: images,
    issue_types_admin: ticket.title,
    issue_types_customer: ticket.typeOfIssue,
    serial_number: ticket.serialNumber,
    is_warranty_covered: ticket.isOnWarranty,
    defect_cause_id: parseInt(ticket.defectCause),
    overall_status_id: parseInt(ticket.status),
    service_ids: ticket.services,
    service_description: ticket.description,
    others_service_cost: ticket.price,
  };
  if (isAdmin()) {
    body = {
      ...body,
      shop_owner_id: parseInt(ticket.assigned),
    };
  } else {
    body = {
      ...body,
      tech_id: parseInt(ticket.assigned),
    };
  }
  return await api.post(`/tickets`, body);
};

export const getServiceRequestStatusesByFilter = async () => {
  return getServiceRequestStatuses("?caller=FILTER");
};

export const getServiceRequestStatuses = async (queryString = "") => {
  return await api.get(`/static/ticket-statuses${queryString}`);
};

export const getOwnerTickets = async (id) => {
  return await api.get(`/tickets/owners/${id}`);
};

export const getServiceRequestById = async (id) => {
  return await api.get(`/tickets/${id}`);
};

export const editImages = async ({id, customerImg, adminImg}) => {
  let images = [];
  if (adminImg) {
    images = adminImg.map((img) => {
      return img.guid;
    });
  }
  let body = {
    customer_images: customerImg,
    admin_images: images,
  };
  return await api.put(`/tickets/${id}/images`, body);
};

export const editTicketStatus = async ({id, status}) => {
  return await api.put(`/tickets/${id}/status`, {status_id: status});
};

export const editServiceRequestIssue = async (ticket) => {
  let parts = [];
  if (ticket.partsList) {
    parts = ticket.partsList.map((part) => {
      return {id: part.id, quantity: part.quantity};
    });
  }
  let body = {
    spare_parts: parts,
    tech_id: parseInt(ticket.assigned),
    shop_comments: ticket.comments,
    issue_types: ticket.title,
    is_warranty_covered: ticket.isOnWarranty,
    defect_cause_id: parseInt(ticket.defectCause),
    overall_status_id: parseInt(ticket.status),
    service_ids: ticket.services,
    service_description: ticket.description,
    others_service_cost: ticket.price,
  };
  return await api.put(`/tickets/${ticket.id}/issue`, body);
};

export const rejectTicket = async (id, note) => {
  return await api.put(`/tickets/${id}/approve/`, {
    is_approved: 2,
    reject_note: note,
  });
};

export const approveTicket = async (id) => {
  return await api.put(`/tickets/${id}/approve`, {
    is_approved: 1,
  });
};

export const getTicketHistory = async (id) => {
  return await api.get(`/tickets/${id}/history`);
};

export const addEditComments = async ({id, message}) => {
  return await api.put(`/tickets/${id}/comment`, {comment: message});
};

export const downloadCSV = async () => {
  return await api.get(`/tickets/export`);
};
