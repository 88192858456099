import {Table, message, Row, Col, Input} from "antd";
import styles from "../../../inventory/inventory.module.css";
import React, {useEffect, useState} from "react";
import {getShopInventories} from "../../../../services/inventories";
import {useParams, useHistory} from "react-router-dom";
import columns from "./columns";
import {useQuery} from "react-query";
import searchNow from "../../../../utils/search-helper";

const Inventories = () => {
  const history = useHistory();
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const {id} = useParams();
  const {data, error, isFetching: isLoading} = useQuery(
    "getShopInventories",
    () => getShopInventories(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    if (error) {
      return "Error";
    }
    setFilteredData(searchNow(data?.data, search, columns));
  }, [data?.data, search]);

  if (error) {
    message.error("Problem fetching shop inventories.");
  }
  const handleSearch = (value) => {
    setSearch(value);
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={10} md={8} lg={8} xl={8}>
          <Input.Search
            placeholder="input search text"
            onSearch={handleSearch}
            enterButton
            allowClear="true"
          />
        </Col>
      </Row>
      <br />
      <Table
        rowKey={"id"}
        rowClassName={styles.inventoryRow}
        columns={columns}
        loading={isLoading}
        dataSource={filteredData}
        onRow={(record) => {
          return {
            onClick: () => {
              console.log(record);
              history.push(`/inventory/${record.id}`);
            },
          };
        }}
        pagination={{
          pageSizeOptions: ["10", "15", "20"],
          defaultPageSize: 15,
          showSizeChanger: true,
          pageSize: history.location?.state?.currentSize
            ? history.location.state.currentSize
            : 10,
          onShowSizeChange: (page, size) => {
            let state = {...history.location.state};
            state.currentSize = size;
            history.replace({...history.location, state});
          },
          current: history.location?.state?.currentPage
            ? history.location.state.currentPage
            : 1,
          onChange: (currentPage) => {
            let state = {...history.location.state};
            state.currentPage = currentPage;
            history.replace({...history.location, state});
          },
        }}
      />
    </>
  );
};

export default Inventories;
