import {useForm, Controller} from "react-hook-form";
import {Input, Button, message} from "antd";
import logo from "../assets/images/onsor-logo.png";
import "antd/dist/antd.css";
import "./login.css";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {loginCall} from "../services/auth";
import {useMutation} from "react-query";
import {useHistory, Link} from "react-router-dom";

const schema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().min(8).required(),
});
function Login() {
  const history = useHistory();
  const from = history.location.state && history.location.state.referrer;
  const {handleSubmit, errors, control} = useForm({
    resolver: yupResolver(schema),
  });
  const mutation = useMutation(loginCall, {
    onSuccess: (data, variables, context) => {
      localStorage.setItem("tkn", data.data.access_token);
      localStorage.setItem("rtkn", data.data.refresh_token);
      localStorage.setItem("role", data.data.role.key);
      localStorage.setItem("name", data.data.full_name);
      history.push(from ? from : "/");
    },
    onError: (error, variables, context) => {
      message.error(error.response.data.error.message);
    },
  });
  const onSubmit = async (data) => {
    mutation.mutate(data);
  };

  return (
    <div className="login-div">
      <img src={logo} alt="Onsor logo" className="onsor-logo" />
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="login-input">
          <Controller
            as={<Input placeholder="Email/Username" />}
            name="email"
            control={control}
          />
          {errors.email && <span className="invalid">{errors.email.message}</span>}
        </div>

        <div className="login-input">
          <Controller
            as={<Input placeholder="Password" type="password" />}
            name="password"
            control={control}
          />
          {errors.password && <span className="invalid">{errors.password.message}</span>}
        </div>

        <div className="login-input">
          <Link to="/reset-password" className="forgot-password-link">
            Forgot your password?
          </Link>
        </div>
        <Button
          className="login-button login-input"
          htmlType="submit"
          type="primary"
          loading={mutation.isLoading}
          block
        >
          Login
        </Button>
        <Button
          className="login-button"
          onClick={() => {
            history.push("/register");
          }}
          type="primary"
          ghost
          block
        >
          Register as a Service Provider
        </Button>
      </form>{" "}
    </div>
  );
}
export default Login;
