const columns = [
  {
    title: "Spare Part",
    dataIndex: "name",
    searchable: true,
    sorter: (record, nextRecord) => record.name < nextRecord.name,
  },
  {
    title: "Type",
    dataIndex: "inventory_type_value",
    sorter: (r, n) => r.inventory_type_id > n.inventory_type_id,
    searchable: true,
  },
  {
    title: "SKU",
    dataIndex: "sku",
    searchable: true,
  },
  {
    title: "Price (OMR)",
    dataIndex: "price",
    key: "price",
    sorter: (record, nextRecord) => record.price < nextRecord.price,
  },
  {
    title: "In Stock",
    dataIndex: "quantity",
    sorter: (record, nextRecord) => record.quantity < nextRecord.quantity,
  },
];
export default columns;
