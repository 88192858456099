import React, {useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {
  Input,
  Col,
  Button,
  Row,
  Upload,
  Select,
  message,
  Divider,
  Image,
  Tag,
  Modal,
  Tooltip,
} from "antd";
import ImgCrop from "antd-img-crop";
import {useHistory} from "react-router-dom";
import {useQuery, useMutation} from "react-query";
import {isAdmin} from "../../utils/check-role";
import "antd/dist/antd.css";
import "./forms.css";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {getInventoryTypes} from "../../services/static";
import {CameraOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import api from "../../api";

import {
  beforeImageUpload,
  SUPPORTED_MIME_TYPES_IMAGE_UPLOAD,
  getImageTooltip,
} from "../../file-upload";
import {disablePart, enablePart, editSparePart} from "../../services/inventories";

const schema = yup.object().shape({
  inventoryTypes: yup.number().required("Inventory types is required"),
  sparePartName: yup.string().required("Spare part name is required"),
  description: yup.string().required("Description is required"),
  sku: yup.string().required("sku is required"),
  price: yup
    .string()
    .test("is-decimal", "invalid price", (value) => (value + "").match(/^\d*\.?\d*$/)),
});

function AddPart(props) {
  const [formData, setFormData] = useState(props.formData);
  const history = useHistory();
  const [isViewOnly] = useState(props.isViewOnly);
  const [isEdit, setisEdit] = useState(props.isEdit);
  const [category, setCategory] = useState(
    props.isViewOnly ? props.formData.inventory_type_id : props.formData.inventoryTypes
  );

  useEffect(() => {
    setisEdit(props.isEdit);
  }, [props.isEdit]);

  useEffect(() => {
    setFormData(props.formData);
    reset();
    setCategory(
      props.isViewOnly ? props.formData.inventory_type_id : props.formData.inventoryTypes
    );
  }, [props.formData]);
  const {
    isLoading: isLoadingInventoryTypes,
    error: errorLoadingInventoryTypes,
    data: inventoryType,
  } = useQuery("inventoryTypesList", getInventoryTypes);

  if (errorLoadingInventoryTypes) {
    message.error(inventoryType.statusText);
  }

  const [defaultFileList, setDefaultFileList] = useState(
    isViewOnly ? {} : formData?.file
  );

  useEffect(() => {
    if (isViewOnly) {
      let img = formData.image.split("/");
      if (img.length > 0) {
        setDefaultFileList([
          {
            fileName: img[img.length - 1],
            guid: img[img.length - 1],
            url: formData.image,
          },
        ]);
      }
    }
  }, [formData.image]);
  const handleOnChange = ({file, fileList, event}) => {
    setDefaultFileList(fileList);
  };
  const handleUpload = async (options) => {
    const {onSuccess, onError, file} = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    fmData.append("file", file);
    try {
      const res = await api.post("/media", fmData, config);
      onSuccess("Ok");
      let temp = JSON.parse(JSON.stringify(defaultFileList));
      temp = res.data;
      setDefaultFileList(temp);
    } catch (err) {
      message.error("Problem uploading");
      onError({err});
    }
  };

  const {TextArea} = Input;

  const {handleSubmit, errors, control, setValue, reset} = useForm({
    resolver: yupResolver(schema),
  });
  const EditPart = useMutation(editSparePart, {
    async onSuccess() {
      props.setLoading(false);
      props.setLoadingText("Save changes");
      message.success("Spare part updated successfully");
      props.setEdit(false);
      await props.refetch();
    },
    onError() {
      props.setLoading(false);
      props.setLoadingText("Save changes");
      message.error("Problem updating spare part");
    },
  });
  const onSubmit = async (data) => {
    if (isViewOnly) {
      props.setLoading(true);
      props.setLoadingText("Saving changes...");
      EditPart.mutate({...data, id: formData?.id, file: defaultFileList});
    } else {
      props.next({...data, file: defaultFileList});
    }
  };

  const disableOptions = {
    async onSuccess() {
      await props.refetch();
    },
    onError() {
      message.error("Problem disabling spare part");
    },
  };

  const enableOptions = {
    async onSuccess() {
      await props.refetch();
    },
    onError() {
      message.error("Problem enabling spare part");
    },
  };

  const disable = useMutation("disable", () => disablePart(formData?.id), disableOptions);

  const enable = useMutation("enable", () => enablePart(formData?.id), enableOptions);

  const getDisableButton = () => {
    return (
      <Button
        danger
        onClick={() => {
          Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: `Are you sure you want to disable ${formData?.name} ?`,
            content:
              "Disabling this spare part will result to it being removed from service providers’ lists and all quantities will automatically be reduced to none.",
            async onOk() {
              await disable.mutate();
            },
            onCancel() {},
          });
        }}
      >
        Disable Spare Part
      </Button>
    );
  };

  const getEnableButton = () => {
    return (
      <Button
        onClick={() => {
          Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: `Are you sure you want to activate ${formData?.name}?`,
            content: "This will result in spare part being enabled and shown to public.",
            async onOk() {
              await enable.mutate();
            },
            onCancel() {},
          });
        }}
      >
        Enable Spare Part
      </Button>
    );
  };

  return (
    <>
      {isViewOnly && (
        <Row>
          <Col lg={15} md={12} sm={24}>
            <Divider orientation="left">Spare Part Details</Divider>
          </Col>{" "}
          <Col lg={9} md={12} sm={24} style={{paddingLeft: "2%"}}>
            Status:{" "}
            {formData?.is_active ? <Tag color="green">active</Tag> : <Tag>disabled</Tag>}
            {isAdmin()
              ? formData?.is_active
                ? getDisableButton()
                : getEnableButton()
              : null}
          </Col>
        </Row>
      )}
      <div className="steps-div">
        <form id="partsForm" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={24} lg={12}>
              <Tooltip title={getImageTooltip(1)}>
                <div style={{margin: "45px 30px 30px"}}>
                  {isViewOnly && !isEdit ? (
                    <Image
                      src={formData.image}
                      style={{width: "100%"}}
                      alt=" Spare part image"
                    />
                  ) : (
                    <ImgCrop>
                      <Upload
                        accept={SUPPORTED_MIME_TYPES_IMAGE_UPLOAD.join(", ")}
                        customRequest={handleUpload}
                        beforeUpload={beforeImageUpload}
                        onChange={handleOnChange}
                        listType="picture-card"
                        fileList={defaultFileList}
                        className="image-upload-grid"
                        showUploadList={{
                          showPreviewIcon: false,
                          showRemoveIcon: true,
                          showDownloadIcon: true,
                          removeIcon: "Replace Image",
                        }}
                      >
                        {defaultFileList && defaultFileList.length >= 1 ? null : (
                          <div>
                            <p className="ant-upload-drag-icon">
                              <CameraOutlined />
                            </p>
                            <p className="ant-upload-hint">Upload image</p>
                          </div>
                        )}
                      </Upload>
                    </ImgCrop>
                  )}
                </div>
              </Tooltip>
            </Col>
            <Col md={24} lg={12}>
              <div style={{margin: "30px"}}>
                <Row className="row-padding">
                  <label>Category*</label>
                  <Controller
                    render={() => {
                      return (
                        <Select
                          disabled={isViewOnly && !isEdit}
                          placeholder="Please Select"
                          style={{width: "100%"}}
                          onChange={(e) => {
                            setValue("inventoryTypes", e);
                            setCategory(e);
                          }}
                          value={category}
                          options={
                            isLoadingInventoryTypes
                              ? [{label: "Loading...", value: "Loading"}]
                              : inventoryType?.data.map((v) => {
                                  return {label: v.value, value: v.id};
                                })
                          }
                        />
                      );
                    }}
                    name="inventoryTypes"
                    defaultValue={
                      isViewOnly ? formData.inventory_type_id : formData.inventoryTypes
                    }
                    control={control}
                  />
                  <span className="invalid">{errors.inventoryTypes?.message}</span>
                </Row>
                <Row className="row-padding">
                  <label>Spare Part Name*</label>
                  <Controller
                    as={
                      <Input
                        placeholder="Spare Part Name"
                        disabled={isViewOnly && !isEdit}
                      />
                    }
                    name="sparePartName"
                    defaultValue={isViewOnly ? formData.name : formData.sparePartName}
                    control={control}
                  />
                  <span className="invalid">{errors.sparePartName?.message}</span>
                </Row>
                <Row className="row-padding">
                  <label>Description*</label>
                  <Controller
                    as={
                      <TextArea
                        placeholder="Write Description"
                        rows={4}
                        disabled={isViewOnly && !isEdit}
                      />
                    }
                    name="description"
                    defaultValue={formData.description}
                    control={control}
                  />
                  <span className="invalid">{errors.description?.message}</span>
                </Row>
                <Row className="row-padding">
                  <label style={{width: "100%"}}>SKU*</label>
                  <Controller
                    as={<Input placeholder="SKU" disabled={isViewOnly && !isEdit} />}
                    name="sku"
                    defaultValue={formData.sku}
                    control={control}
                    wrapperCol={{sm: 24}}
                    style={{width: "60%"}}
                  />
                </Row>
                <Row className="row-padding" style={{marginTop: "-20px"}}>
                  <span className="invalid">{errors.sku?.message}</span>
                </Row>
                <Row>
                  <Col span={12} style={{padding: "0px 5px"}}>
                    <label>Price*</label>
                    <Controller
                      as={<Input placeholder="0.R.0" disabled={isViewOnly && !isEdit} />}
                      name="price"
                      defaultValue={isViewOnly ? formData.price : formData.price}
                      control={control}
                    />
                    <span className="invalid">{errors.price?.message}</span>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          {!isViewOnly && (
            <div className="steps-action">
              <Button type="primary" htmlType="submit" style={{float: "right"}}>
                Next
              </Button>
              <Button
                onClick={() => {
                  history.goBack();
                }}
              >
                Back
              </Button>
            </div>
          )}
        </form>
      </div>
    </>
  );
}

export default AddPart;
