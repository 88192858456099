import React, {useEffect, useState} from "react";
import BlackHeader from "../../components/blackHeader/BlackHeader";
import Box from "../../components/box/Box";
import HorizontalLinearStepper from "../../components/stepper/Stepper";
import YourServiceProvider from "../../components/serviceProvider/YourServiceProvider";
import {useQuery} from "react-query";
import {useHistory, useParams} from "react-router-dom";
import {getOwnerProfile} from "../../services/customers";
import styles from "./CustomerTicket.module.css";
import {Divider} from "antd";

const CustomerTicket = () => {
  const {id} = useParams();
  const {error, isLoading, data, isSuccess} = useQuery([id], getOwnerProfile, {
    retry: 1,
    retryDelay: (attempt) =>
      Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
  });
  const history = useHistory();
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (isLoading) {
      return "Loading...";
    }
    if (error) {
      history.push(`/support`);
      return;
    }
    if (isSuccess) {
      setProfile(data?.data);
    }
  });

  return (
    <>
      <BlackHeader />
      <div className={styles.root}>
        <div className={styles.leftBox}>
          <Box>
            <br />
            <Divider
              orientation="left"
              style={{
                color: "var(--support-color-text)",
                borderTopColor: "var(--support-color-text)",
                fontSize: "16px",
              }}
            >
              <strong
                style={{
                  color: "var(--support-color-text)",
                  fontSize: "24px",
                }}
              >
                We’re here to help
              </strong>
            </Divider>
            <HorizontalLinearStepper />
          </Box>
        </div>
        <div className={styles.rightBox}>
          <div
            style={{
              backgroundColor: "var(--bg-color-support-comp)",
              minHeight: "90vh",
            }}
          >
            <br />
            <Divider
              orientation="left"
              style={{
                color: "var(--support-color-text)",
                borderTopColor: "var(--support-color-text)",
              }}
            >
              <strong
                style={{
                  color: "var(--support-color-text)",
                  fontSize: "24px",
                }}
              >
                Service Provider Information
              </strong>
            </Divider>
            {profile && (
              <YourServiceProvider
                shopName={profile.shop_name}
                shopAdress={profile.shop_address}
                phoneNumber={profile.full_phone_number}
                email={profile.email}
                officeDays={profile.operation_time}
                shopLogo={profile.shop_logo}
                disableButton={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomerTicket;
