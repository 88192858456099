import {Comment, Avatar, Form, Button, Input, Divider, Card, message} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {useState} from "react";
import {useMutation} from "react-query";
import {addEditComments} from "../../../services/tickets";

const {TextArea} = Input;

export default function CommentTab(props) {
  const [comments, setComments] = useState(
    props.data?.admin_comments
      ? {
          avatar: <Avatar icon={<UserOutlined />} />,
          author: "Super Admin",
          content: props.data.admin_comments,
        }
      : null
  );
  const [value, setValue] = useState(
    props.data?.admin_comments ? props.data.admin_comments : ""
  );
  const [submitting, setSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const commentsMutation = useMutation(addEditComments, {
    onSuccess: (data) => {
      if (data.status >= 200 && data.status < 300) {
        message.success("Comments updated successfully");
        //props.refetch();
        setComments({
          avatar: <Avatar icon={<UserOutlined />} />,
          author: "Super Admin",
          content: value,
        });
        setSubmitting(false);
        setValue("");
        setIsEdit(false);
      } else {
        message.error(data.statusText);
      }
    },
    onError: (error) => {
      message.error(error.response.data.error.message);
    },
  });

  const handleEdit = () => {
    setSubmitting(false);
    setValue(comments.content);
    setIsEdit(true);
    setComments({
      avatar: <Avatar icon={<UserOutlined />} />,
      author: "Super Admin",
      content: value,
    });
  };

  const handleSubmit = () => {
    if (!value) {
      return;
    }

    setSubmitting(true);

    commentsMutation.mutate({id: props.data.id, message: value});
  };

  return (
    <>
      <br />
      <Card className="site-layout-background" style={{minHeight: "77vh"}}>
        <Divider orientation="left">Onsor comments</Divider>

        {isEdit || !comments ? (
          <Comment
            avatar={<Avatar icon={<UserOutlined />} />}
            content={
              <>
                <Form.Item>
                  <TextArea
                    style={{width: "70%"}}
                    rows={5}
                    showCount
                    maxLength={1200}
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                    value={value}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    loading={submitting}
                    onClick={handleSubmit}
                    type="primary"
                  >
                    Add Comment
                  </Button>
                </Form.Item>
              </>
            }
          />
        ) : (
          <div style={{padding: "10px", width: "70%"}}>
            <h4>1 Comment</h4>
            <Comment
              {...comments}
              content={
                <>
                  <p>{comments.content}</p>
                  <Button
                    onClick={handleEdit}
                    type="link"
                    style={{padding: 0, paddingTop: 10, fontSize: "12px"}}
                  >
                    Edit
                  </Button>
                </>
              }
            />
          </div>
        )}
      </Card>
    </>
  );
}
