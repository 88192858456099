import styles from "./reset.module.css";
import onsorLogo from "../../assets/images/onsor-technologies.svg";
import informationLogo from "../../assets/images/information.svg";
import React from "react";
import {Button} from "antd";
import {useHistory} from "react-router-dom";
import {navigateToLogin} from "../../navigator";

const CheckEmail = () => {
  const history = useHistory();
  const handleLoginNavigation = () => {
    navigateToLogin(history);
  };
  return (
    <div className={styles.resetRoot}>
      <img alt={""} src={onsorLogo} />
      <img alt={""} className={styles.informationLogo} src={informationLogo} />
      <div className={styles.checkYourEmail}>Check your email.</div>
      <div className={styles.checkYourEmailSubtitle}>
        A verification email to reset your password has been sent to your email address.
        Please follow the instructions in the email to complete your password reset.
      </div>
      <div className={styles.checkEmailResetButtons}>
        <Button
          onClick={handleLoginNavigation}
          className={[styles.checkEmailButton, styles.blueButton]}
        >
          Back to sign in
        </Button>
      </div>
    </div>
  );
};
export default CheckEmail;
