import {useForm, Controller} from "react-hook-form";
import {Col, Button, Input, Row, Select, DatePicker, message} from "antd";
import "antd/dist/antd.css";
import "./forms.css";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {confirmDevice} from "../../services/devices";
import {useMutation, useQuery} from "react-query";
import {useState} from "react";
import moment from "moment";
import {getTicketStatics} from "../../services/tickets";
const {Option} = Select;
const {TextArea} = Input;

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email().required("Email is required"),
  phone: yup.string().length(8).required("Phone is required"),
  serialNumber: yup.string().required("Serial number is required"),
  deviceIssue: yup.string().required("Device issue is required"),
  typeOfIssue: yup.array().required("Type of Issue is required"),
});
function Step1(props) {
  const formData = props.formData;

  const [confirm, setConfirm] = useState(formData.confirm);
  const [isConfirmed, setIsConfirmed] = useState(formData.isConfirmed);
  const [loading, setLoading] = useState(false);

  const ConfirmMutitation = useMutation(confirmDevice, {
    onSuccess: (data) => {
      message.success("Device found successfully");
      setConfirm(data?.data);
      setIsConfirmed(true);
      setLoading(false);
    },
    onError: (error) => {
      message.error("Could not find the device");
      setLoading(false);
    },
  });
  const {
    isLoading: isLoadingIssueType,
    error: errorLoadingIssueType,
    data: issueType,
  } = useQuery("inventoryList", getTicketStatics);

  const {handleSubmit, errors, control, setValue, getValues} = useForm({
    resolver: yupResolver(schema),
  });

  if (errorLoadingIssueType) {
    message.error(issueType.data.statusText);
  }

  const onSubmit = async (data) => {
    //go to next step and send data
    let fd = {...data, confirm, isConfirmed};
    if (isConfirmed) {
      props.next(fd);
    } else {
      message.error("Please confirm the device serial number first.");
    }
  };
  const selectBeforePhone = (
    <Select disabled={true} className="select-before" value="+968">
      <Option value="+968">+968</Option>
    </Select>
  );
  return (
    <div className="steps-div">
      <form ref={props.ref} onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={24} md={12}>
            <div style={{margin: "40px"}}>
              <Row>
                <Col span={12} style={{padding: "0px 5px"}}>
                  <label>First Name*</label>
                  <Controller
                    as={<Input />}
                    name="firstName"
                    defaultValue={formData.firstName}
                    control={control}
                  />
                  <span className="invalid">{errors.firstName?.message}</span>
                </Col>
                <Col span={12} style={{padding: "0px 5px"}}>
                  <label>Last Name*</label>
                  <Controller
                    as={<Input />}
                    name="lastName"
                    defaultValue={formData.lastName}
                    control={control}
                  />
                  <span className="invalid">{errors.lastName?.message}</span>
                </Col>
              </Row>
              <Row className="row-padding">
                <label>Owner’s Email*</label>
                <Controller
                  as={<Input />}
                  name="email"
                  defaultValue={formData.email}
                  control={control}
                />
                <span className="invalid">{errors.email?.message}</span>
              </Row>
              <Row className="row-padding">
                <label>Owner’s Phone Number*</label>
                <Controller
                  as={<Input addonBefore={selectBeforePhone} placeholder="phone" />}
                  name="phone"
                  defaultValue={formData.phone}
                  control={control}
                />
                <span className="invalid">{errors.phone?.message}</span>
              </Row>
              <Row className="row-padding">
                <label>Type of Issue*</label>

                <Controller
                  render={() => {
                    return (
                      <Select
                        mode="multiple"
                        style={{width: "100%"}}
                        onChange={(e) => {
                          setValue("typeOfIssue", e);
                        }}
                        defaultValue={formData.typeOfIssue}
                        options={
                          isLoadingIssueType
                            ? [{label: "Loading...", value: "Loading"}]
                            : issueType.data.issue_types &&
                              issueType.data.issue_types.map((v) => {
                                return {label: v.value, value: v.id};
                              })
                        }
                      />
                    );
                  }}
                  name="typeOfIssue"
                  defaultValue={formData.typeOfIssue}
                  control={control}
                />
                <span className="invalid">{errors.typeOfIssue?.message}</span>
              </Row>
              <Row className="row-padding">
                <label>Device issues*</label>
                <Controller
                  as={<TextArea rows={4} />}
                  name="deviceIssue"
                  defaultValue={formData.deviceIssue}
                  control={control}
                />
                <span className="invalid">{errors.deviceIssue?.message}</span>
              </Row>
            </div>
          </Col>

          <Col sm={24} md={12}>
            <div style={{margin: "40px"}}>
              <Row className="row-padding">
                <label>Serial Number*</label>
                <Controller
                  as={<Input />}
                  disabled={isConfirmed || loading}
                  name="serialNumber"
                  defaultValue={formData.serialNumber}
                  control={control}
                />
                <span className="invalid">{errors.serialNumber?.message}</span>
              </Row>
              <Row className="row-padding">
                <Button
                  type="dashed"
                  disabled={isConfirmed || loading}
                  block
                  onClick={async () => {
                    setLoading(true);
                    ConfirmMutitation.mutate(getValues().serialNumber);
                  }}
                >
                  {loading ? "Confirm..." : isConfirmed ? "Confirmed" : "Confirm"}
                </Button>
              </Row>
              <Row style={{padding: "5px"}}>
                <label>Device</label>
                <Input disabled={true} value={confirm?.device_name} />
              </Row>

              <Row>
                <Col span={12} style={{padding: "0px 5px"}}>
                  <label>Date of purchase</label>
                  <DatePicker
                    style={{width: "100%"}}
                    disabled={true}
                    value={moment(confirm?.warranty_start_date, "DD/MMM/YYYY")}
                  />
                </Col>
                <Col span={12} style={{padding: "0px 5px"}}>
                  <label>Warranty End Date</label>
                  <DatePicker
                    style={{width: "100%"}}
                    disabled={true}
                    value={moment(confirm?.warranty_end_date, "DD/MMM/YYYY")}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <div className="steps-action">
          <Button type="primary" htmlType="submit" style={{float: "right"}}>
            Next
          </Button>
        </div>
      </form>
    </div>
  );
}
export default Step1;
