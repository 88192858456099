import ActiveLabel from "../../ActiveLabel";
import React from "react";

const columns = (isAdmin) => [
  {
    title: "Rank",
    dataIndex: "rank",
  },
  {
    title: isAdmin ? "Service Provider" : "Technicians",
    dataIndex: "name",
  },
  {
    title: "# of Requests",
    dataIndex: "count",
  },
  {
    title: "Status",
    dataIndex: "is_active",
    render: (value) => {
      return <ActiveLabel is_active={value} />;
    },
  },
];
export default columns;
