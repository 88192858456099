import styles from "./reset.module.css";
import React, {useEffect, useState} from "react";
import onsorLogo from "../../assets/images/onsor-technologies.svg";
import {message, Button, Input} from "antd";
import {resetPassword} from "../../services/reset-password";
import {useMutation} from "react-query";
import {navigateToCheckEmail} from "../../navigator";
import {useHistory} from "react-router-dom";
import {isEmailValid} from "../../utils/text-utils";

const Reset = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Reset password");
  const [isInvalidInput, setIsInvalidInput] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setButtonText("Resetting...");
    } else {
      setButtonText("Reset password");
    }
  }, [email, isLoading]);
  const finish = () => {
    setLoading(false);
    message.info("If an email exists, we will send you a reset-password link.");
    navigateToCheckEmail(history);
  };
  const reset = useMutation(() => resetPassword({email: email}), {
    onSuccess() {
      finish();
    },
    onError() {
      finish();
    },
  });
  const showError = () => {
    setIsInvalidInput(true);
  };
  const hideError = () => {
    setIsInvalidInput(false);
  };
  const handleButtonClick = async () => {
    if (!isEmailValid(email)) {
      showError();
      return;
    }
    hideError();
    setLoading(true);
    reset.mutate();
  };
  return (
    <div className={styles.resetRoot}>
      <img alt={""} src={onsorLogo} />
      <div className={styles.inputFieldRoot}>
        <div className={styles.passwordResetTitle}>Password Reset</div>
        <div className={styles.passwordResetExplanation}>
          Enter your email address that you used to register and we will send you an email
          with a link to reset you password.
        </div>
        <Input
          onChange={(e) => setEmail(e.target.value)}
          placeholder={"Email"}
          type={"email"}
          className={styles.inputField}
        />
        <label className={styles.labelError}>
          {isInvalidInput && "Enter a valid email address"}
        </label>
        <Button
          disabled={isLoading}
          onClick={handleButtonClick}
          className={styles.resetButton}
        >
          {buttonText}
        </Button>
        <a className={styles.a} href={"/login"}>
          Return to login
        </a>
      </div>
    </div>
  );
};
export default Reset;
