import {withRouter} from "react-router-dom";
import {Card, Select, Input, Col, Row, message, Button} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import Table from "../../components/tables/serviceRequest";
import "../serviceRequest/serviceRequest.css";
import {useState} from "react";
import {useMutation, useQuery} from "react-query";
import {
  getServiceRequestList,
  getServiceRequestStatusesByFilter,
  downloadCSV,
} from "../../services/tickets";
import {isTech, isClient, isAdmin} from "../../utils/check-role";
import moment from "moment";

function ServiceProvider({history}) {
  const [filter, setFilter] = useState({
    search: history.location?.state?.filter ? history.location.state.filter.search : "",

    status: history.location?.state?.filter
      ? history.location.state.filter.status
      : "all",
  });

  const [searchInputValue, setSearchInputValue] = useState(
    history.location?.state?.filter ? history.location.state.filter.search : ""
  );

  const [loadCSV, setLoadCSV] = useState(false);

  const {
    isLoading: isLoadingServiceRequest,
    error: errorLoadingServiceRequest,
    data: serviceRequests,
  } = useQuery("serviceRequestList", getServiceRequestList);
  const {
    isLoading: isLoadingServiceRequestStatus,
    error: errorLoadingServiceRequestStatus,
    data: serviceRequestStatus,
  } = useQuery("getServiceRequestStatusByFilter", getServiceRequestStatusesByFilter);

  const downloadFile = useMutation(downloadCSV, {
    async onSuccess(data) {
      var downloadLink = document.createElement("a");
      var blob = new Blob(["\ufeff", data.data]);
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `tickets_${moment().format("YYYY-MM-DD_hhmmss")}.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setLoadCSV(false);
    },
    onError(error) {
      message.error("Problem in downloading the tickets");
      setLoadCSV(false);
    },
  });

  if (errorLoadingServiceRequest) {
    message.error("Unable to load service requests.");
    return;
  }

  const handleFilterStatus = (value) => {
    // store filters in history state
    let state = {...history.location.state}; //browser
    state.filter = {...filter, status: value}; //new filter
    history.replace({...history.location, state});

    setFilter({...filter, status: value});
  };

  const onSearch = (value) => {
    // store filters in history state
    let state = {...history.location.state}; //browser
    state.filter = {...filter, search: value}; //new filter
    history.replace({...history.location, state});

    setFilter({...filter, search: value});
  };

  return (
    <>
      <Card bordered={false}>
        <span className="title">Service Requests</span>
      </Card>
      <br />
      <Card bordered={false}>
        <Row>
          <span className="title filter">Filter:</span>
          <Col className="filter" xs={24} sm={24} md={12} lg={12}>
            Status:{" "}
            <Select
              value={filter.status}
              placeholder="select"
              style={{width: 200}}
              onChange={handleFilterStatus}
              options={
                errorLoadingServiceRequestStatus
                  ? [...[], {label: "Failed to load", value: "all"}]
                  : isLoadingServiceRequestStatus
                  ? [...[], {label: "Loading", value: "all"}]
                  : [
                      {label: "All", value: "all"},
                      ...serviceRequestStatus.data.map((item) => {
                        return {label: item.value, value: item.key};
                      }),
                    ]
              }
            />
          </Col>
        </Row>
      </Card>
      <br />
      <Card bordered={false}>
        <Row>
          <Col xs={24} sm={10} md={8} lg={8} xl={12} style={{marginBottom: "10px"}}>
            <Input.Search
              value={searchInputValue}
              placeholder="input search text"
              onSearch={onSearch}
              onChange={(e) => setSearchInputValue(e.target.value)}
              enterButton
              className="search-input"
              allowClear
            />
          </Col>
          <Col style={{textAlign: "right"}} xs={24} sm={14} md={16} lg={16} xl={12}>
            {isAdmin() && (
              <Button
                className="download-button"
                type="default"
                icon={!loadCSV ? <DownloadOutlined /> : ""}
                loading={loadCSV}
                onClick={async () => {
                  setLoadCSV(true);
                  await downloadFile.mutateAsync();
                }}
                style={{
                  backgroundColor: "lightgrey",
                  marginRight: "14px",
                  marginBottom: "10px",
                }}
              >
                Download CSV
              </Button>
            )}

            {isTech() || isClient() ? (
              ""
            ) : (
              <Button
                onClick={() => history.push("/service-requests/new")}
                type="primary"
              >
                + Create Service Request
              </Button>
            )}
          </Col>
        </Row>
        <br />
        <Table
          filtration={filter}
          data={serviceRequests?.data}
          isLoading={isLoadingServiceRequest}
        />
      </Card>
    </>
  );
}

export default withRouter(ServiceProvider);
