import {Input} from "antd";
import React from "react";

const SearchBox = (props) => {
  return (
    <Input.Search
      placeholder={props.placeholder || "Search text"}
      onSearch={props.onSearch}
      enterButton
      allowClear="true"
    />
  );
};
export default SearchBox;
