import {Tag} from "antd";
import React from "react";

const ActiveLabel = (props) => {
  return (
    <Tag
      style={{
        color: props.is_active ? "green" : "red",
      }}
    >
      {props.is_active
        ? props.active_label || "active"
        : props.inactive_label || "inactive"}
    </Tag>
  );
};
export default ActiveLabel;
