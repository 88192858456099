import {Space, Spin} from "antd";
import React from "react";

const LoadingComponent = (props) => {
  const defaultStyle = {
    height: "150px",
    textAlign: "center",
    position: "relative",
    marginTop: "130px",
  };
  return (
    <div style={!props.style ? defaultStyle : props.style}>
      <Space size="middle">
        <Spin />
      </Space>
    </div>
  );
};
export default LoadingComponent;
