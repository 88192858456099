import React from "react";
import styles from "./Button.module.css";

const Button = (props) => {
  return (
    <button
      style={{
        position: "absolute",
        left: props.left,
        right: props.right,
        bottom: 10,
        cursor: "pointer",
      }}
      onClick={props.click}
      className={`${props.backgroundColor ? styles.backBtn : ""} ${styles.button}`}
      disabled={props.disabled}
    >
      {props.text}
    </button>
  );
};
export default Button;
