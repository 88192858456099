import logo from "../assets/images/onsor-logo.png";
import {useHistory} from "react-router-dom";
import {InfoCircleFilled, CloseCircleFilled} from "@ant-design/icons";
import "antd/dist/antd.css";
import "./login.css";

import {Card, Steps, Button} from "antd";
import React, {useState} from "react";
import Step1 from "../components/forms/newProviderStep1";
import Step2 from "../components/forms/newProviderStep2";
import Step3 from "../components/forms/newProviderStep3";
function Register() {
  const history = useHistory();
  const [current, setCurrent] = useState(0);
  const [myForms1Data, setMyForms1Data] = useState({});
  const [myForms2Data, setMyForms2Data] = useState({});
  const [myForms3Data, setMyForms3Data] = useState({});
  const [errorSubmitting, setErrorSubmitting] = useState();
  const next = (data) => {
    if (current === 0) {
      setMyForms1Data(data);
    } else if (current === 1) {
      setMyForms2Data(data);
    }

    setCurrent(current + 1);
  };

  const prev = (data) => {
    if (current === 2) {
      setMyForms3Data(data);
    } else if (current === 1) {
      setMyForms2Data(data);
    }

    setCurrent(current - 1);
  };
  const steps = [
    {
      title: "Contact details",
      content: <Step1 next={next} formData={myForms1Data} />,
    },
    {
      title: "Company info",
      content: <Step2 prev={prev} next={next} formData={myForms2Data} />,
    },
    {
      title: "Operation Period",
      content: (
        <Step3
          prev={prev}
          next={next}
          formData={myForms3Data}
          form1Data={myForms1Data}
          form2Data={myForms2Data}
          submitError={setErrorSubmitting}
        />
      ),
    },
  ];
  return (
    <div>
      <Card className="register-form-card">
        <img src={logo} alt="Onsor logo" className="onsor-logo" />
        <br />
        {current === steps.length ? (
          errorSubmitting ? (
            <div style={{padding: "5%"}}>
              <CloseCircleFilled
                style={{marginBottom: "2%", color: "red", fontSize: 70}}
              />
              <br />
              <h2>Your registration has Failed!</h2>
              <p style={{color: "gray"}}>{errorSubmitting}</p>
              <Button
                type="primary"
                onClick={() => {
                  setCurrent(0);
                }}
              >
                Return to my request
              </Button>
            </div>
          ) : (
            <div style={{paddingTop: "5%"}}>
              <InfoCircleFilled
                style={{marginBottom: "2%", color: "#1890FF", fontSize: 70}}
              />
              <br />
              <h2>Thank you for registering</h2>
              <p style={{color: "gray"}}>
                Your account needs to be reviewed & approved by Onsor before signing in.
                <br />
                We hope for your kind waiting.
              </p>
              <Button type="primary" onClick={() => history.push("/login")}>
                Go to login
              </Button>
            </div>
          )
        ) : (
          <>
            <h2>Register</h2>
            <br />
            <Steps current={current} className="steps-header">
              {steps.map((item) => (
                <Steps.Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div>{steps[current].content}</div>
          </>
        )}
      </Card>
    </div>
  );
}
export default Register;
