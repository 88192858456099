/***
 * YOU SHOULD USE THIS FILE TO ADD ANY NEW NAVIGATION TAB.
 */
import {HomeOutlined} from "@ant-design/icons";
import MedicineBoxOutlined from "@ant-design/icons/lib/icons/MedicineBoxOutlined";
import ProfileOutlined from "@ant-design/icons/lib/icons/ProfileOutlined";
import ToolOutlined from "@ant-design/icons/lib/icons/ToolOutlined";
import React from "react";

export const buildNavTab = (permission, path, title, icon, group) => {
  return {permission, key: path, path: `/${path}`, title, icon, group};
};

export const ADMIN = 1;
export const OWNER = 2;
export const TECH = 4;
export const CLIENT = 8;

export const TABS = [
  buildNavTab(ADMIN + OWNER, "dashboard", "Home", <HomeOutlined />),
  buildNavTab(ADMIN, "providers", "Service Providers", <MedicineBoxOutlined />, "MANAGE"),
  buildNavTab(OWNER, "technicians", "Technicians", <MedicineBoxOutlined />, "MANAGE"),
  buildNavTab(
    ADMIN + OWNER + TECH + CLIENT,
    "service-requests",
    "Service Requests",
    <ProfileOutlined />,
    "MANAGE"
  ),
  buildNavTab(ADMIN + OWNER, "inventory", "Inventory", <ToolOutlined />, "MANAGE"),
  buildNavTab(
    ADMIN + OWNER + TECH + CLIENT,
    "account",
    "Account",
    <MedicineBoxOutlined />,
    "MANAGE"
  ),
  buildNavTab(ADMIN + CLIENT, "devices", "Devices", <MedicineBoxOutlined />, "MANAGE"),
];
