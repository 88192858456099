import styles from "./DashboardComponent.module.css";
import {Card, Popover, DatePicker} from "antd";
import {CalendarOutlined} from "@ant-design/icons";
const {RangePicker} = DatePicker;
const DashboardComponent = (props) => {
  return (
    <div style={props.style}>
      <div className={styles.title}>
        {props.title}
        {props.title === "Who are the busiest service providers?" && (
          <Popover
            placement="bottom"
            content={
              <>
                <RangePicker
                  value={props.dates}
                  onChange={(val) => {
                    props.setDates(val);
                  }}
                />
                <br />
              </>
            }
            title="Pick a period to show information "
          >
            <CalendarOutlined className="calender-icon" style={{marginRight: "5%"}} />
          </Popover>
        )}
      </div>
      <Card
        bodyStyle={{
          paddingTop: 0,
          paddingBottom: 0,
          overflow: !props.noOverflow ? "auto" : undefined,
        }}
        bordered={false}
      >
        {props.children}
      </Card>
    </div>
  );
};
export default DashboardComponent;
