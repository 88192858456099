import React, {useEffect} from "react";
import {Col} from "antd";
import Ticket from "./Ticket/Ticket";
import {getTicketStatusOverview} from "../../services/dashboard";
import {useQuery} from "react-query";

const Tickets = ({dates}) => {
  const {
    isFetching: isLoadingTickets,
    error: errorLoadingTickets,
    data: tickets,
    refetch: refetchTickets,
  } = useQuery(
    "ticketStatusOverview",
    () =>
      getTicketStatusOverview({
        start: dates && dates[0]?.format("YYYY-MM-DD"),
        end: dates && dates[1]?.format("YYYY-MM-DD"),
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    refetchTickets({
      start: dates && dates[0].format("YYYY-MM-DD"),
      end: dates && dates[1].format("YYYY-MM-DD"),
    });
  }, [dates]);

  const getTicketCompByKey = (key) => {
    return tickets?.data.filter((ticket) => ticket.key === key)[0];
  };

  const map = {
    pending: "Pending Requests",
    "in-progress": "In-Progress Requests",
    done: "Completed Requests",
    total: "Total Requests",
  };
  const tooltip = {
    pending:
      "Tickets that are still under review and no technician has been assigned to them yet.",
    "in-progress": "Tickets that technicians have started working on.",
    done: "Tickets that are complete.",
    total:
      "The total number of tickets (pending, in-progress, and complete). Ignoring rejected tickets.",
  };
  const getTitle = (key) => {
    return map[key];
  };

  const getTooltip = (key) => {
    return tooltip[key];
  };

  const buildTicketComp = (key) => {
    const comp = getTicketCompByKey(key);
    return (
      <Col span={6} xs={24} sm={12} xl={6} lg={12}>
        <Ticket
          title={getTitle(key)}
          tooltipTitle={getTooltip(key)}
          value={comp ? comp.count : "-"}
          isLoading={isLoadingTickets}
          error={errorLoadingTickets}
        />
      </Col>
    );
  };

  return (
    <>
      {buildTicketComp("total")}
      {buildTicketComp("pending")}
      {buildTicketComp("in-progress")}
      {buildTicketComp("done")}
    </>
  );
};
export default Tickets;
