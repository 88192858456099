import React, {useEffect, useState} from "react";
import {Card, Progress, Row, Col, List, Tooltip} from "antd";
import {getDefectCausePercentage} from "../../services/dashboard";
import {useQuery} from "react-query";
import DashboardComponent from "./DashboardComponent";
import {BsFillCircleFill} from "react-icons/bs";
import LoadingComponent from "../LoadingComponent";

const CauseOfDefect = (props) => {
  const {isFetching: isLoading, error, data: defectCauseData} = useQuery(
    "defectCausePercentage",
    getDefectCausePercentage,
    {
      refetchOnWindowFocus: false,
    }
  );
  const [title, setTitle] = useState("");
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    if (defectCauseData?.data.count === 2) {
      let percent = defectCauseData?.data.data.filter((item) => item.id === 1)[0]
        .percentage;
      const manu = defectCauseData?.data.data[0].percentage;
      const external = defectCauseData?.data.data[1].percentage;
      setTitle(`Manufacturing: ${manu}% / External: ${external}%`);
      setPercent(percent);
    }
  }, [defectCauseData?.data]);

  if (error) {
    return null;
  }

  const TotalCost = (props) => {
    return (
      <>
        <div style={{marginBottom: 12, fontSize: 16}}>Total Cost</div>
        <div style={{fontSize: 20, fontWeight: "bold"}}>{props.cost}</div>
      </>
    );
  };
  return defectCauseData?.data.count === 0 ? null : (
    <DashboardComponent
      style={props.style}
      title={`What are the costs for the different types of defects?`}
    >
      <Card bordered={false}>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <Row>
            <Col
              clas
              sName="gutter-row"
              span={12}
              style={{textAlign: "center", marginBottom: 24}}
            >
              <Tooltip title={title}>
                <Progress
                  percent={percent}
                  strokeLinecap="square"
                  strokeWidth="10"
                  strokeColor="#1890FF"
                  width="200px"
                  type="circle"
                  format={() => <TotalCost cost={defectCauseData?.data.total_cost_omr} />}
                  trailColor="#FADB14"
                />
              </Tooltip>
            </Col>
            <Col className="gutter-row" span={12}>
              <List
                itemLayout="horizontal"
                dataSource={defectCauseData?.data.data}
                renderItem={(item) => (
                  <List.Item style={{display: "block"}}>
                    <Col style={{display: "inline-flex"}}>
                      <BsFillCircleFill
                        style={{
                          fontSize: 10,
                          color: item.id === 1 ? "#1890FF" : "#FADB14",
                        }}
                      />
                    </Col>
                    <Col
                      style={{
                        marginLeft: 10,
                        width: 100,
                        display: "inline-flex",
                        color: "#595959",
                      }}
                    >
                      {item.value}
                    </Col>
                    <Col
                      style={{marginLeft: 10, display: "inline-flex", color: "#8C8C8C"}}
                    >{`${item.percentage}%`}</Col>
                  </List.Item>
                )}
                split={false}
                style={{
                  paddingTop: 50,
                  paddingLeft: 15,
                }}
              />
            </Col>
          </Row>
        )}
      </Card>
    </DashboardComponent>
  );
};

export default CauseOfDefect;
