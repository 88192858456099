import {Switch, Redirect} from "react-router-dom";
import ServiceRequestPage from "../views/serviceRequest/serviceRequestList";
import ServiceRequestDetails from "../views/serviceRequest/serviceRequestDetails";
import TechAccount from "../views/account";
import ChangePassword from "../views/changePassword";

import ProtectedRoute from "../protectedRoutes";

export default function TechnicianRoutes() {
  return (
    <Switch>
      <ProtectedRoute path="/service-requests" exact component={ServiceRequestPage} />
      <ProtectedRoute
        path="/service-requests/:serviceRequestNumber"
        component={ServiceRequestDetails}
      />
      <ProtectedRoute path="/account" exact component={TechAccount} />
      <ProtectedRoute path="/account/change-password" exact component={ChangePassword} />
      <Redirect from="/" to="service-requests" />
    </Switch>
  );
}
