import {Switch, Redirect} from "react-router-dom";
import Dashboard from "../views/dashboard/dashboard";
import SPPage from "../views/serviceProviders/serviceProviderList";
import SPDetails from "../views/serviceProviders/serviceProviderDetails";
import SRPage from "../views/serviceRequest/serviceRequestList";
import SRdetails from "../views/serviceRequest/serviceRequestDetails";
import NewSR from "../views/serviceRequest/newServiceRequest";
import Inventory from "../views/inventory/inventory";
import NewSparePart from "../views/inventory/newSparePart";
import InventoryDetails from "../views/inventory/inventoryDetails";
import ProtectedRoute from "../protectedRoutes";
import TechnicianDetails from "../views/technicians/technicianDetails";
import Devices from "../views/devices";
import Account from "../views/account";
import ChangePassword from "../views/changePassword";

export default function SuperAdminRoutes() {
  return (
    <Switch>
      <ProtectedRoute path="/dashboard" component={Dashboard} />
      <ProtectedRoute path="/providers" exact component={SPPage} />
      <ProtectedRoute path="/providers/:id" exact component={SPDetails} />
      <ProtectedRoute path="/technicians/:id" exact component={TechnicianDetails} />
      <ProtectedRoute path="/service-requests" exact component={SRPage} />
      <ProtectedRoute path="/service-requests/new" exact component={NewSR} />
      <ProtectedRoute
        path="/service-requests/:serviceRequestNumber"
        component={SRdetails}
      />
      <ProtectedRoute path="/inventory" exact component={Inventory} />
      <ProtectedRoute path="/inventory/new" exact component={NewSparePart} />
      <ProtectedRoute path="/inventory/:id" exact component={InventoryDetails} />
      <ProtectedRoute path="/devices" exact component={Devices} />
      <ProtectedRoute path="/account" exact component={Account} />
      <ProtectedRoute path="/account/change-password" exact component={ChangePassword} />
      <Redirect from="/" to="dashboard" />
    </Switch>
  );
}
