import api from "../api";

export const getGovernorates = async () => {
  return await api.get(`/static/governorates`);
};

export const getProvinces = async (id) => {
  return await api.get(`/static/governorates/${id}`);
};

export const getIssueTypes = async () => {
  return await api.get(`/static/issue-types`);
};

export const getInventoryTypes = async () => {
  return await api.get(`/static/inventory-types`);
};
