import {
  Col,
  Divider,
  Row,
  Select,
  Radio,
  Card,
  Modal,
  Input,
  InputNumber,
  message,
  Button,
  Tooltip,
} from "antd";
import {useForm, Controller} from "react-hook-form";
import {
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useQuery, useMutation} from "react-query";
import {useState, useEffect} from "react";
import {getProvidersList} from "../../../services/providers";
import {getShopInventories, getMyPartsList} from "../../../services/inventories";
import {getTicketStatics, editServiceRequestIssue} from "../../../services/tickets";
import {getTechnicianList} from "../../../services/technician";
import {useParams} from "react-router-dom";
import {
  isTech,
  isAdminOrClient,
  isAdmin,
  isClient,
  isOwner,
} from "../../../utils/check-role";
import {shouldShowEditButton} from "../../../ticket-helper";

const {Option} = Select;
const {TextArea} = Input;
const {confirm} = Modal;

export default function CustomerTab(props) {
  const formData = props.data ? props.data : null;
  const [isView, setisView] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Save changes");
  const [allPartsList, setAllPartsList] = useState([]);
  const [allPartsWithPrice, setAllPartsWithPrice] = useState([]);
  const [partsList, setPartsList] = useState(formData ? formData.spare_parts : []);
  const [isOther, setIsOther] = useState();
  const [othersId, setOthersId] = useState();
  const {serviceRequestNumber} = useParams();

  const schema = yup.object().shape({
    title: yup
      .array()
      .min(1, "Issue Title is required")
      .required("Issue Title is required"),
    status: yup.string().required("Overall Status is required"),
    isOnWarranty: yup.bool().required("Covered by Warranty is required").nullable(),
    defectCause: yup.string().required("Cause of Defect is required"),
    assigned: yup.string().required("Assigned is required"),
    comments: yup.string().required("Comments is required").nullable(),
    services: yup.array().min(1).required("Service is required"),
    description: yup.string().when("services", {
      is: (value) => value.includes(othersId),
      then: yup.string().required("Description is Required"),
    }),
    price: yup.string().when("services", {
      is: (value) => value.includes(othersId),
      then: yup
        .string()
        .required("Price is required")
        .test(
          "is-decimal",
          "Price Must be a number with 3 decimal places at most",
          (value) => (value + "").match(/^\d*\.?\d{3}$/)
        ),
    }),
  });

  useEffect(() => {
    setLoadingText(loading ? "Saving changes..." : "Save changes");
  }, [loading]);

  const assignedUsersCall = isAdmin()
    ? getProvidersList
    : isOwner()
    ? getTechnicianList
    : null;
  const getPartsCall = isAdminOrClient()
    ? getShopInventories
    : () => getMyPartsList(serviceRequestNumber);
  const {data: assignedUsers} = useQuery("splist", assignedUsersCall);
  const {
    isLoading: isLoadingServiceRequestStaticData,
    error: errorLoadingServiceRequestStaticData,
    data: serviceRequestStaticData,
  } = useQuery("inventoryList", getTicketStatics);

  const PartsMutation = useMutation(getPartsCall, {
    onSuccess: (data, variables, context) => {
      if (data.status >= 200 && data.status < 300) {
        setAllPartsList(data.data);
        setAllPartsWithPrice(data.data);
      }
    },
    onError: (error, variables, context) => {
      message.error(error.response.data.error.message);
    },
  });

  const editRequestIssueMutation = useMutation(editServiceRequestIssue, {
    onSuccess: (data, variables, context) => {
      if (data.status >= 200 && data.status < 300) {
        setLoading(false);
        message.success("Service Request updated successfully");
        setisView(true);
        props.refetch();
      } else {
        message.error(data.statusText);
      }
    },
    onError: (error, variables, context) => {
      setLoading(false);
      let msg = error.response.data?.error?.message;
      message.error(msg ? msg : "Failed to update the information");
    },
  });
  function mutateParts(id) {
    if (id) {
      PartsMutation.mutate(id);
    } else {
      PartsMutation.mutate();
    }
  }
  useEffect(() => {
    if (formData && isAdminOrClient()) {
      mutateParts(formData.shop_id);
    }
    if (localStorage.getItem("role") === "owner") {
      mutateParts();
    }
    formData?.ticket_services?.forEach((v) => {
      if (v.service_id === othersId) {
        setIsOther(true);
      }
    });
  }, [formData]);

  useEffect(() => {
    formData?.ticket_services?.forEach((v) => {
      if (v.service_id === othersId) {
        setIsOther(true);
      }
    });

    let cost = 0;
    formData?.ticket_services?.forEach((v) => {
      cost += v.cost;
    });
    setServicePrice(Math.round((cost + Number.EPSILON) * 100) / 100);
  }, [formData?.ticket_services, othersId]);

  const getServicesOptions = () => {
    let options = [];
    if (serviceRequestStaticData?.data && serviceRequestStaticData.data.services) {
      serviceRequestStaticData.data.services.forEach((service) => {
        if (service.cost) {
          options.push({label: service.name, value: service.id});
        } else {
          options.push({label: service.name, value: service.id});
          setOthersId(service.id);
        }
      });
    }
    return options;
  };

  const {setValue, handleSubmit, errors, control, reset, getValues} = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setLoading(true);
    if (data.services.includes(othersId)) {
      let temp = data;
      temp.services = [othersId];
      data = temp;
    }
    let submittedDats = {...data, partsList, id: formData.id};
    //send data to API
    editRequestIssueMutation.mutate(submittedDats);
  };
  function onChangeRadioWarenty(e) {
    setValue("isOnWarranty", e.target.value);
  }
  function onChangeRadioCase(e) {
    setValue("defectCause", e.target.value);
  }
  // function isFilled() {
  //   let filled = true;
  //   partsList.forEach((element) => {
  //     if (!element.item) filled = false;
  //   });
  //   return filled;
  // }

  function getPartOptions(id) {
    let list = [];
    allPartsList.length > 0 &&
      allPartsList.forEach((item) => {
        let x = true;
        partsList &&
          partsList.forEach((elm) => {
            if (elm.id === item.id) x = false;
          });
        if (x || (id && id === item.id))
          list.push({
            label: item.name,
            value: item.id,
          });
      });
    return list ? list : [];
  }

  function getCost() {
    let cost = 0;
    cost = getPartCost() + getVATCost() + servicePrice;
    return Math.round((cost + Number.EPSILON) * 100) / 100;
  }

  function getPartCost() {
    let cost = 0;

    partsList.forEach((element) => {
      let part = allPartsWithPrice.find((o) => o.id === element.id);
      if (part) cost += element.quantity * part.price;
    });
    return Math.round((cost + Number.EPSILON) * 100) / 100;
  }
  const [servicePrice, setServicePrice] = useState(getServiceCost());
  function getServiceCost() {
    if (getValues().services?.includes(othersId)) {
      if (parseFloat(getValues().price)) {
        return Math.round((parseFloat(getValues().price) + Number.EPSILON) * 100) / 100;
      }
      return 0;
    }
    let cost = 0;
    if (getValues().services && getValues().services.length > 0) {
      serviceRequestStaticData?.data?.services?.forEach((element) => {
        if (getValues().services?.includes(element.id)) cost += element.cost;
      });
    }
    return Math.round((cost + Number.EPSILON) * 100) / 100;
  }

  function getVATCost() {
    let cost = 0;
    if (getPartCost() > 0) cost = getPartCost() * 0.05;
    return Math.round((cost + Number.EPSILON) * 100) / 100;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id="issueForm"
      onReset={() => {
        setIsOther(formData?.ticket_services.find((o) => o.description) ? true : false);
        setPartsList(formData ? formData.spare_parts : []);
        reset({
          title: formData?.issue_types_admin.map((item) => {
            return item.id;
          }),
          status: formData?.overall_status?.id,
          isOnWarranty: formData?.is_covered_by_warranty,
          defectCause: formData?.defect_cause?.id,
          assigned:
            localStorage.getItem("role") === "admin"
              ? formData?.shop_id
              : formData?.assigned_technician?.id,
          comments: formData?.issue_comments,
          services: formData?.ticket_services?.map((v) => {
            return v.service_id;
          }),
          description: formData?.ticket_services.find((o) => o.description)?.description,
          price: formData?.ticket_services.find((o) => o.description)?.cost,
        });
      }}
    >
      {shouldShowEditButton() ? (
        <div>
          <Button
            onClick={() => {
              !isView &&
                confirm({
                  icon: <ExclamationCircleOutlined />,
                  title: "Are you sure you want to cancel?",
                  content: (
                    <span>You will lose all the changes you’ve made on this page.</span>
                  ),
                  okButtonProps: {form: "issueForm", key: "cancel", htmlType: "reset"},
                  onOk() {
                    //reset all feild to original
                    setisView(true);
                  },
                  onCancel() {},
                });

              isView && setisView(!isView);
            }}
            type={isView ? "primary" : ""}
          >
            {isView ? "Edit" : "Cancel"}
          </Button>
          {!isView ? (
            <Button
              disabled={loading}
              type="primary"
              onClick={() => {
                confirm({
                  icon: <ExclamationCircleOutlined />,
                  title: "Are you sure you want to save changes?",
                  okButtonProps: {form: "issueForm", key: "submit", htmlType: "submit"},
                  onCancel() {},
                });
              }}
              style={{float: "right"}}
            >
              {loadingText}
            </Button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <br />
      <Card className="site-layout-background" style={{minHeight: "77vh"}}>
        <Divider
          orientation="left"
          style={{
            fontSize: 18,
            marginBottom: 0,
          }}
        >
          Issue Details
        </Divider>
        <Row style={{width: "90%", paddingLeft: "2%"}}>
          <Col sm={24} md={12}>
            <div style={{margin: "40px"}}>
              <Row className="row-padding">
                <label>Issue Title*</label>
                <Controller
                  render={() => {
                    return (
                      <Select
                        mode="multiple"
                        style={{width: "100%"}}
                        disabled={isView || loading}
                        options={
                          errorLoadingServiceRequestStaticData
                            ? [...[], {label: "Failed to load", value: null}]
                            : isLoadingServiceRequestStaticData
                            ? [...[], {label: "Loading", value: null}]
                            : serviceRequestStaticData?.data.issue_types.map((v) => {
                                return {label: v.value, value: v.id};
                              })
                        }
                        defaultValue={formData?.issue_types_admin.map((item) => {
                          return item.id;
                        })}
                        value={getValues().title}
                        onChange={(e) => {
                          setValue("title", e);
                        }}
                      />
                    );
                  }}
                  defaultValue={formData?.issue_types_admin.map((item) => {
                    return item.id;
                  })}
                  name="title"
                  disabled={isView || loading}
                  control={control}
                />
                <span className="invalid">{errors.title?.message}</span>
              </Row>

              <Row className="row-padding">
                <label>
                  {isAdminOrClient()
                    ? "Assigned service provider*"
                    : "Assigned Technician*"}
                </label>
                <Controller
                  render={() => {
                    return (
                      <Select
                        disabled={isView || loading}
                        style={{width: "100%"}}
                        defaultValue={
                          isAdmin()
                            ? formData?.shop_id
                            : isClient()
                            ? formData?.shop_name
                            : formData?.assigned_technician?.id
                        }
                        value={getValues().assigned}
                        onChange={(e) => {
                          if (localStorage.getItem("role") === "admin") {
                            PartsMutation.mutate(e);
                          }
                          setValue("assigned", e);
                        }}
                      >
                        {localStorage.getItem("role") === "admin" &&
                          assignedUsers?.data.length > 0 &&
                          assignedUsers?.data.map((item) => {
                            return <Option value={item.id}>{item.shop_name}</Option>;
                          })}
                        {localStorage.getItem("role") === "owner" &&
                          assignedUsers?.data.length > 0 &&
                          assignedUsers?.data.map((item) => {
                            return (
                              <Option value={item.id}>
                                {item.first_name + " " + item.last_name}
                              </Option>
                            );
                          })}
                        {isTech() && (
                          <Option value={formData?.assigned_technician.id}>
                            {formData?.assigned_technician.first_name +
                              " " +
                              formData?.assigned_technician.last_name}
                          </Option>
                        )}
                      </Select>
                    );
                  }}
                  name="assigned"
                  defaultValue={
                    localStorage.getItem("role") === "admin"
                      ? formData?.shop_id
                      : isClient()
                      ? formData?.shop_name
                      : formData?.assigned_technician?.id
                  }
                  control={control}
                />
                <span className="invalid">{errors.assigned?.message}</span>
              </Row>

              <Row>
                <Col span={18} style={{padding: "0px 5px"}}>
                  {localStorage.getItem("role") === "admin" ? (
                    <Tooltip title="You must select provider to show options">
                      <label>Part(s) to be Replaced</label>
                    </Tooltip>
                  ) : (
                    <label>Part(s) to be Replaced</label>
                  )}
                </Col>
                <Col span={6} style={{padding: "0px 5px"}}>
                  <label>Quantity</label>
                </Col>
              </Row>
              {partsList.map((part, index) => {
                return (
                  <Row>
                    <Col span={17} style={{padding: "0px 5px"}}>
                      <Select
                        style={{width: "100%"}}
                        value={part.id}
                        disabled={isView || loading}
                        onChange={(e) => {
                          let temp = [...partsList];
                          temp[index].id = e;
                          allPartsList.forEach((element) => {
                            if (element.id === e) temp[index].item = element;
                          });
                          setPartsList(temp);
                        }}
                        options={getPartOptions(part.id)}
                      />
                    </Col>
                    <Col span={6} style={{padding: "0px 5px"}}>
                      <InputNumber
                        type="number"
                        min={1}
                        style={{width: "100%"}}
                        value={part.quantity}
                        disabled={isView || loading}
                        onChange={(e) => {
                          allPartsList.forEach((element) => {
                            if (element.id === part.id) {
                              if (element.quantity >= e) {
                                let temp = [...partsList];
                                temp[index].quantity = parseInt(e);
                                setPartsList(temp);
                              } else message.warn("not having enough supplies");
                            }
                          });
                        }}
                      />
                    </Col>
                    {!isView && (
                      <Col span={1}>
                        <MinusCircleOutlined
                          onClick={() => {
                            let temp = [...allPartsList];
                            temp.splice(index, 1);
                            setAllPartsList(temp);
                          }}
                          style={{paddingTop: "8px", position: "relative", right: 0}}
                        />
                      </Col>
                    )}
                  </Row>
                );
              })}
              {!isView ? (
                <Row className="row-padding">
                  <Button
                    type="dashed"
                    block
                    disabled={allPartsList.length === partsList.length}
                    onClick={() => {
                      let temp = [...partsList];
                      temp.push({item: null, quantity: 1});
                      setPartsList(temp);
                    }}
                  >
                    <PlusCircleOutlined />
                    Add another part
                  </Button>
                </Row>
              ) : (
                ""
              )}

              <Row className="row-padding">
                <label>Services</label>
                <Row
                  style={!isOther ? {width: "100%", display: "none"} : {width: "100%"}}
                >
                  <Col span="17" style={{padding: "0px 5px"}}>
                    <Controller
                      as={<Input placeholder="Description" />}
                      defaultValue={
                        formData?.ticket_services.find((o) => o.description)?.description
                      }
                      name="description"
                      disabled={isView || loading}
                      control={control}
                    />
                    <span className="invalid">{errors.description?.message}</span>
                  </Col>
                  <Col span="6" style={{padding: "0px 5px"}}>
                    <Controller
                      render={() => {
                        return (
                          <Input
                            defaultValue={
                              formData?.ticket_services.find((o) => o.description)?.cost
                            }
                            value={getValues().price}
                            placeholder="price"
                            disabled={isView || loading}
                            onChange={(e) => {
                              setValue("price", e.target.value);
                              setServicePrice(getServiceCost());
                            }}
                          />
                        );
                      }}
                      name="price"
                      control={control}
                      defaultValue={
                        formData?.ticket_services.find((o) => o.description)?.cost
                      }
                    />
                    <span className="invalid">{errors.price?.message}</span>
                  </Col>
                  <Col span="1">
                    <MinusCircleOutlined
                      style={{paddingTop: "8px", position: "relative", right: 0}}
                      onClick={() => {
                        setIsOther(false);
                        setValue("services", undefined);
                        setValue("price", undefined);
                        setValue("description", undefined);
                        setServicePrice(getServiceCost());
                      }}
                    />
                  </Col>
                </Row>

                <div style={isOther ? {width: "100%", display: "none"} : {width: "100%"}}>
                  <Controller
                    render={() => {
                      return (
                        <Select
                          disabled={isView || loading}
                          style={{width: "100%"}}
                          mode="multiple"
                          value={getValues().services}
                          options={getServicesOptions()}
                          onChange={(e) => {
                            if (e.includes(othersId)) {
                              setValue("services", [othersId]);
                              setIsOther(true);
                              setServicePrice(getServiceCost());
                            } else {
                              setValue("services", e);
                              setServicePrice(getServiceCost());
                            }
                          }}
                        />
                      );
                    }}
                    name="services"
                    defaultValue={formData?.ticket_services?.map((v) => {
                      return v.service_id;
                    })}
                    control={control}
                  />
                  <span className="invalid">{errors.services?.message}</span>
                </div>
              </Row>

              <Row className="row-padding">
                <label>Covered by Warranty?*</label>
                <Controller
                  render={() => {
                    return (
                      <Radio.Group
                        defaultValue={formData?.is_covered_by_warranty}
                        onChange={onChangeRadioWarenty}
                        value={getValues().isOnWarranty}
                        style={{width: "100%"}}
                        disabled={isView || loading}
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    );
                  }}
                  name="isOnWarranty"
                  disabled={isView || loading}
                  defaultValue={formData?.is_covered_by_warranty}
                  control={control}
                />
                <span className="invalid">{errors.isOnWarranty?.message}</span>
              </Row>
              <Row className="row-padding">
                <label>Cause of Defect*</label>
                <Controller
                  render={() => {
                    return (
                      <Radio.Group
                        onChange={onChangeRadioCase}
                        value={getValues().defectCause}
                        defaultValue={formData?.defect_cause?.id}
                        style={{width: "100%"}}
                        disabled={isView || loading}
                      >
                        <Radio value={1}>Manufacturing</Radio>
                        <Radio value={2}>External</Radio>
                      </Radio.Group>
                    );
                  }}
                  name="defectCause"
                  defaultValue={formData?.defect_cause?.id}
                  control={control}
                  disabled={isView || loading}
                />
                <span className="invalid">{errors.defectCause?.message}</span>
              </Row>

              <Row className="row-padding">
                <label>Spare Part Cost</label>
                <Input disabled={true} value={getPartCost()} />
              </Row>
              <Row className="row-padding">
                <label>Service Cost</label>
                <Input
                  disabled={true}
                  value={isView && formData ? formData?.total_service_cost : servicePrice}
                />
              </Row>
              <Row className="row-padding">
                <label>VAT Cost</label>
                <Input disabled={true} value={getVATCost()} />
              </Row>
              <Row className="row-padding">
                <label>Total Cost</label>
                <Input
                  disabled={true}
                  value={isView && formData ? formData?.total_price : getCost()}
                />
              </Row>
            </div>
          </Col>

          <Col sm={24} md={12}>
            <div style={{margin: "40px"}}>
              <Row className="row-padding">
                <label>Overall Status*</label>
                <Controller
                  render={() => {
                    return (
                      <Select
                        style={{width: "100%"}}
                        disabled={isView || loading}
                        options={
                          errorLoadingServiceRequestStaticData
                            ? [...[], {label: "Failed to load", value: null}]
                            : isLoadingServiceRequestStaticData
                            ? [...[], {label: "Loading", value: null}]
                            : serviceRequestStaticData?.data.overall_statuses.map((v) => {
                                return {label: v.value, value: v.id};
                              })
                        }
                        defaultValue={formData?.overall_status?.id}
                        value={getValues().status}
                        onChange={(e) => {
                          setValue("status", e);
                        }}
                      />
                    );
                  }}
                  name="status"
                  defaultValue={formData?.overall_status?.id}
                  disabled={isView || loading}
                  control={control}
                />
                <span className="invalid">{errors.status?.message}</span>
              </Row>
              <Row className="row-padding">
                <label>Comments*</label>
                <Col span={24}>
                  <Controller
                    as={<TextArea showCount maxLength={1000} rows={4} />}
                    name="comments"
                    defaultValue={formData?.issue_comments}
                    control={control}
                    disabled={isView || loading}
                  />
                  <span className="invalid">{errors.comments?.message}</span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Card>
    </form>
  );
}
