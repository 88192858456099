import api from "../api";

export const getTicketStatusOverview = async ({start, end}) => {
  if (start && end) {
    return await api.get(`/dashboard/ticket-status-overview?from=${start}&to=${end}`);
  } else {
    return await api.get(`/dashboard/ticket-status-overview`);
  }
};

export const getServiceProviderTechsList = async ({start, end}) => {
  if (start && end) {
    return await api.get(`/dashboard/list-sp-techs?from=${start}&to=${end}`);
  } else {
    return await api.get(`/dashboard/list-sp-techs`);
  }
};

export const getMostReplacedParts = async () => {
  return await api.get(`/dashboard/most-replaced-parts`);
};

export const getDefectCausePercentage = async () => {
  return await api.get(`/dashboard/defect-cause-percentage`);
};

export const getTotalCostPerWeek = async () => {
  return await api.get(`/dashboard/graph?last=week`);
};

export const getTotalCostPerMonth = async () => {
  return await api.get(`/dashboard/graph?last=month`);
};

export const getTotalCostPerYear = async () => {
  return await api.get(`/dashboard/graph?last=year`);
};

export const getTicketClosed = async ({start, end}) => {
  if (start && end) {
    return await api.get(`/dashboard/get-closed-tickets?from=${start}&to=${end}`);
  } else {
    return await api.get(`/dashboard/get-closed-tickets`);
  }
};

export const getTicketLeadTime = async ({start, end}) => {
  if (start && end) {
    return await api.get(`/dashboard/get-ticket-lead-time?from=${start}&to=${end}`);
  } else {
    return await api.get(`/dashboard/get-ticket-lead-time`);
  }
};
