import React from "react";
import {useHistory} from "react-router-dom";

import {Table, Tag} from "antd";
import "antd/dist/antd.css";
import styles from "./technician.module.css";
import searchNow from "../../utils/search-helper";

const columns = [
  {
    title: "Technician",
    dataIndex: "full_name",
    searchable: true,
    sorter: (record, nextRecord) => record.full_name > nextRecord.full_name,
  },

  {
    title: "Email",
    dataIndex: "email",
    searchable: true,
    sorter: (record, nextRecord) => record.email > nextRecord.email,
  },
  {
    title: "Phone Number",
    dataIndex: "full_phone_number",
    searchable: true,
    sorter: (record, nextRecord) => record.phone_number > nextRecord.phone_number,
  },
  {
    title: "Status",
    dataIndex: "is_active",
    sorter: (record, nextRecord) => record.is_active > nextRecord.is_active,
    render: (key) => {
      if (key) {
        return <Tag color="green">active</Tag>;
      } else {
        return <Tag>disabled</Tag>;
      }
    },
  },
];

export default function Technicians({filtration, data, isLoading = false}) {
  const {status, search} = filtration;
  const history = useHistory();
  let filteredData = data?.filter((record) => {
    if (status === "all") return true;
    return record.is_active === status;
  });
  filteredData = searchNow(filteredData, search, columns);

  return (
    <Table
      onRow={(record) => {
        return {
          onClick: () => {
            history.push(`/technicians/${record.id}`);
          },
        };
      }}
      rowClassName={styles.technicianRow}
      columns={columns}
      dataSource={filteredData}
      pagination={{
        pageSizeOptions: ["10", "15", "20"],
        defaultPageSize: 15,
        showSizeChanger: true,
        pageSize: history.location?.state?.currentSize
          ? history.location.state.currentSize
          : 10,
        onShowSizeChange: (page, size) => {
          let state = {...history.location.state};
          state.currentSize = size;
          history.replace({...history.location, state});
        },
        current: history.location?.state?.currentPage
          ? history.location.state.currentPage
          : 1,
        onChange: (currentPage) => {
          let state = {...history.location.state};
          state.currentPage = currentPage;
          history.replace({...history.location, state});
        },
      }}
      loading={isLoading}
    />
  );
}
