import api from "../api";

export const getOwnerProfile = async (ownerId) => {
  ownerId = ownerId.queryKey[0];
  return await api.get(`/customers/shops/${ownerId}`);
};

export const getServiceProviders = async () => {
  return await api.get(`/customers/shops`);
};

export const createServiceRequest = async (ticket) => {
  let images = ticket.Upload_Images
    ? ticket.Upload_Images.map((img) => {
        return img.response && Array.isArray(img.response)
          ? img.response[0].guid
          : undefined;
      })
    : [];

  let body = {
    customer_first_name: ticket.First_Name,
    customer_last_name: ticket.Last_Name,
    customer_email: ticket.Owner_Email,
    customer_phone_area_code: "968",
    customer_phone_number: ticket.Phone_Number,
    customer_comments: ticket.Device_issue,
    customer_images: images,
    customer_issue_types: ticket.Type_of_issue.map((issue) => {
      return issue.value;
    }),
    serial_number: ticket.Serial_Number,
    shop_owner_id: parseInt(ticket.id),
  };

  return await api.post(`/customers/tickets`, body);
};
