import React from "react";
import styles from "./Box.module.css";
const Box = (props) => {
  return (
    <div
      style={{
        position: props.relative ? "relative" : "",
      }}
      className={styles.root}
    >
      {props.children}
    </div>
  );
};
export default Box;
