import React from "react";
import styles from "./Marker.module.css";

const Marker = (props) => {
  const {name} = props;
  return (
    <div onClick={props.onClick}>
      <div className={`${styles.marker} ${styles.bounce} ${styles.pin}`} title={name} />
      <div className={styles.pulse} />
    </div>
  );
};
export default Marker;
