import {Card, Steps, Breadcrumb} from "antd";
import {Layout} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import AddPart from "../../components/forms/newSparePartStep1";
import Step2 from "../../components/forms/newSparePartStep2";
const {Step} = Steps;
const {Header, Content} = Layout;

export default function NewSparePart() {
  const history = useHistory();
  const [current, setCurrent] = useState(0);
  const [myForms1Data, setMyForms1Data] = useState({});
  const [myForms2Data, setMyForms2Data] = useState({});
  const next = (data) => {
    setCurrent(current + 1);
    setMyForms1Data(data);
  };

  const prev = (data) => {
    setCurrent(current - 1);
    setMyForms2Data(data);
  };
  const steps = [
    {
      title: "Add Spare Part",
      content: <AddPart next={next} formData={myForms1Data} />,
    },
    {
      title: "Assign Service Providers",
      content: <Step2 prev={prev} formData={myForms2Data} form1Data={myForms1Data} />,
    },
  ];
  return (
    <Layout>
      <Header className="breadcrumb-header">
        <Breadcrumb style={{margin: "16px 0"}}>
          <Breadcrumb.Item>
            <Link to="/inventory">Inventory</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Add Spare Part</Breadcrumb.Item>
        </Breadcrumb>
        <h2>
          <ArrowLeftOutlined
            onClick={() => {
              history.goBack();
            }}
          />{" "}
          Add Spare Part
        </h2>
      </Header>
      <Content style={{margin: "24px 0px 0"}}>
        <Card className="site-layout-background" style={{padding: 24, minHeight: "77vh"}}>
          <Steps current={current} className="steps-header">
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div id="contentOfStep">{steps[current].content}</div>
        </Card>
      </Content>
    </Layout>
  );
}
