import React, {useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {InputNumber, Col, Button, Row, Select, Space, message} from "antd";
import {useMutation, useQuery} from "react-query";
import {useHistory} from "react-router-dom";
import "antd/dist/antd.css";
import "./forms.css";
import {getProvidersList} from "../../services/providers";
import {createSparePart, assignSparePart} from "../../services/inventories";
import {MinusCircleOutlined} from "@ant-design/icons";

function Step2(props) {
  const formData = props.formData;
  const history = useHistory();
  const [selectedProviders, setSelectedProviders] = useState(
    formData.selectedProviders ? formData.selectedProviders : []
  );
  const [isSkip, setIsSKip] = useState(false);
  const [options, setOptions] = useState(formData.options ? formData.options : []);
  const {
    isLoading: isLoadingProvidersList,
    error: errorLoadingProvidersList,
    data: providersList,
  } = useQuery("providersList", getProvidersList);

  useEffect(() => {
    if (!formData.options) {
      setOptions(providersList?.data);
    }
  }, [providersList?.data]);

  useEffect(() => {
    setOptions(formData?.options);
  }, [formData?.options]);

  useEffect(() => {
    if (formData?.selectedProviders && !props.isEdit) {
      setSelectedProviders(formData?.selectedProviders);
    }
  }, [formData?.selectedProviders]);

  if (errorLoadingProvidersList) {
    message.error(JSON.stringify(providersList.statusText));
  }

  const CreateParts = useMutation(createSparePart, {
    onSuccess: (data) => {
      if (!isSkip) {
        //call assign providers API
        AssignParts.mutate({shops: selectedProviders, id: data.data.id});
      } else {
        message.success("Spare part created successfully");
        history.push("/inventory");
      }
    },
    onError: (error) => {
      message.error(error.response.data.error.message);
    },
  });

  const AssignParts = useMutation(assignSparePart, {
    onSuccess: (data) => {
      if (props.isEdit) {
        props.setLoading(false);
        props.setLoadingText("Save changes");
        message.success("Spare part assigned to shops successfully");
        props.setEdit(false);
        props.refetch();
      } else {
        message.success("Spare part created and assigned to shops successfully");
        history.push("/inventory");
      }
    },
    onError: (error) => {
      props.setLoading(false);
      props.setLoadingText("Save changes");
      message.error(error.response.data.error.message);
    },
  });

  const {handleSubmit, control} = useForm();

  const onSubmit = async (data) => {
    // eslint-disable-next-line
    if (props.isEdit) {
      props.setLoading(true);
      props.setLoadingText("Saving changes...");
      AssignParts.mutate({shops: selectedProviders, id: props.id});
    } else {
      CreateParts.mutate(props.form1Data);
    }
  };
  return (
    <div className="steps-div">
      <form id="partsForm" onSubmit={handleSubmit(onSubmit)}>
        <p className="ant-upload-hint" style={{marginTop: "40px"}}>
          Select the Service Provider{`(s)`} you want to assign the spare part to {`&`}{" "}
          choose the quantity.
        </p>
        <Controller
          render={() => {
            return (
              <Select
                placeholder={`Search & Add Service Provider`}
                style={{width: "45%"}}
                value={null}
                onSelect={(e) => {
                  providersList.data.forEach((element) => {
                    if (element.id === e) {
                      let newList = [...selectedProviders];
                      console.log({element: element, quantity: 1});
                      newList.push({element: element, quantity: 1, current: 0});
                      setSelectedProviders(newList);
                    }
                  });
                  options.forEach((element, index) => {
                    if (element.id === e) {
                      let newList = [...options];
                      newList.splice(index, 1);
                      setOptions(newList);
                    }
                  });
                }}
                options={
                  isLoadingProvidersList
                    ? [{label: "Loading...", value: "Loading"}]
                    : options &&
                      options.map((v) => {
                        return {label: v.shop_name, value: v.id};
                      })
                }
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            );
          }}
          name="providersList"
          control={control}
        />
        <Row className="row-padding"></Row>

        <Row>
          <Col span={11} style={{padding: "0px", marginBottom: "12px"}}>
            <label>
              <strong>Service Provider</strong>
            </label>
          </Col>
          {props.isEdit && (
            <Col span={3} style={{padding: "0 10px ", marginBottom: "12px"}}>
              <label>
                <strong>Current</strong>
              </label>
            </Col>
          )}
          <Col span={5} style={{padding: "0px", marginBottom: "12px"}}>
            <label>
              <strong>Quantity</strong>
            </label>
          </Col>
          {props.isEdit && (
            <Col span={3} style={{padding: "0 10px ", marginBottom: "12px"}}>
              <label>
                <strong>Total</strong>
              </label>
            </Col>
          )}
        </Row>
        {selectedProviders.map((v, index) => {
          return (
            <Row>
              <Col span={11} style={{padding: "0px", marginBottom: "12px"}}>
                <label>{v.element.shop_name}</label>
              </Col>
              {props.isEdit && (
                <Col span={3} style={{padding: "0 10px ", marginBottom: "12px"}}>
                  <label>
                    <strong>{v.current}</strong>
                  </label>
                </Col>
              )}
              <Col span={5} style={{padding: "0px", marginBottom: "12px"}}>
                <InputNumber
                  min={0 - v.current}
                  defaultValue={v.quantity}
                  onChange={(defaultValue, min) => {
                    if (defaultValue < min) {
                      return "Quantity can not be less than the current number of items";
                    } else {
                      let newList = [...selectedProviders];
                      newList[index].quantity = defaultValue;
                      setSelectedProviders(newList);
                    }
                  }}
                />
              </Col>
              {props.isEdit && (
                <Col span={3} style={{padding: "0 10px", marginBottom: "12px"}}>
                  <label>
                    <strong>{parseInt(v.current) + parseInt(v.quantity)}</strong>
                  </label>
                </Col>
              )}
              <Col span={1} style={{padding: "0px", marginLeft: "10px"}}>
                <MinusCircleOutlined
                  onClick={() => {
                    let newList = [...selectedProviders];
                    let newOptions = [...options];
                    if (v.current === 0) {
                      let removed = newList.splice(index, 1);
                      setSelectedProviders(newList);
                      newOptions.push(removed[0].element);
                      setOptions(newOptions);
                    } else {
                      let newList = [...selectedProviders];
                      newList[index].quantity = 0;
                      setSelectedProviders(newList);
                    }
                  }}
                />
              </Col>
            </Row>
          );
        })}

        {!props.isEdit && (
          <div className="steps-action">
            <Space style={{float: "right"}}>
              <Button
                type="link"
                onClick={async () => {
                  await setIsSKip(true);
                  CreateParts.mutate(props.form1Data);
                }}
              >
                Skip and add spare part
              </Button>
              <Button type="primary" htmlType="submit">
                Done
              </Button>
            </Space>
            <Button
              style={{margin: "0 8px"}}
              onClick={() => {
                props.prev({selectedProviders, options});
              }}
            >
              Back
            </Button>
          </div>
        )}
      </form>
    </div>
  );
}

export default Step2;
