import {Card, Input, Col, message} from "antd";
import React, {useState} from "react";
import Table from "../../../components/tables/technician";
import "../serviceProvider.css";
import {useQuery} from "react-query";
import {getOwnerTechniciansList} from "../../../services/technician";
import {useParams} from "react-router-dom";

export default function TechniciansTab() {
  const {id} = useParams();
  const {
    isFetching: isLoadingTechnicians,
    error: errorLoadingTechnicians,
    data: technicians,
  } = useQuery("getOwnerTechniciansList", () => getOwnerTechniciansList(id), {
    refetchOnWindowFocus: false,
  });

  const [filter, setFilter] = useState({
    search: "",
    status: "all",
    location: "all",
  });

  const onSearch = (value) => {
    setFilter({...filter, search: value.trim()});
  };
  if (errorLoadingTechnicians) {
    message.error("Failed to load technicians.");
  }
  return (
    <Card bordered={false}>
      <Col xs={24} sm={10} md={8} lg={8} xl={12}>
        <Input.Search
          placeholder="input search text"
          onSearch={onSearch}
          enterButton
          className="search-input"
          allowClear="true"
        />
      </Col>
      <br />
      <Table
        filtration={filter}
        data={technicians?.data}
        isLoading={isLoadingTechnicians}
      />
    </Card>
  );
}
