import React, {useState, useEffect} from "react";
import ContentWrapper from "../../contentWrapper/ContentWrapper";
import styles from "./Second.module.css";
import "./Second.css";
import {
  beforeImageUpload,
  getSupportedImages,
  MAX_IMAGE_SIZE_STR,
} from "../../../file-upload";
import {Upload, message, Input, Button, Modal, Col, Row, Spin} from "antd";
import {InfoCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {confirmDevice} from "../../../services/devices";
import {useMutation} from "react-query";
import serialPlace from "../../../assets/images/serial_place.png";

function getImageContainerText() {
  return `Use high-quality ${getSupportedImages()} images less than ${MAX_IMAGE_SIZE_STR} each`;
}

const Second = (props) => {
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [serialNumber, setSerialNumber] = useState(props.second.get("Serial_Number"));
  const [open, setOpen] = useState(false);
  const [confirmDeviceError, setConfirmDeviceError] = useState("");
  const [isUploading, setUploading] = useState(false);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
  const [imageList, setImageList] = useState(
    props.second.get("Upload_Images") ? props.second.get("Upload_Images") : []
  );

  const [loading, setLoading] = useState(false);
  const ConfirmMutitation = useMutation(confirmDevice, {
    onSuccess: (data) => {
      message.success("Device found");
      displayInfo(data?.data);
      setLoading(false);
      setConfirmButtonDisabled(true);
    },
    onError: () => {
      message.error("Device was not found");
      setLoading(false);
    },
  });

  useEffect(() => {
    let newMap = new Map(props.second);
    const key = "Upload_Images";
    newMap.set(key, imageList);
    props.setSecond(newMap);
  }, [imageList]);

  const displayInfo = (response) => {
    //TODO:here I shall send API response to retrieve data
    let newMap = new Map(props.second);
    const key1 = "Serial_Number";
    const key2 = "Device";
    const key3 = "Date_Of_Purchase";
    const key4 = "Warranty_End_Date";
    newMap.set(key1, serialNumber);
    newMap.set(key2, response.device_name);
    newMap.set(key3, response.warranty_start_date);
    newMap.set(key4, response.warranty_end_date);
    props.setSecond(newMap);
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const uploadButton = () => {
    return imageList.length >= 8 ? null : (
      <div style={{border: "dashed 1px !important", padding: "10px"}}>
        <PlusOutlined />
        <div style={{marginTop: 8}}>Upload</div>
        {imageList.length === 0 && (
          <div>{!isUploading ? getImageContainerText() : <Spin size={"small"} />}</div>
        )}
      </div>
    );
  };

  const handleCancel = () => {
    setPreviewVisible(false);
    return false;
  };
  const handleRemove = (file) => {
    setImageList(
      imageList.filter((item) =>
        item.response && Array.isArray(item.response)
          ? item.response[0].guid !== file.response[0].guid
          : true
      )
    );
  };

  const handleOnChange = ({file, fileList}) => {
    setUploading(file.status === "uploading");
    if (file.status === "uploading") {
      props.setIsUploadingImage(true);
      setImageList(fileList);
    }
    if (file.status === "done" || file.status === "error") {
      props.setIsUploadingImage(false);
    }
  };

  return (
    <ContentWrapper>
      <div className={styles.root2}>
        <div className={styles.textContainer}>Serial Number*</div>
        <div className={styles.serialNumberContainer}>
          <Input
            placeholder="Enter Serial Number"
            className={`${styles.inputContainer}`}
            disabled={props.second.get("Serial_Number")}
            value={props.second.get("Serial_Number")}
            onChange={(e) => {
              setConfirmButtonDisabled(e.target.value.length === 0);
              setSerialNumber(e.target.value);
              if (e.target.value !== "") setConfirmDeviceError("");
            }}
          />

          <Button
            type="link"
            style={{color: "var(--support-color-text)", padding: 0}}
            onClick={() => {
              setOpen(true);
            }}
          >
            How to find your product's serial number &gt;
          </Button>
        </div>
        <Button
          disabled={confirmButtonDisabled}
          className={
            props.second.get("Device")
              ? styles.buttonContainerConfirmed
              : styles.buttonContainer
          }
          onClick={() => {
            if (serialNumber) {
              setLoading(true);
              ConfirmMutitation.mutate(serialNumber);
              setConfirmDeviceError("");
            } else {
              setConfirmDeviceError("You should enter serial number first");
            }
          }}
        >
          {loading
            ? "Confirming..."
            : props.second.get("Device")
            ? "Confirmed"
            : "Confirm"}
        </Button>
        <p className="invalid">
          {props.errors.Serial_Number ? props.errors.Serial_Number : confirmDeviceError}
        </p>
        <div className={styles.textContainer}>Device</div>
        <input
          className={styles.disabledInputContainer}
          value={props.second.get("Device")}
          disabled={true}
        />{" "}
        <div className={styles.rowContainer}>
          <div>
            <div className={styles.textContainer}>Date of purchase</div>
            <input
              className={styles.disabledInputContainer}
              value={props.second.get("Date_Of_Purchase")}
              disabled={true}
            />
          </div>
          <div>
            <div className={styles.textContainer}>Warranty End Date</div>
            <input
              className={styles.disabledInputContainer}
              value={props.second.get("Warranty_End_Date")}
              disabled={true}
            />
          </div>
        </div>
        <div id={imageList.length > 0 ? "" : "second"}>
          <div className={styles.textContainer}>Upload Images</div>
          <span style={{color: "gray", marginBottom: "5px", display: "inline-block"}}>
            Make sure that the images show clearly the damaged part(s)
          </span>
          <Upload
            className={`${styles.antUpload} ${styles["ant-upload.ant-upload-select-picture-card"]} `}
            action={`${process.env.REACT_APP_BASE_API_URL}/media`}
            onChange={handleOnChange}
            listType="picture-card"
            beforeUpload={beforeImageUpload}
            onPreview={handlePreview}
            fileList={imageList}
            multiple={false} //we don't want the user to be able to select multiple files at once
            onRemove={handleRemove}
          >
            {uploadButton()}
          </Upload>
          <Modal
            footer={null}
            visible={previewVisible}
            title={previewTitle}
            onCancel={handleCancel}
          >
            <img alt="example" style={{width: "100%"}} src={previewImage} />
          </Modal>
        </div>
        <div id="max3" />
      </div>
      <Modal
        closable={false}
        footer={
          <Button
            style={{
              backgroundColor: "var(--support-color-text)",
              borderColor: "var(--support-color-text)",
              color: "var(--bg-color-support-comp)",
            }}
            key="back"
            onClick={() => {
              setOpen(false);
            }}
          >
            Got it!
          </Button>
        }
        visible={open}
      >
        <>
          <Row style={{margin: "20px 0px"}}>
            <Col span={2}>
              <InfoCircleOutlined
                style={{fontSize: "20px", color: "var(--support-color-text)"}}
              />
            </Col>
            <Col span={22}>
              <span style={{color: "#8b8a8a", fontSize: "16px"}}>
                All Onsor laptops' serial numbers are located on the back of the device.
              </span>
            </Col>
          </Row>
          <img
            src={serialPlace}
            style={{width: "100%"}}
            alt="Where to find serial number on onsor laptops"
          />
        </>
      </Modal>
    </ContentWrapper>
  );
};
export default Second;
