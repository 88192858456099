import "./App.css";
import {Route, Switch} from "react-router-dom";
import Login from "./views/login";
import Reset from "./views/reset-password/reset";
import Register from "./views/registerProvider";
import Home from "./views/home";
import Support from "./views/support/Support";
import CustomerTicket from "./views/customerTicket/CustomerTicket";
import ProtectedRoute from "./protectedRoutes";
import CheckEmail from "./views/reset-password/check-email";
import Verification from "./views/reset-password/verification";

function App() {
  return (
    <Switch>
      <Route path="/reset-password" exact component={Reset} />
      <Route path="/reset-password/verification" exact component={Verification} />
      <Route path="/reset-password/check-email" exact component={CheckEmail} />
      <Route path="/login" exact component={Login} />
      <Route path="/register" exact component={Register} />
      <Route path="/support" exact component={Support} />
      <Route path="/support/:id" exact component={CustomerTicket} />
      <ProtectedRoute path="/" component={Home} />
    </Switch>
  );
}

export default App;
