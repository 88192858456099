import DashboardTable from "./dashboardTable/DashboardTable";
import {useQuery} from "react-query";
import {getMostReplacedParts} from "../../services/dashboard";
import DashboardComponent from "./DashboardComponent";
import columns from "./models/top-replaced-parts";
import React from "react";

const TopReplacedParts = (props) => {
  const {
    isFetching: isLoadingServiceProviderTechs,
    data: topReplacedPartsData,
  } = useQuery("getMostReplacedParts", getMostReplacedParts, {
    refetchOnWindowFocus: false,
  });
  return (
    <DashboardComponent
      style={props.style}
      title={`What are the most replaced types of spare parts?`}
    >
      <DashboardTable
        data={topReplacedPartsData?.data}
        columns={columns}
        isLoading={isLoadingServiceProviderTechs}
      />
    </DashboardComponent>
  );
};

export default TopReplacedParts;
