import React from "react";
import styles from "./BlackHeader.module.css";
import onsorBlueIcon from "../../assets/images/FullLogo_blue.png";

const BlackHeader = () => {
  return (
    <div className={styles.root}>
      <img
        className={styles.logo}
        src={onsorBlueIcon}
        onClick={() => {
          window.open(`${process.env.REACT_APP_ONSOR_WP}/support`, "_self");
        }}
        alt="icon"
      />
      <div className={styles.text}>SUPPORT</div>
    </div>
  );
};
export default BlackHeader;
