const columns = [
  {
    title: "Rank",
    dataIndex: "rank",
  },
  {
    title: "Category",
    dataIndex: "name",
  },
  {
    title: "# of Replacements ",
    dataIndex: "count",
  },
];
export default columns;
