import api from "../api";

export const getProvidersList = async () => {
  return await api.get("/users/owners");
};

export const getProviderById = async (id) => {
  id = id.queryKey[0];
  return await api.get(`/users/owners/${id}`);
};

export const getProviderAccount = async () => {
  return await api.get(`/users/owners/my-profile`);
};

export const editProviderAccount = async (data) => {
  let body = {};
  if (data.time) {
    body = {operation_time: data.time};
  } else {
    console.log(data);
    let operationTime = data.operationTime.map((time) => {
      if (time.is_closed) {
        return {
          day: time.day,
          is_closed: true,
        };
      } else {
        return {
          day: time.day,
          start: time.start_str,
          end: time.end_str,
        };
      }
    });
    let logo = "";
    if (data.imageList && data?.imageList[0]?.guid) {
      logo = data?.imageList[0]?.guid;
    }

    body = {
      shop_logo: logo,
      shop_name: data.companyName,
      governorate_id: data.governorate,
      province_id: data.province,
      address: data.address,
      cr_number: data.CRNumber,
      cr_pdf: data.file ? data.file[0]?.guid : null,
      operation_time: operationTime,
      map_lat: data.map[0].toString(),
      map_long: data.map[1].toString(),
    };
  }
  return await api.put(`/auth/accounts/owners/`, body);
};
