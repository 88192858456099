const resolve = (record) => {
  if (record?.is_approved === 1) {
    return record.ticket_status.key;
  }
  if (record?.is_approved === 0) {
    return "pending-approval";
  }
  if (record?.is_approved === 2) {
    return "rejected";
  }
  return null;
};

export default resolve;
