import {Divider, Image, Row, message, Card, Upload, Modal, Button, Progress} from "antd";
import {useState, useEffect} from "react";
import {BaseURL} from "../../../services/config";
import {editImages} from "../../../services/tickets";
import api from "../../../api";
import {PlusOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {useMutation} from "react-query";

const {confirm} = Modal;

export default function ImagesTab(props) {
  const formData = props.data;
  const imageList = formData?.issue_uploaded_images;
  const [newImageList, setNewImgList] = useState([]);
  const [isView, setisView] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Save changes");
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setLoadingText(loading ? "Saving changes..." : "Save changes");
  }, [loading]);

  //img upload functions
  const updateImgMutation = useMutation(editImages, {
    onSuccess: (data, variables, context) => {
      if (data.status >= 200 && data.status < 300) {
        setLoading(false);
        message.success("Images updated successfully");
        props.refetch();
        setisView(true);
      } else {
        message.error(data.statusText);
      }
    },
    onError: (error, variables, context) => {
      setLoading(false);
      message.error(error.response.data.error.message);
    },
  });
  useEffect(() => {
    let fileList =
      imageList &&
      imageList.map((img) => {
        return {
          fileName: img,
          guid: img,
          url: `${BaseURL}/media/${img}`,
        };
      });
    setNewImgList(fileList);
  }, [imageList]);

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error("Image must smaller than 1MB!");
    }
    return isJpgOrPng && isLt1M;
  }
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{marginTop: 8}}>Upload</div>
      {progress > 0 ? <Progress percent={progress} /> : null}
    </div>
  );
  const uploadImage = async (options) => {
    const {onSuccess, onError, file, onProgress} = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({percent: (event.loaded / event.total) * 100});
      },
    };
    fmData.append("file", file);
    try {
      const res = await api.post("/media", fmData, config);
      onSuccess("Ok");
      let temp = [...newImageList];
      temp.push(res.data[0]);
      setNewImgList(temp);
    } catch (err) {
      onError({err});
    }
  };
  const handleOnChange = ({file, fileList, event}) => {
    if (
      !(
        file.type === "image/jpeg" ||
        (file.type === "image/png" && file.size / 1024 / 1024 > 1)
      )
    ) {
      fileList.splice(fileList.length - 1, 1);
    }
  };

  return (
    <>
      {localStorage.getItem("role") === "owner" && formData ? (
        <div>
          <Button
            onClick={() => {
              !isView &&
                confirm({
                  icon: <ExclamationCircleOutlined />,
                  title: "Are you sure you want to cancel?",
                  content: (
                    <span>You will lose all the changes you’ve made on this page.</span>
                  ),
                  onOk() {
                    //reset all feild to original
                    setisView(!isView);
                  },
                  onCancel() {},
                });
              isView && setisView(!isView);
            }}
            type={isView ? "primary" : ""}
          >
            {isView ? "Edit" : "Cancel"}
          </Button>
          {!isView ? (
            <Button
              disabled={loading}
              type="primary"
              onClick={() => {
                confirm({
                  icon: <ExclamationCircleOutlined />,
                  title: "Are you sure you want to save changes?",
                  content: <span>Once saved, the info will update.</span>,
                  async onOk() {
                    //call save issue tab API
                    setLoading(true);
                    await updateImgMutation.mutate({
                      id: formData.id,
                      customerImg: formData.customer_uploaded_images,
                      adminImg: newImageList,
                    });
                  },
                  onCancel() {},
                });
              }}
              style={{float: "right"}}
            >
              {loadingText}
            </Button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <br />
      <Card className="site-layout-background" style={{padding: 0, minHeight: "77vh"}}>
        <Divider orientation="left">Images by customer</Divider>
        <Row style={{minHeight: 100}}>
          {formData?.customer_uploaded_images.map((img) => {
            return (
              <div style={{margin: "5px", border: "1px solid gray", padding: "2px"}}>
                <Image width={100} src={`${BaseURL}/media/${img}`} />
              </div>
            );
          })}
        </Row>
        <br />
        <Divider orientation="left">Images by Service Provider</Divider>
        <Row>
          {isView ? (
            imageList &&
            imageList.map((img) => {
              return (
                <div style={{margin: "5px", border: "1px solid gray", padding: "2px"}}>
                  <Image width={100} src={`${BaseURL}/media/${img}`} />
                </div>
              );
            })
          ) : (
            <Upload
              customRequest={uploadImage}
              onChange={handleOnChange}
              listType="picture-card"
              beforeUpload={beforeUpload}
              fileList={newImageList}
              multiple={true}
              onRemove={(file) => {
                let fileIndex = -1;
                let temp = [...newImageList];
                newImageList.forEach((element, index) => {
                  if (file.guid === element.guid) {
                    fileIndex = index;
                  }
                });
                if (fileIndex >= 0) {
                  temp.splice(fileIndex, 1);
                  setNewImgList(temp);
                }
              }}
            >
              {uploadButton}
            </Upload>
          )}
        </Row>
      </Card>
    </>
  );
}
