import React from "react";
import {useHistory} from "react-router-dom";

import {Table, Tag} from "antd";
import "antd/dist/antd.css";
import styles from "../serviceProvider/ServiceProvider.module.css";
import searchNow from "../../utils/search-helper";
const columns = [
  {
    title: "Service Provider",
    dataIndex: "shop_name",
    searchable: true,
    sorter: (record, nextRecord) => record.shop_name > nextRecord.shop_name,
  },
  {
    title: "Location",
    dataIndex: "governorate",
    sorter: (record, nextRecord) => record.governorate > nextRecord.governorate,
  },
  {
    title: "Email",
    dataIndex: "email",
    searchable: true,
    sorter: (record, nextRecord) => record.email > nextRecord.email,
  },
  {
    title: "Phone",
    dataIndex: "phone_number",
    searchable: true,
    sorter: (record, nextRecord) => record.phone_number > nextRecord.phone_number,
  },
  {
    title: "Status",
    dataIndex: "status",
    filters: [
      {text: "Active", value: "active"},
      {text: "Inactive", value: "inactive"},
      {text: "Rejected", value: "rejected"},
      {text: "Pending Approval", value: "pending_approval"},
    ],
    onFilter: (value, record) => record.status.includes(value),
    sorter: (record, nextRecord) => record.status > nextRecord.status,
    render: (key) => {
      return <Tag className={key}>{key}</Tag>;
    },
  },
];

export default function ServiceProviderTable({filtration, data, isLoading = false}) {
  const {status, location, search} = filtration;
  const history = useHistory();
  let filteredData = data
    ?.filter((record) => {
      if (status === "All") return true;
      return record.status === status;
    })
    .filter((record) => {
      if (location === "All") {
        return true;
      }
      return record.governorate_id === location;
    });

  filteredData = searchNow(filteredData, search, columns);

  return (
    <Table
      onRow={(record) => {
        return {
          onClick: () => {
            history.push(`providers/${record.id}`);
          },
        };
      }}
      rowClassName={styles.serviceProviderRow}
      columns={columns}
      dataSource={filteredData}
      pagination={{
        pageSizeOptions: ["10", "15", "20"],
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSize: history.location?.state?.currentSize
          ? history.location.state.currentSize
          : 10,
        onShowSizeChange: (page, size) => {
          let state = {...history.location.state};
          state.currentSize = size;
          history.replace({...history.location, state});
        },
        current: history.location?.state?.currentPage
          ? history.location.state.currentPage
          : 1,
        onChange: (currentPage) => {
          let state = {...history.location.state};
          state.currentPage = currentPage;
          history.replace({...history.location, state});
        },
      }}
      loading={isLoading}
    />
  );
}
