import api from "../api";

export const loginCall = async (data) => {
  return await api.post(
    `/auth/sign-in`,
    {
      email: data.email,
      password: data.password,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const signUpServiceProvider = async (data) => {
  return await api.post(
    `/auth/sign-up`,
    {
      email: data.email,
      password: data.pass,
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phone,
      phone_area_code: "968",
      shop_logo: data.imageList[0]?.guid,
      shop_name: data.companyName,
      governorate_id: data.governorate,
      province_id: data.province,
      address: data.address,
      operation_time: data.time,
      cr_number: data.CRNumber,
      cr_pdf: data.file[0]?.guid,
      map_lat: data.map[0].toString(),
      map_long: data.map[1].toString(),
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
