import {Card, Input, Col, message, Layout, Select, Row, Button} from "antd";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import Table from "../../components/tables/technician";
import {useQuery} from "react-query";
import {getTechnicianList} from "../../services/technician";
import {isOwner} from "../../utils/check-role";

export default function TechniciansListPage() {
  let history = useHistory();
  const {
    isLoading: isLoadingTechnicians,
    error: errorLoadingTechnicians,
    data: technicians,
  } = useQuery("technicianList", getTechnicianList);
  const [filter, setFilter] = useState({
    search: "",
    status: "all",
    location: "all",
  });

  const handleFilterStatus = (value) => {
    setFilter({...filter, status: value});
  };

  const onSearch = (value) => {
    setFilter({...filter, search: value.trim()});
  };

  if (errorLoadingTechnicians) {
    message.error("Failed to load technicians.");
  }
  if (!isOwner) {
    return history.push("../login");
  }
  return (
    <Layout>
      <Card bordered={false}>
        <span className="title">Technicians</span>
      </Card>
      <br />
      <Card bordered={false}>
        <Row>
          <span className="title filter">Filter:</span>
          <Col className="filter" xs={24} sm={24} md={8} lg={8}>
            Status:{" "}
            <Select
              value={filter.status}
              placeholder="select"
              style={{width: 200}}
              onChange={handleFilterStatus}
            >
              <Select.Option value="all">All</Select.Option>
              <Select.Option value={true}>Active</Select.Option>
              <Select.Option value={false}>Inactive</Select.Option>
            </Select>
          </Col>
        </Row>
      </Card>
      <br />
      <Card bordered={false}>
        <Row>
          <Col xs={24} sm={10} md={8} lg={8} xl={12}>
            <Input.Search
              placeholder="input search text"
              onSearch={onSearch}
              enterButton
              className="search-input"
              allowClear="true"
            />
          </Col>
          <Col style={{textAlign: "right"}} xs={24} sm={10} md={8} lg={8} xl={12}>
            <Button type="primary" onClick={() => history.push("/technicians/new")}>
              + Create Technician
            </Button>
          </Col>
        </Row>
        <br />
        <Table
          filtration={filter}
          data={technicians?.data}
          isLoading={isLoadingTechnicians}
        />
      </Card>
    </Layout>
  );
}
