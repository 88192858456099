/******** THIS FILE IS RESPONSIBLE FOR AUTOMATICALLY GENERATING THE NAVIGATION TABS ************/
/******** YOU SHOULDN'T MODIFY THIS FILE UNLESS NECESSARY ************/

import React from "react";
import {isAdmin, isOwner, isTech, isClient} from "../utils/check-role";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import HashMap from "hashmap";
import {TABS} from "./tabs";

const groupTabs = () => {
  const hashMap = new HashMap();
  TABS.forEach((route) => {
    if (!isAllowed(route.permission)) {
      return;
    }
    const key = route.group ? route.group : "-";
    const routeGroup = hashMap.get(key);
    if (routeGroup) {
      routeGroup.push(route);
    } else {
      hashMap.set(key, [route]);
    }
  });
  return hashMap;
};

const wrapInGroup = (key, routes) => {
  return (
    <Menu.ItemGroup key={key} title={key}>
      {routes.map((item) => {
        return buildSingleMenuItem(item);
      })}
    </Menu.ItemGroup>
  );
};

const isAllowed = (permission) => {
  const currentRole = isAdmin() ? 1 : isOwner() ? 2 : isTech() ? 4 : isClient() ? 8 : -1;
  return (currentRole | permission) <= permission;
};

const buildCorrespondingTabs = (groupedTabs, key) => {
  if (key === "-") {
    return groupedTabs.get(key)?.map((route) => {
      return buildSingleMenuItem(route);
    });
  } else {
    return wrapInGroup(key, groupedTabs.get(key));
  }
};

export const buildTabs = () => {
  const groupedTabs = groupTabs();
  const NAV_MENU = [];
  groupedTabs.keys().forEach((key) => {
    const tabs = buildCorrespondingTabs(groupedTabs, key);
    NAV_MENU.push(tabs);
  });
  return NAV_MENU;
};

const buildSingleMenuItem = (route) => {
  return (
    <Menu.Item key={route.key} icon={route.icon}>
      <Link to={route.path}>{route.title}</Link>
    </Menu.Item>
  );
};
