import React, {useEffect, useState} from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../Marker/Marker";
import styles from "./Map.module.css";
import SearchInput from "./SearchMap";

const Map = (props) => {
  const MUSCAT_COORDINATES = {
    lat: 23.588,
    lng: 58.3829,
  };
  const [zoom, setZoom] = useState(10);
  const [maps, setMaps] = useState({});
  const [searchMarker, setSearchMarker] = useState(
    props.selected ? {lat: props.selected[0], lng: props.selected[1]} : MUSCAT_COORDINATES
  );

  useEffect(() => {
    if (props.geocode) {
      setSearchMarker(props.geocode);
      setZoom(13);
    }
  }, [props.geocode]);

  useEffect(() => {
    if (
      props.isViewOnly &&
      props.defaultPin &&
      props.defaultPin.lat &&
      props.defaultPin.lng
    ) {
      setSearchMarker(props.defaultPin);
    }
  }, [props.isViewOnly]);

  const handleApiLoaded = (map, maps) => {
    setMaps(maps);
  };

  const handlePlacesChanged = (placeResult) => {
    // maybe just console.log the placeResult for now
    if (placeResult && placeResult.geometry) {
      const placeLat = placeResult.geometry.location.lat();
      const placeLng = placeResult.geometry.location.lng();
      setSearchMarker({lat: placeLat, lng: placeLng});
      props.onChange(placeLat, placeLng);
    } else {
      setSearchMarker(MUSCAT_COORDINATES);
      props.onChange(MUSCAT_COORDINATES.lat, MUSCAT_COORDINATES.lng);
    }
    setZoom(12);
  };

  return (
    <div className={props.markersList ? styles.mapContainer : styles.mapContainerOTSP}>
      {!props.isViewOnly && (
        <SearchInput
          isOTSP={!props.markersList}
          onPlacesChanged={handlePlacesChanged}
          maps={maps}
        />
      )}
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{
          key: process.env.REACT_APP_MAP_API_KEY,
          libraries: ["places", "geometry"],
        }}
        zoom={zoom}
        panControl={false}
        defaultCenter={searchMarker}
        center={searchMarker}
        fullscreenControl="false"
        onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
        onClick={
          props.markersList || props.isViewOnly
            ? ""
            : (evt) => {
                setSearchMarker({
                  lat: evt.lat,
                  lng: evt.lng,
                });
                props.onChange(evt.lat, evt.lng);
              }
        }
      >
        {props.markersList?.map((serviceProvider, index) => {
          return (
            <Marker
              onClick={() => {
                props.setExpanded(index);
                document.getElementById(index).scrollIntoView();
              }}
              lat={+serviceProvider.latitude}
              lng={+serviceProvider.longitude}
              name={serviceProvider.shop_name}
            />
          );
        })}
        {!props.markersList && props.selected && (
          <Marker
            lat={+searchMarker.lat}
            lng={+searchMarker.lng}
            name="Selected Location"
          />
        )}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
