import React, {useState, useEffect} from "react";
import {Card, Row, Col, Menu} from "antd";
import DashboardComponent from "./DashboardComponent";
import Chart from "react-apexcharts";
import {useQuery} from "react-query";
import {
  getTotalCostPerMonth,
  getTotalCostPerWeek,
  getTotalCostPerYear,
} from "../../services/dashboard";
import styles from "./TotalCostOfSpareParts.module.css";
import LoadingComponent from "../LoadingComponent";

const CostGraph = (props) => {
  const {isFetching: isLoadingTotalCostPerWeek, data: totalCostPerWeek} = useQuery(
    "getTotalCostPerWeek",
    getTotalCostPerWeek,
    {
      refetchOnWindowFocus: false,
    }
  );
  const {isFetching: isLoadingTotalCostPerMonth, data: totalCostPerMonth} = useQuery(
    "getTotalCostPerMonth",
    getTotalCostPerMonth,
    {
      refetchOnWindowFocus: false,
    }
  );
  const {isFetching: isLoadingTotalCostPerYear, data: totalCostPerYear} = useQuery(
    "getTotalCostPerYear",
    getTotalCostPerYear,
    {
      refetchOnWindowFocus: false,
    }
  );

  const [currentData, setCurrentData] = useState();
  const [defaultKey, setDefaultKey] = useState(["week"]);

  const load = () => {
    switch (defaultKey[0]) {
      case "week":
        setCurrentData(totalCostPerWeek?.data);
        break;
      case "month":
        setCurrentData(totalCostPerMonth?.data);
        break;
      case "year":
      default:
        setCurrentData(totalCostPerYear?.data);
        break;
    }
  };
  useEffect(() => {
    load();
  }, [defaultKey]);

  useEffect(() => {
    load();
  });

  const isLoading = () =>
    isLoadingTotalCostPerMonth || isLoadingTotalCostPerWeek || isLoadingTotalCostPerYear;

  const handleClick = ({key}) => {
    setDefaultKey([key]);
  };

  const series = [
    {
      name: "Total Cost",
      data: currentData?.data,
    },
  ];
  const options = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      seriesName: "Total Cost",
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    xaxis: {
      axisTicks: {
        show: true,
        offsetX: 20,
      },
      tickPlacement: "between",
    },
    grid: {
      show: true,
      position: "front",
    },
    fill: {
      type: "gradient",
      gradient: {
        colorStops: [
          {
            color: "rgba(0,0,0,0.5)",
            opacity: 0,
          },
        ],
      },
    },
  };

  const TotalEarning = (props) => {
    return (
      <Card
        style={{
          marginTop: 24,
          marginBottom: 16,
          padding: 0,
          height: "128px",
        }}
        bordered={true}
      >
        <div className={styles.cardInner}>
          <p className={styles.cardTitle}>{props.title}</p>
        </div>
        <div className={styles.cardInner}>
          <p className={styles.value}>{props.value}</p>
        </div>
      </Card>
    );
  };

  return (
    <DashboardComponent
      noOverflow={true}
      style={props.style}
      title={`What is the total cost of spare parts?`}
      id="total-cost-card"
    >
      {!isLoading() ? (
        <>
          <Row className={styles.header}>
            <Col span={6}>
              <Menu
                mode="horizontal"
                defaultSelectedKeys={["1"]}
                style={{
                  borderBottom: "none",
                }}
              >
                <Menu.Item key="1">Total Cost</Menu.Item>
              </Menu>
            </Col>
            <Col span={18}>
              <Menu
                mode="horizontal"
                defaultSelectedKeys={defaultKey}
                style={{
                  textAlign: "end",
                  borderBottom: "none",
                }}
                onClick={handleClick}
              >
                <Menu.Item
                  key="week"
                  style={{
                    borderBottom: "none",
                  }}
                >
                  Last 7 days
                </Menu.Item>
                <Menu.Item
                  key="month"
                  style={{
                    borderBottom: "none",
                  }}
                >
                  Last 30 days
                </Menu.Item>
                <Menu.Item
                  key="year"
                  style={{
                    borderBottom: "none",
                  }}
                >
                  Last 12 months
                </Menu.Item>
              </Menu>
            </Col>
          </Row>
          <Row>
            <Col className="gutter-row" span={15} xs={24} sm={24} md={24} lg={18}>
              <Chart
                options={options}
                series={series}
                type="area"
                style={{
                  marginBottom: 0,
                }}
                height="456px"
                width="100%"
              />
            </Col>
            <Col
              span={9}
              xs={24}
              sm={24}
              md={24}
              lg={6}
              style={{display: "grid", alignItems: "center"}}
            >
              <TotalEarning title={"Total Earnings"} value={currentData?.total} />
            </Col>
          </Row>
        </>
      ) : (
        <LoadingComponent />
      )}
    </DashboardComponent>
  );
};

export default CostGraph;
