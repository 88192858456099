import api from "../api";

export const resetPassword = async (payload) => {
  return await api.post(`/reset-password`, {
    email: payload.email,
  });
};
export const verifyCode = async (payload) => {
  return await api.post(`/reset-password/verification?code=${payload.code}`, {
    password: payload.password,
  });
};
export const checkVerifyCode = async (payload) => {
  return await api.get(`/reset-password/verification?code=${payload.code}`);
};
