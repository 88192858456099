import React from "react";
import ContentWrapper from "../../contentWrapper/ContentWrapper";
import styles from "./First.module.css";
import {getIssueTypes} from "../../../services/static";
import {useQuery} from "react-query";
import {Select, Input} from "antd";
import "./First.css";

const First = (props) => {
  const {error, isLoading, data} = useQuery("getIssueTypes", getIssueTypes);

  function searchIssueType(ids) {
    if (data?.data) {
      let issuesList = [];
      for (let i = 0; i < data.data.length; i++) {
        for (let j = 0; j < ids.length; j++) {
          if (data.data[i].id === parseInt(ids[j])) {
            issuesList.push({label: data.data[i].value, value: data.data[i].id});
          }
        }
      }
      return issuesList;
    }
    return "";
  }

  const addAnswer = (event, index) => {
    if (index === 1) {
      let newMap = new Map(props.first);
      const key = "Type_of_issue";
      newMap.set(key, searchIssueType(event));
      props.setFirst(newMap);
    } else if (index === 2) {
      let newMap = new Map(props.first);
      const key = "Device_issue";
      newMap.set(key, event.target.value);
      props.setFirst(newMap);
    }
  };

  const {TextArea} = Input;

  if (isLoading) {
    return "Loading...";
  }

  if (error) {
    return "Error..please refresh page.";
  }

  return (
    <ContentWrapper>
      <div id="summary-root" className={styles.root1}>
        <div className={styles.textContainer}>Type of issue*</div>
        <Select
          mode="multiple"
          placeholder="Please select issue"
          defaultValue={props.first.get("Type_of_issue")?.map((issue) => {
            return issue.value;
          })}
          onChange={(event) => {
            addAnswer(event, 1);
          }}
          style={{
            marginBottom: "4px",
          }}
          showArrow={true}
          showSearch={false}
          dropdownStyle={{
            backgroundColor: "#1E1E1E",
          }}
        >
          {data.data.map((item) => {
            return (
              <option id="issue_type-option" value={item.id}>
                {item.value}
              </option>
            );
          })}
        </Select>
        <p className="invalid">{props.errors.Type_of_issue}</p>
        <div className={styles.textContainer}>Device issues*</div>
        <TextArea
          className={`${styles.textContainer} ${styles.textAreaContainer}`}
          defaultValue={props.first.get("Device_issue")}
          onBlur={(event) => {
            addAnswer(event, 2);
          }}
          showCount
          maxLength={1000}
          id="device-issues_textarea"
        />
        <p className="invalid">{props.errors.Device_issue}</p>
      </div>
    </ContentWrapper>
  );
};
export default First;
