import {message} from "antd";
import {toAndable} from "./utils";

const ONE_MB = 1048576;
const MAX_FILE_SIZE = 3 * 1048576;
const MAX_IMAGE_SIZE = ONE_MB; //1 MB
const convertToReadableMb = (size) => size / ONE_MB + "MB";
export const MAX_IMAGE_SIZE_STR = convertToReadableMb(MAX_IMAGE_SIZE);
const MAX_FILE_SIZE_STR = convertToReadableMb(MAX_FILE_SIZE);
export const SUPPORTED_MIME_TYPES_IMAGE_UPLOAD = ["image/jpeg", "image/png", "image/jpg"];
export const SUPPORTED_MIME_TYPES_FILE_UPLOAD = ["application/pdf"];
export const getSupportedImages = () => getSupported(SUPPORTED_MIME_TYPES_IMAGE_UPLOAD);
const getSupportedFiles = () => getSupported(SUPPORTED_MIME_TYPES_FILE_UPLOAD);
const getSupported = (supportedMimeList) =>
  toAndable(supportedMimeList.map((item) => item.split("/")[1]));

export const getImageTooltip = (n) => getTooltip(true, n);
export const getFileTooltip = () => getTooltip(false);

const getTooltip = (isImage, n = 1) => {
  return `You can upload ${!isImage ? "one file" : `up to ${n} image`}${
    n > 1 ? "s" : ""
  } of type: ${isImage ? getSupportedImages() : getSupportedFiles()}. Max size of each ${
    isImage ? "image" : "file"
  }: ${isImage ? MAX_IMAGE_SIZE_STR : MAX_FILE_SIZE_STR}`;
};
export const onPreview = async (file) => {
  let src = file.url;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  }
  return src;
};
export const isImageSizeValid = (file) => {
  return file.size <= MAX_IMAGE_SIZE;
};
export const isFileSizeValid = (file) => {
  return file.size <= MAX_FILE_SIZE;
};
export const beforeImageUpload = (file) => {
  const isSupported = SUPPORTED_MIME_TYPES_IMAGE_UPLOAD.includes(file.type);
  if (!isSupported) {
    message.error(
      `Unsupported image format. Supported types are: ${SUPPORTED_MIME_TYPES_IMAGE_UPLOAD.map(
        (each) => each.substr(each.indexOf("image/") + "image/".length)
      ).join(", ")}`
    );
    return false;
  }
  if (!isImageSizeValid(file)) {
    message.error("Image must not exceed 1 MB");
    return false;
  }
  return true;
};
export const beforeFileUpload = (file) => {
  const isSupported = SUPPORTED_MIME_TYPES_FILE_UPLOAD.includes(file.type);
  if (!isSupported) {
    message.error(
      `Unsupported file format. Supported types are: ${SUPPORTED_MIME_TYPES_FILE_UPLOAD.map(
        (each) => each.substr(each.indexOf("/") + "/".length)
      ).join(", ")}`
    );
    return false;
  }
  if (!isFileSizeValid(file)) {
    message.error("File must not exceed 3 MB");
    return false;
  }
  return true;
};
