import {Switch, Redirect} from "react-router-dom";
import Dashboard from "../views/dashboard/dashboard";
import SRPage from "../views/serviceRequest/serviceRequestList";
import SRdetails from "../views/serviceRequest/serviceRequestDetails";
import NewSR from "../views/serviceRequest/newServiceRequest";
import Inventory from "../views/inventory/inventory";
import ProtectedRoute from "../protectedRoutes";
import TechnicianDetails from "../views/technicians/technicianDetails";
import TechniciansList from "../views/technicians/techniciansList";
import NewTechnician from "../views/technicians/newTechnician";
import InventoryDetails from "../views/inventory/inventoryDetails";
import Account from "../views/account";
import ChangePassword from "../views/changePassword";

export default function AdminRoutes() {
  return (
    <Switch>
      <ProtectedRoute path="/dashboard" component={Dashboard} />
      <ProtectedRoute path="/technicians" exact component={TechniciansList} />
      <ProtectedRoute path="/technicians/new" exact component={NewTechnician} />
      <ProtectedRoute path="/technicians/:id" exact component={TechnicianDetails} />
      <ProtectedRoute path="/service-requests" exact component={SRPage} />
      <ProtectedRoute path="/service-requests/new" exact component={NewSR} />
      <ProtectedRoute
        path="/service-requests/:serviceRequestNumber"
        component={SRdetails}
      />
      <ProtectedRoute path="/inventory" exact component={Inventory} />
      <ProtectedRoute path="/inventory/:id" exact component={InventoryDetails} />
      <ProtectedRoute path="/inventory" component={Inventory} />
      <ProtectedRoute path="/account" exact component={Account} />
      <ProtectedRoute path="/account/change-password" exact component={ChangePassword} />
      <Redirect from="/" to="dashboard" />
    </Switch>
  );
}
