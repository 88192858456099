/***
 *
 * @param data the list you want to filter
 * @param search the keyword
 * @param columns optional - table's columns containing searchable fields
 * @param searchableFields optional - If columns is not set, then this is taken into consideration
 * @returns list of filtered data
 */
const search = (data, search, columns, searchableFields = []) => {
  if (columns) {
    searchableFields = columns
      .filter((item) => item.searchable)
      .map((data) => data.dataIndex);
  }
  return data?.filter((item) => {
    const keyword = search.trim().toLowerCase();
    const splits = keyword.split(" ");
    let result = true;
    for (const split of splits) {
      let innerResult = false;
      for (let i = 0; i < searchableFields.length; i++) {
        if (item[searchableFields[i]]?.toString().toLowerCase().includes(split)) {
          innerResult = true;
        }
      }
      result = result && innerResult;
    }
    if (keyword === "") {
      return true;
    }
    return result;
  });
};
export default search;
