import {Row, Col, Image} from "antd";
import React from "react";
import styles from "./Summary.module.css";

const Summary = (props) => {
  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <h3 className={styles.divTitle}>
          What’s happening? <span onClick={() => props.change(0)}>Change</span>
        </h3>
        <div className={styles.textContainer}>
          <Row>
            <Col className={styles.leftCol}>
              Type of Issue:
              <br />
              Device Issues:
            </Col>
            <Col className={styles.rightCol}>
              {props.answers
                .get("Type_of_issue")
                .map((issue) => {
                  return issue.label;
                })
                .join(", ")}
              <br />
              {props.answers.get("Device_issue")}
            </Col>
          </Row>
        </div>
      </div>

      <div className={styles.section}>
        <h3 className={styles.divTitle}>
          Your device <span onClick={() => props.change(1)}>Change</span>
        </h3>
        <div className={styles.textContainer}>
          <Row>
            <Col className={styles.leftCol}>
              Serial Number:
              <br />
              Device:
              <br />
              Date of Purchase
              <br />
              Warranty End Date:
              <br />
              Images:
            </Col>
            <Col className={styles.rightCol}>
              {props.answers.get("Serial_Number")}
              <br />
              {props.answers.get("Device")}
              <br />
              {props.answers.get("Date_Of_Purchase")}
              <br />
              {props.answers.get("Warranty_End_Date")}
              <br />
              <br />
              <Row>
                {props.answers.get("Upload_Images") &&
                  props.answers.get("Upload_Images").map((img) => {
                    return img.response && Array.isArray(img.response) ? (
                      <div
                        style={{
                          width: "100px",
                          padding: "8px",
                          border: "1px solid #d9d9d9",
                          borderRadius: "1px",
                          marginRight: 20,
                        }}
                      >
                        <Image src={img.response[0].url} />
                      </div>
                    ) : null;
                  })}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.section}>
        <h3 className={styles.divTitle}>
          Your information <span onClick={() => props.change(2)}>Change</span>
        </h3>
        <div className={styles.textContainer}>
          <Row>
            <Col className={styles.leftCol}>
              First Name:
              <br />
              Last Name:
              <br />
              Phone Number:
              <br />
              Email:
            </Col>
            <Col className={styles.rightCol}>
              {props.answers.get("First_Name")}
              <br />
              {props.answers.get("Last_Name")}
              <br />
              {props.answers.get("Phone_Number")}
              <br />
              {props.answers.get("Owner_Email")}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default Summary;
