import {Card, Row, Col, Button, Upload, Tooltip} from "antd";
import {Content} from "antd/es/layout/layout";
import React, {useEffect, useState} from "react";
import SearchBox from "../components/SearchBox";
import DeviceTable from "../components/DeviceTable";
import {getDevices} from "../services/devices";
import {useQuery} from "react-query";
import {message} from "antd";
import searchNow from "../utils/search-helper";
import {getBearerAccessToken} from "../utils/token";
import exampleCSV from "../assets/example.csv";
import columns from "../components/DeviceColumns";
import VisibilityWrapper from "../VisibilityWrapper";
import {
  shouldShowUploadButton,
  shouldShowUploadingForTheFirstTime,
} from "./permissions/devices";
import {isAdmin} from "../utils/check-role";

const Devices = () => {
  const {isFetching: isLoading, error, data, refetch} = useQuery(
    "getDevices:" + (isAdmin() ? "admin" : "client"),
    getDevices,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const [filteredData, setFilteredData] = useState([]);

  const UploadingFirstTime = () => {
    return (
      <div style={{marginTop: 12}}>
        <span style={{fontWeight: "bold", display: "block"}}>
          Uploading for the first time?{" "}
        </span>
        <a style={{marginLeft: 2, display: "inline-block"}} href={exampleCSV} download>
          Click here to download a sample CSV file
        </a>
      </div>
    );
  };
  const [isUploading, setIsUploading] = useState(false);
  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    action: `${process.env.REACT_APP_BASE_API_URL}/devices`,
    headers: {
      authorization: getBearerAccessToken(),
    },
    onChange(info) {
      setIsUploading(info.file.status === "uploading");
      if (info.file.status === "done") {
        refetch();
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        if (info.file.response && info.file.response.error) {
          message.error(info.file.response.error.message);
        } else {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  useEffect(() => {
    if (!isLoading && !error) {
      setFilteredData(data?.data);
    }
  }, [data?.data]);

  if (error) {
    message.error("Error loading devices.");
  }

  const handleSearch = (value) => {
    setFilteredData(searchNow(data?.data, value, columns()));
  };

  const UploadButton = () => {
    return (
      <VisibilityWrapper isVisible={shouldShowUploadButton}>
        <Upload style={{textAlign: "right"}} {...props}>
          <Tooltip
            title={"Please update and upload the original CSV file to add new entries"}
          >
            <Button type="ghost" disabled={isUploading} loading={isUploading}>
              Upload new CSV
            </Button>
          </Tooltip>
        </Upload>
      </VisibilityWrapper>
    );
  };

  return (
    <Content>
      <Card bordered={false} style={{marginBottom: 12}}>
        <span className="title">Devices</span>
      </Card>
      <Card style={{marginBottom: 12}}>
        <Row>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <SearchBox
              placeholder={
                "Search for serial number, device name, sku, batch, warranty end date"
              }
              onSearch={handleSearch}
            />
            <VisibilityWrapper isVisible={shouldShowUploadingForTheFirstTime}>
              <UploadingFirstTime />
            </VisibilityWrapper>
          </Col>
        </Row>
      </Card>
      <Card>
        <Row style={{marginBottom: 16}}>
          <Col xs={24} sm={10} md={8} lg={8} xl={12}>
            <Button
              type="primary"
              onClick={refetch}
              disabled={isLoading}
              loading={isLoading}
            >
              Reload
            </Button>
          </Col>
          <Col style={{textAlign: "right"}} xs={24} sm={10} md={8} lg={8} xl={12}>
            <UploadButton />
          </Col>
        </Row>
        <DeviceTable isLoading={isLoading} data={filteredData} />
      </Card>
    </Content>
  );
};
export default Devices;
