import {useForm, Controller} from "react-hook-form";
import {Col, Button, Input, Row, Select} from "antd";
import {useHistory} from "react-router-dom";
import "antd/dist/antd.css";
import "./forms.css";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
const {Option} = Select;

const schema = yup.object().shape({
  pass: yup.string().required("Password is required").min(8),
  confirmPass: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("pass"), null], "Passwords must match"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email().required("Email is required"),
  phone: yup
    .string()
    .required("Phone is required")
    .test("is-phone", "Invalid phone number", (value) => (value + "").match(/^\d*$/))
    .length(8),
});
function Step1(props) {
  const formData = props.formData;
  const history = useHistory();
  const {handleSubmit, errors, control} = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    //go to next step and send data
    props.next(data);
  };
  const CountryPhoneCode = (
    <Select disabled={true} className="select-before" value="+968">
      <Option value="+968">+968</Option>
    </Select>
  );
  return (
    <div className="steps-div">
      <form ref={props.ref} onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={24} md={12} style={{margin: "40px 0"}}>
            <div className="row-padding">
              <Controller
                as={
                  <label>
                    First Name*
                    <Input style={{width: "90%"}} defaultValue={formData?.firstName} />
                  </label>
                }
                defaultValue={formData?.firstName}
                name="firstName"
                control={control}
              />

              <p className="invalid">{errors.firstName?.message}</p>
            </div>
            <div className="row-padding">
              <Controller
                as={
                  <label>
                    Last Name*
                    <Input style={{width: "90%"}} defaultValue={formData?.lastName} />
                  </label>
                }
                defaultValue={formData?.lastName}
                name="lastName"
                control={control}
              />
              <p className="invalid">{errors.lastName?.message}</p>
            </div>
            <div className="row-padding">
              <Controller
                as={
                  <label>
                    Company Phone Number*
                    <Input
                      maxLength={8}
                      addonBefore={CountryPhoneCode}
                      style={{width: "90%"}}
                      defaultValue={formData?.phone}
                    />
                  </label>
                }
                defaultValue={formData?.phone}
                name="phone"
                control={control}
              />
              <p className="invalid">{errors.phone?.message}</p>
            </div>
          </Col>
          <Col sm={24} md={12} style={{margin: "40px 0"}}>
            <div className="row-padding">
              <Controller
                as={
                  <label>
                    Company Email*
                    <Input style={{width: "90%"}} defaultValue={formData?.email} />
                  </label>
                }
                defaultValue={formData?.email}
                name="email"
                control={control}
              />
              <p className="invalid">{errors.email?.message}</p>
            </div>
            <div className="row-padding">
              <Controller
                as={
                  <label>
                    Password*
                    <Input.Password
                      style={{width: "90%"}}
                      defaultValue={formData?.pass}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </label>
                }
                defaultValue={formData?.pass}
                name="pass"
                control={control}
              />
              <p className="invalid">{errors.pass?.message}</p>
            </div>
            <div className="row-padding">
              <Controller
                as={
                  <label>
                    Confirm Password*
                    <Input.Password
                      style={{width: "90%"}}
                      defaultValue={formData?.confirmPass}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </label>
                }
                defaultValue={formData?.confirmPass}
                name="confirmPass"
                control={control}
              />
              <p className="invalid">{errors.confirmPass?.message}</p>
            </div>
          </Col>
        </Row>

        <div className="steps-action">
          <Button type="primary" htmlType="submit" style={{float: "right"}}>
            Next
          </Button>
          <Button
            style={{margin: "0 8px"}}
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </Button>
        </div>
      </form>
    </div>
  );
}
export default Step1;
