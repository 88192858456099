import React, {useState} from "react";
import Tickets from "../../components/dashboard/Tickets";
import ServiceProvider from "../../components/dashboard/ServiceProvider";
import {Col, Row, Popover, DatePicker, Divider} from "antd";
import {CalendarOutlined} from "@ant-design/icons";
import TopReplacedParts from "../../components/dashboard/TopReplacedParts";
import CauseOfDefect from "../../components/dashboard/CauseOfDefect";
import CostGraph from "../../components/dashboard/CostGraph";
import LeadGraph from "../../components/dashboard/LeadGraph";
import CloseGraph from "../../components/dashboard/CloseGraph";
import "./dashboard.css";
import {isAdmin} from "../../utils/check-role";
const {RangePicker} = DatePicker;
export default function Dashboard() {
  const [dates, setDates] = useState();
  return (
    <>
      {isAdmin() && (
        <Row
          style={{
            marginTop: 22,
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          <Col md={22}>
            Showing data from{" "}
            <b>{dates && dates[0] ? dates[0].format(" DD/MM/YYYY ") : " Start "}</b>
            to <b>{dates && dates[1] ? dates[1].format(" DD/MM/YYYY ") : " Now "}</b>
          </Col>
          <Col md={2}>
            <Popover
              placement="bottom"
              content={
                <>
                  <RangePicker
                    value={dates}
                    onChange={(val) => {
                      setDates(val);
                    }}
                  />
                  <br />
                </>
              }
              title="Pick a period to show information "
            >
              <CalendarOutlined className="calender-icon" />
            </Popover>
          </Col>
        </Row>
      )}
      <Row
        gutter={16}
        style={{
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <Tickets dates={dates} />
      </Row>
      {isAdmin() && <Divider />}
      <Row
        gutter={16}
        style={{
          marginTop: 22,
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        {isAdmin() && (
          <>
            <Col style={{marginBottom: 12}} span={24}>
              <LeadGraph />
            </Col>

            <Col style={{marginBottom: 12}} span={24}>
              <CloseGraph />
            </Col>
          </>
        )}

        <Col style={{marginBottom: 12}} span={24}>
          <CostGraph />
        </Col>

        <Col style={{marginBottom: 12}} span={12} xs={24} sm={24} xl={12} lg={12}>
          <TopReplacedParts />
        </Col>
        <Col style={{marginBottom: 12}} span={12} xs={24} sm={24} xl={12} lg={12}>
          <CauseOfDefect />
        </Col>
        <Col style={{marginBottom: 12}} span={12} xs={24} sm={24} xl={12} lg={12}>
          <ServiceProvider />
        </Col>
      </Row>
    </>
  );
}
