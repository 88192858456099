import api from "../api";

export const getTechnicianList = async () => {
  return await api.get(`/users/techs`);
};

export const getOwnerTechniciansList = async (id) => {
  return await api.get(`/users/owners/${id}/techs`);
};

export const getTechnicianById = async (id) => {
  return await api.get(`/users/techs/${id}`);
};
export const editTechnicianById = async (info) => {
  return await api.put(`/auth/accounts/techs/${info.id}`, {
    first_name: info.firstName,
    last_name: info.lastName,
    phone_area_code: "968",
    phone_number: info.phone,
  });
};

export const createTechnician = async (info) => {
  return await api.post(`/auth/accounts/techs`, {
    email: info.email,
    first_name: info.firstName,
    last_name: info.lastName,
    phone_area_code: "968",
    phone_number: info.phone,
  });
};

export const getTechnicianAccount = async () => {
  return await api.get(`/users/techs/my-profile`);
};
