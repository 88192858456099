import {Card, Tabs, Col, Divider, Row, Select, Input, Button, Modal} from "antd";
import {Layout} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import "antd/dist/antd.css";
import {useQuery} from "react-query";
import {getMyProfile} from "../services/users";
import {isOwner} from "../utils/check-role";
import {getProviderAccount} from "../services/providers";
import ProviderInfo from "../components/forms/newProviderStep2";
import ProviderOperationTime from "../components/forms/newProviderStep3";

const {Header, Content} = Layout;
const {TabPane} = Tabs;

export default function ServiceProviderDetails(props) {
  const isView = true;

  const history = useHistory();
  const [current, setCurrent] = useState(1);
  const [isEdit, setisEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Save changes");
  const {isLoading: isLoadingProfile, data: info, refetch} = useQuery(
    "getMyProfile",
    isOwner() ? getProviderAccount : getMyProfile
  );

  if (isLoadingProfile) {
    return <div>Loading...</div>;
  }

  const getTabContent = (current) => {
    if (!ShowOwnerTabs() || current === "2") {
      return (
        <div>
          <Divider orientation="left">
            {ShowOwnerTabs() ? "Contact Details" : "Account info"}
          </Divider>
          <Row>
            <Col md={9}>
              <div className="row-padding">
                <label>
                  First Name
                  <Input disabled={isView} defaultValue={info?.data.first_name} />
                </label>
              </div>
              <div className="row-padding">
                <label>
                  Last Name
                  <Input disabled={isView} defaultValue={info?.data.last_name} />
                </label>
              </div>
              <div className="row-padding">
                <label>
                  Phone Number{" "}
                  <Input
                    disabled={isView}
                    addonBefore={selectBeforePhone}
                    defaultValue={info?.data.phone_number}
                  />
                </label>
              </div>
            </Col>
            <Col md={14} style={{paddingLeft: "6%"}}>
              <div className="row-padding">
                <label>
                  Email
                  <Row>
                    <Col md={17}>
                      <Input disabled={true} value={info?.data.email} />
                    </Col>
                  </Row>
                </label>
              </div>
              <div className="row-padding">
                <label>
                  Password{" "}
                  <Col span="17">
                    <Input disabled={true} defaultValue="password" type="password" />
                  </Col>
                </label>
              </div>
              <div className="row-padding">
                <Button
                  type="primary"
                  onClick={() => {
                    history.push("/account/change-password");
                  }}
                >
                  Change Password
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    let operationTime = info?.data.operation_time?.map((v, index) => {
      return {
        day: v.day_str,
        isClosed: v.is_closed,
        startTime: v.start_str ? v.start_str : "00:00",
        endTime: v.end_str ? v.end_str : "00:00",
        index: index,
      };
    });
    switch (current) {
      case "3":
        return (
          <ProviderOperationTime
            formData={operationTime}
            isViewAccount={true}
            isEdit={isEdit}
            setEdit={setisEdit}
            handleClick={handleClick}
            setLoading={setLoading}
            setLoadingText={setLoadingText}
            refetch={refetch}
          />
        );
      default:
        return (
          <ProviderInfo
            formData={info?.data}
            isViewAccount={true}
            isViewOnly={true}
            isEdit={isEdit}
            setEdit={setisEdit}
            handleClick={handleClick}
            setLoading={setLoading}
            setLoadingText={setLoadingText}
            refetch={refetch}
          />
        );
    }
  };

  const selectBeforePhone = (
    <Select defaultValue="http://" className="select-before" disabled={true}>
      <Select.Option value="http://">+968</Select.Option>
    </Select>
  );
  const ShowOwnerTabs = () => {
    return isOwner();
  };

  const handleClick = () => {
    setisEdit(true);
  };
  const canShowEditButton = () => {
    return isOwner() && current !== "2";
  };

  const getEditButton = () => {
    return (
      <Button onClick={handleClick} type="primary">
        Edit
      </Button>
    );
  };
  const getSaveChangesCancel = () => {
    return (
      <div>
        <Button
          onClick={() => {
            Modal.confirm({
              icon: <ExclamationCircleOutlined />,
              title: "Are you sure you want to cancel?",
              content: (
                <span>You will lose all the changes you’ve made on this page.</span>
              ),
              okButtonProps: {form: "accountForm", key: "cancel", htmlType: "reset"},
              onOk() {
                setisEdit(false);
              },
              onCancel() {},
            });
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          type="primary"
          onClick={() => {
            Modal.confirm({
              icon: <ExclamationCircleOutlined />,
              title: "Are you sure you want to save changes?",
              okButtonProps: {form: "accountForm", key: "submit", htmlType: "submit"},
              onCancel() {},
            });
          }}
          style={{float: "right"}}
        >
          {loadingText}
        </Button>
      </div>
    );
  };

  return (
    <Layout>
      <Header className="breadcrumb-header">
        <span
          style={{
            fontFamily: "Roboto",
            fontWeight: "bold",
            fontSize: 20,
          }}
        >
          Account
        </span>
        <Tabs
          defaultActiveKey="1"
          onChange={(key) => {
            setCurrent(key);
            setisEdit(false);
          }}
        >
          <TabPane tab="Info" key="1" />
          {ShowOwnerTabs() && (
            <>
              <TabPane tab="Contact" key="2" />
              <TabPane tab="Operation Period" key="3" />
            </>
          )}
        </Tabs>
      </Header>

      <Content style={{margin: "24px 0px 0"}}>
        <div>
          {canShowEditButton()
            ? isEdit
              ? getSaveChangesCancel()
              : getEditButton()
            : null}
        </div>
        <Card
          className="site-layout-background"
          style={{
            marginTop: canShowEditButton() ? 24 : "",
            padding: 24,
            minHeight: "77vh",
          }}
        >
          {getTabContent(current)}
        </Card>
      </Content>
    </Layout>
  );
}
