import {
  Breadcrumb,
  Tabs,
  Tag,
  Select,
  Modal,
  Row,
  Col,
  message,
  Layout,
  Button,
  Steps,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {useState} from "react";
import {useParams, Link, useHistory} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import ImgTab from "./tabs/images";
import CustomerTab from "./tabs/customer";
import IssueTab from "./tabs/issue";
import CommentTab from "./tabs/comments";
import {colors} from "./colors";
import {
  getServiceRequestById,
  editTicketStatus,
  approveTicket,
  rejectTicket,
  getServiceRequestStatuses,
  getTicketHistory,
} from "../../services/tickets";
import {isAdmin} from "../../utils/check-role";
import ticketStatusResolver from "../../ticketStatusResolver";
import {shouldShowStatusDropdown} from "../../ticket-helper";
import moment from "moment";
const {confirm} = Modal;
const {Header, Content} = Layout;
const {TabPane} = Tabs;
const {Step} = Steps;

export default function NewServiceProvider() {
  const history = useHistory();
  const {serviceRequestNumber} = useParams();
  const [current, setCurrent] = useState("1");
  const {
    isLoading: isLoadingServiceRequestStatus,
    error: errorLoadingServiceRequestStatus,
    data: serviceRequestStatus,
  } = useQuery("getServiceRequestStatuses", () => getServiceRequestStatuses());
  const {
    isFetching: isLoadingServiceRequest,
    error: errorLoadingServiceRequest,
    data: serviceRequest,
    refetch,
  } = useQuery("requestDetails", () => getServiceRequestById(serviceRequestNumber), {
    refetchOnWindowFocus: false,
  });

  const {data: serviceRequestHistory} = useQuery(
    "requestHistory",
    () => getTicketHistory(serviceRequestNumber),
    {
      refetchOnWindowFocus: false,
    }
  );

  const changeStatusMutation = useMutation(editTicketStatus, {
    onSuccess: (data, variables, context) => {
      if (data.status >= 200 && data.status < 300) {
        message.success("Status updated successfully");
        refetch();
      } else {
        message.error(data.statusText);
      }
    },
    onError: (error, variables, context) => {
      message.error(error.response.data.error.message);
    },
  });

  const getCustomerTab = <CustomerTab data={serviceRequest?.data} refetch={refetch} />;
  const getIssueTab = <IssueTab data={serviceRequest?.data} />;
  const getImgTab = <ImgTab data={serviceRequest?.data} refetch={refetch} />;
  const getCommentTab = <CommentTab data={serviceRequest?.data} refetch={refetch} />;

  let component = [getIssueTab, getCustomerTab, getImgTab, getCommentTab];
  function callback(key) {
    setCurrent(key);
  }
  const handleStatusChange = (value) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: (
        <span>
          Are you sure you want to change the status to <b>{value}</b>?
        </span>
      ),
      onOk() {
        //get status id
        let statusId = -1;
        serviceRequestStatus?.data.forEach((item) => {
          if (item.key === value) statusId = item.id;
        });
        //call API and send id of status
        if (statusId >= 0) {
          changeStatusMutation.mutate({id: serviceRequest.data.id, status: statusId});
        }
      },
      onCancel() {},
    });
  };
  function getStatusOption() {
    if (isLoadingServiceRequestStatus || errorLoadingServiceRequestStatus) {
      return [];
    }
    let options = [];
    serviceRequestStatus?.data.length > 0 &&
      serviceRequestStatus?.data.forEach((item) => {
        if (item.key !== "pending") {
          options.push({
            label: item.value,
            value: item.key,
          });
        }
      });
    return options;
  }

  const [note, setNote] = useState();
  const [showError, setShowError] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const rejectOptions = {
    async onSuccess() {
      setConfirmLoading(false);
      await refetch();
      setNote();
      setShowError(false);
      hideModal();
    },
    onError() {
      setConfirmLoading(false);
      message.error("Problem in rejecting service provider");
    },
  };
  const approveOptions = {
    async onSuccess() {
      await refetch();
    },
    onError() {
      message.error("Problem in approving service provider");
    },
  };

  const Reject = useMutation(
    "rejected",
    () => rejectTicket(serviceRequest?.data.id, note),
    rejectOptions
  );
  const Approve = useMutation(
    "approved",
    () => approveTicket(serviceRequest?.data.id),
    approveOptions
  );

  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const hideModal = () => {
    setShowError(false);
    setNote();
    setVisible(false);
  };

  if (isLoadingServiceRequest) {
    return <div>Loading...</div>;
  }
  if (errorLoadingServiceRequest) {
    message.error("Failed to load service requests!");
  }
  const status = ticketStatusResolver(serviceRequest?.data);

  return (
    <Layout>
      <Header className="breadcrumb-header">
        <Breadcrumb style={{margin: "16px 0"}}>
          <Breadcrumb.Item>
            <Link to="/service-requests">Service Requests</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {serviceRequestNumber === "new"
              ? "New Ticket"
              : `Ticket ${serviceRequestNumber}`}
            {" -"}
            {serviceRequest?.data.shop_name}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col md={isAdmin() ? 12 : 20} sm={24}>
            <h3
              style={{
                fontSize: 20,
                fontWeight: 500,
                color: "#000000",
              }}
            >
              <ArrowLeftOutlined
                onClick={() => {
                  history.goBack();
                }}
              />{" "}
              {serviceRequestNumber === "new"
                ? "New Ticket"
                : `Ticket ${serviceRequestNumber}`}{" "}
              {status ? <Tag color={colors[status]}>{status}</Tag> : "loading..."}
            </h3>
          </Col>
          <Col md={isAdmin() ? 12 : 4} sm={24}>
            {localStorage.getItem("role") === "owner" &&
            shouldShowStatusDropdown(serviceRequest?.data) ? (
              <Select
                value={serviceRequest?.data.ticket_status.value}
                style={{width: 120}}
                disabled={serviceRequest?.data.ticket_status.key === "pending"}
                onChange={handleStatusChange}
                options={getStatusOption()}
              />
            ) : (
              ""
            )}
            {isAdmin() && (
              <div className="time-line-container">
                <Steps
                  progressDot
                  current={
                    serviceRequestHistory?.data
                      ? serviceRequestHistory?.data.length - 1
                      : 0
                  }
                  direction="vertical"
                  className="time-line"
                >
                  {serviceRequestHistory?.data?.map((item) => {
                    return (
                      <Step
                        title={"Ticket " + item.status + ","}
                        subTitle={`${moment(item.date).format("MMM DD yyyy hh:mm A")}`}
                      />
                    );
                  })}
                  {serviceRequestHistory?.data[serviceRequestHistory?.data.length - 1]
                    .status !== "Done" && <Step title="Done" />}
                </Steps>
              </div>
            )}
          </Col>
        </Row>

        <Tabs defaultActiveKey="1" onChange={callback} tabBarStyle={{marginBottom: 0}}>
          <TabPane tab="Issue" key="1" />
          <TabPane tab="Customer" key="2" />
          <TabPane tab="Images" key="3" />
          {isAdmin() && <TabPane tab="Comments" key="4" />}
        </Tabs>
      </Header>

      {serviceRequest?.data.is_approved === 2 && (
        <div style={{marginTop: "24px"}}>
          <Button
            icon={<QuestionCircleOutlined />}
            onClick={() => {
              Modal.info({
                title: "Rejection Note",
                content: (
                  <div>
                    <p>{serviceRequest?.data.reject_note}</p>
                  </div>
                ),
                onOk() {},
              });
            }}
          >
            View Rejection Note
          </Button>
          {isAdmin() && (
            <Button
              style={{marginLeft: "2%"}}
              icon={<CheckCircleOutlined />}
              type="primary"
              onClick={() => {
                Modal.confirm({
                  icon: <ExclamationCircleOutlined />,
                  title: (
                    <span>
                      Are you sure you want to approve Ticket{" "}
                      <strong>{serviceRequest.data.id}</strong>?
                    </span>
                  ),
                  content:
                    "Once the ticket is approved, the service provider will start working on it.",
                  async onOk() {
                    if (!showError) {
                      await Approve.mutateAsync();
                    }
                  },
                  onCancel() {},
                });
              }}
            >
              Approve
            </Button>
          )}
        </div>
      )}

      {isAdmin() && serviceRequest?.data.is_approved === 0 && (
        <div style={{marginTop: "24px", textAlign: "right"}}>
          <Button
            icon={<CloseCircleOutlined />}
            type="primary"
            danger
            onClick={() => {
              showModal();
            }}
          >
            Reject
          </Button>
          <Button
            style={{marginLeft: "2%"}}
            icon={<CheckCircleOutlined />}
            type="primary"
            onClick={() => {
              Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: (
                  <span>
                    Are you sure you want to approve Ticket{" "}
                    <strong>{serviceRequest.data.id}</strong>?
                  </span>
                ),
                content:
                  "Once the ticket is approved, the service provider will start working on it.",
                async onOk() {
                  if (!showError) {
                    await Approve.mutateAsync();
                  }
                },
                onCancel() {},
              });
            }}
          >
            Approve
          </Button>
        </div>
      )}

      <Content style={!isAdmin() ? {margin: "24px 0px 0"} : {}}>
        {component[current - 1]}
      </Content>
      <Modal
        title={
          <span>
            You are about to reject
            <br />
            Ticket <strong>{serviceRequest?.data.id}</strong>
          </span>
        }
        visible={visible}
        confirmLoading={confirmLoading}
        onOk={async () => {
          if (!showError && note && note?.trim() !== "") {
            setConfirmLoading(true);
            await Reject.mutateAsync();
          } else setShowError(true);
        }}
        onCancel={hideModal}
      >
        <p>Tell them why</p>
        <TextArea
          placeholder="write something"
          value={note}
          onChange={(e) => {
            if (e.target.value.trim() !== "" && showError) {
              setShowError(false);
            }
            setNote(e.target.value);
          }}
          onBlur={() => {
            if (!note || note === "") {
              setShowError(true);
            }
          }}
          maxLength={100}
          showCount
          rows={3}
        />
        <p className="invalid">{showError ? "Rejection note is required" : ""}</p>
      </Modal>
    </Layout>
  );
}
