import {Card, Input, Col, message} from "antd";
import React, {useState} from "react";
import Table from "../../../components/tables/serviceRequest";
import "../serviceProvider.css";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {getOwnerTickets} from "../../../services/tickets";

// This is the tickets tab
const ServiceRequestListPage = () => {
  const {id} = useParams();
  const {
    isFetching: isLoadingServiceRequests,
    error: errorLoadingServiceRequests,
    data: serviceRequests,
  } = useQuery("serviceRequestList", () => getOwnerTickets(id), {
    refetchOnWindowFocus: false,
  });

  const [filter, setFilter] = useState({
    search: "",
    status: "all",
    location: "all",
  });

  const onSearch = (value) => {
    setFilter({...filter, search: value.trim()});
  };

  if (errorLoadingServiceRequests) {
    message.error("Failed to load service requests");
    return null;
  }
  return (
    <Card bordered={false}>
      <Col xs={24} sm={10} md={8} lg={8} xl={12}>
        <Input.Search
          placeholder="input search text"
          onSearch={onSearch}
          enterButton
          className="search-input"
          allowClear="true"
        />
      </Col>
      <br />
      <Table
        filtration={filter}
        data={serviceRequests?.data}
        admin={true}
        isLoading={isLoadingServiceRequests}
      />
    </Card>
  );
};
export default ServiceRequestListPage;
