import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import {Card, Breadcrumb, Tabs, message, Button, Modal} from "antd";
import {Layout} from "antd";
import {ArrowLeftOutlined, ExclamationCircleOutlined} from "@ant-design/icons";

import ServiceProvider from "./tabs/serviceProvider";

import {useQuery} from "react-query";
import {getPartsById} from "../../services/inventories";

import {useParams, Link} from "react-router-dom";
import {isAdmin} from "../../utils/check-role";
import AddPart from "../../components/forms/newSparePartStep1";

const {Header, Content} = Layout;
const {TabPane} = Tabs;
export default function ServiceProviderDetails() {
  const history = useHistory();
  const {id} = useParams();
  const [isEdit, setisEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Save changes");

  const {
    isLoading: isLoadingSparePardInfo,
    error: errorLoadingSparePardInfo,
    data: sparePartInfo,
    refetch,
  } = useQuery(
    "getPart",
    () => {
      return getPartsById(id);
    },
    {cacheTime: 0}
  );
  useEffect(() => {
    refetch();
  }, [useParams().id]);

  const [current, setCurrent] = useState(1);
  const getComponent = (current) => {
    switch (current) {
      case "2":
        return (
          <ServiceProvider
            id={sparePartInfo?.data.id}
            isEdit={isEdit}
            setEdit={setisEdit}
            setLoading={setLoading}
            setLoadingText={setLoadingText}
          />
        );
      default:
        return (
          <AddPart
            isViewOnly={true}
            isEdit={isEdit}
            setEdit={setisEdit}
            handleClick={handleClick}
            setLoading={setLoading}
            setLoadingText={setLoadingText}
            refetch={refetch}
            formData={sparePartInfo?.data}
          />
        );
    }
  };
  function callback(key) {
    setisEdit(false);
    setCurrent(key);
  }

  const handleClick = () => {
    setisEdit(true);
  };
  const getEditButton = () => {
    return (
      <Button onClick={handleClick} type="primary">
        Edit
      </Button>
    );
  };
  const getSaveChangesCancel = () => {
    return (
      <div>
        <Button
          onClick={() => {
            Modal.confirm({
              icon: <ExclamationCircleOutlined />,
              title: "Are you sure you want to cancel?",
              content: (
                <span>You will lose all the changes you’ve made on this page.</span>
              ),
              onOk() {
                setisEdit(false);
              },
              onCancel() {},
            });
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          type="primary"
          onClick={() => {
            Modal.confirm({
              icon: <ExclamationCircleOutlined />,
              title: "Are you sure you want to save changes?",
              okButtonProps: {form: "partsForm", key: "submit", htmlType: "submit"},
              onCancel() {},
            });
          }}
          style={{float: "right"}}
        >
          {loadingText}
        </Button>
      </div>
    );
  };
  if (errorLoadingSparePardInfo) {
    message.error("Failed to load spare part detail");
  }

  if (isLoadingSparePardInfo) {
    return <div>Loading...</div>;
  }

  const canShowSPTabPage = () => {
    return isAdmin();
  };

  const canShowEditButton = () => {
    return isAdmin();
  };

  return (
    <Layout>
      <Header className="breadcrumb-header">
        <Breadcrumb style={{margin: "16px 0"}}>
          <Breadcrumb.Item>
            <Link to="/inventory">Inventory</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{sparePartInfo?.data.name}</Breadcrumb.Item>
        </Breadcrumb>
        <h3>
          <ArrowLeftOutlined
            onClick={() => {
              history.goBack();
            }}
          />
          {sparePartInfo?.data.name}
        </h3>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Info" key="1" />
          {canShowSPTabPage() && <TabPane tab="Service Providers" key="2" />}
        </Tabs>
      </Header>
      <Content style={{margin: "24px 0px 0"}}>
        <div>
          {canShowEditButton()
            ? isEdit
              ? getSaveChangesCancel()
              : getEditButton()
            : null}
        </div>
        <Card
          className="site-layout-background"
          style={{marginTop: 24, padding: 24, minHeight: "77vh"}}
        >
          {getComponent(current)}
        </Card>
      </Content>
    </Layout>
  );
}
