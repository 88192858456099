import React, {useState} from "react";
import styles from "./Support.module.css";
import BlackHeader from "../../components/blackHeader/BlackHeader";
import Map from "../../components/Map/Map";
import Box from "../../components/box/Box";
import ServiceProvider from "../../components/serviceProvider/ServiceProvider";
import noData from "../../assets/images/noData.svg";
import thinking from "../../assets/images/thinking.svg";
import paper from "../../assets/images/paper.svg";
import {getServiceProviders} from "../../services/customers";
import {useQuery} from "react-query";
import {Divider} from "antd";
const Support = () => {
  const [geocode, setGeocode] = useState(null);
  const {error, isLoading: isSpLoading, data: spList} = useQuery(
    "getServiceProviders",
    getServiceProviders
  );
  // const {error: errorGeocode, isLoading: isGeocodeLoading, data: geoCode} = useQuery("getGeocode", getGeocode)
  const [SPopen, setSPopen] = useState(-1);

  if (error) {
    return <div>Something went wrong, please refresh the page.</div>;
  }

  if (isSpLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <BlackHeader />
      <div className={styles.background}>
        <div className={`${styles.root} ${styles.rootMargin}`}>
          <h1 className={styles.title}>We’re here to help.</h1>
          <div className={styles.text}>
            Find the nearest service provider to help you get support with your device{" "}
          </div>
          {/*<SupportLocationSelector/>*/}
          <div className={styles.mapDataContainer}>
            <Map geocode={geocode} markersList={spList?.data} setExpanded={setSPopen} />
            <div className={styles.providerContainer}>
              <div style={{width: "95%"}}>
                <Divider
                  orientation="left"
                  style={{
                    color: "#48BBCB",
                    borderTopColor: "#48BBCB",
                    margin: "4%",
                    fontSize: "24px",
                  }}
                >
                  <strong>Pick A Service Provider To Help You</strong>
                </Divider>
              </div>
              <div
                className={styles.providerContainerList}
                style={{
                  scrollbarColor: "#48BBCB green",
                }}
              >
                {spList.length === 0 ? (
                  <Box relative={true}>
                    <img className={styles.noDataImg} src={noData} alt="no data" />
                    <img className={styles.thinkingImg} src={thinking} alt="thinking" />
                    <img className={styles.paperImg} src={paper} alt="paper" />
                    <div className={`${styles.dotImg} ${styles.one}`} />
                    <div className={`${styles.dotImg} ${styles.two}`} />
                    <div className={`${styles.dotImg} ${styles.three}`} />
                    <div className={styles.noDataText}>No Data</div>
                  </Box>
                ) : (
                  <>
                    <div className={styles.spContainer}>
                      {spList.data.map((serviceProvider, index) => {
                        return (
                          <ServiceProvider
                            address={serviceProvider.address}
                            shopName={serviceProvider.shop_name}
                            id={index}
                            shopAddress={serviceProvider.shop_address}
                            phoneNumber={serviceProvider.full_phone_number}
                            email={serviceProvider.email}
                            officeDays={serviceProvider.operation_time}
                            serviceId={serviceProvider.id}
                            open={SPopen === index}
                            shopLogo={
                              serviceProvider.shop_logo
                                ? serviceProvider.shop_logo
                                : "error"
                            }
                            handleLearnMoreClick={() => {
                              setGeocode({
                                lat: +serviceProvider.latitude,
                                lng: +serviceProvider.longitude,
                              });
                            }}
                            setExpanded={setSPopen}
                            disableButton={false}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Support;
