import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useMutation} from "react-query";
import {createServiceRequest} from "../../services/customers";
import {CheckCircleFilled, WarningFilled} from "@ant-design/icons";
import {Divider, Steps} from "antd";
import "./Stepper.css";

import First from "./first/First";
import Second from "./second/Second";
import Third from "./third/Third";
import Summary from "./summary/Summary";
import Button from "./button/Button";
import styles from "./Stepper.module.css";

import * as yup from "yup";

const step1Schema = yup.object().shape({
  Device_issue: yup.string().required(),
  Type_of_issue: yup.array().min(1).required(),
});
const step2Schema = yup.object().shape({
  Serial_Number: yup.string().required("Serial_Number must be confirmed"),
});
const step3Schema = yup.object().shape({
  First_Name: yup.string().required(),
  Last_Name: yup.string().required(),
  Owner_Email: yup.string().email().required(),
  Phone_Number: yup.string().required(),
});
const schemaArray = [step1Schema, step2Schema, step3Schema];

const HorizontalLinearStepper = () => {
  const history = useHistory();
  const {id} = useParams("id");
  const [activeStep, setActiveStep] = useState(0);
  const [first, setFirst] = useState(new Map());
  const [second, setSecond] = useState(new Map());
  const [third, setThird] = useState(new Map());
  const [errors, setErrors] = useState([{}, {}, {}]);
  const [errorSubmitting, setErrorSubmitting] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false); // to disable going next if image is being uploaded

  const pageSteps = [
    {
      title: "What’s happening?",
      content: <First first={first} setFirst={setFirst} errors={errors[0]} />,
    },
    {
      title: "Your device",
      content: (
        <Second
          second={second}
          setIsUploadingImage={setIsUploadingImage}
          setSecond={setSecond}
          errors={errors[1]}
        />
      ),
    },
    {
      title: "Customer Info",
      content: <Third third={third} setThird={setThird} errors={errors[2]} />,
    },
    {
      title: "Review",
      content: (
        <Summary
          answers={new Map([...first, ...second, ...third])}
          change={setCurrentStep}
        />
      ),
    },
  ];

  const requestMutation = useMutation(createServiceRequest, {
    onSuccess: () => {
      setSubmitting(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    },
    onError: () => {
      setSubmitting(false);
      setErrorSubmitting("Failed to create ticket");
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    },
  });
  function setCurrentStep(stepIndex) {
    setActiveStep(stepIndex);
  }

  const handleNext = () => {
    validateData(true);
  };

  useEffect(() => {
    if (activeStep === 1) {
      validateData(false);
    }
  }, [second.get("Serial_Number")]);

  const validateData = (isSubmit) => {
    let stepData;
    if (activeStep === 0) {
      stepData = first;
    }
    if (activeStep === 1) {
      stepData = second;
    }
    if (activeStep === 2) {
      stepData = third;
    }

    schemaArray[activeStep]
      .validate(
        Array.from(stepData).reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {}),
        {abortEarly: false}
      )
      .then(function (valid) {
        setErrors([{}, {}, {}]);
        if (isSubmit) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      })
      .catch(function (err) {
        let stepErrors = {};
        err.errors?.forEach((element) => {
          let name = element.split(" ")[0];
          stepErrors[name] = element.split("_").join(" ");
        });
        let temp = [...errors];
        temp[activeStep] = stepErrors;
        setErrors(temp);
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleFinish = async () => {
    let obj = Array.from(new Map([...first, ...second, ...third])).reduce(
      (obj, [key, value]) => {
        obj[key] = value;
        return obj;
      },
      {}
    );
    obj = {...obj, id};
    setSubmitting(true);
    await requestMutation.mutate(obj);
  };

  return (
    <div className={styles.root}>
      <Steps
        id="support"
        current={activeStep}
        style={{marginTop: "50px"}}
        className="steps-header"
      >
        {pageSteps.map((item) => (
          <Steps.Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <div>
        {activeStep === pageSteps.length ? (
          errorSubmitting ? (
            <div className={styles.finish}>
              <WarningFilled
                style={{
                  color: "#CC4242",
                  fontSize: 100,
                  marginBottom: 25,
                }}
              />
              <h3 style={{color: "#42BBCC"}}>Your request has Failed!</h3>
              <p style={{color: "#757575"}}>{errorSubmitting}</p>
              <button className={styles.button} onClick={handleReset}>
                Back
              </button>
            </div>
          ) : (
            <div className={styles.finish}>
              <CheckCircleFilled
                style={{
                  color: "#42bbcc",
                  fontSize: 100,
                  marginBottom: 25,
                }}
              />
              <h3 style={{color: "#43BCCD"}}>Your request is submitted!</h3>
              <p>Check your email for confirmation of your request</p>
              <button className={styles.button} onClick={() => history.push("/support")}>
                Return to Onsor homepage
              </button>
            </div>
          )
        ) : (
          <div style={{height: "100%"}}>
            <div>
              <div style={{marginTop: "60px", minHeight: "50vh"}}>
                {pageSteps[activeStep].content}
              </div>
              <Divider style={{borderTopColor: "gray"}}></Divider>
              <div
                style={{marginBottom: "10px", paddingBottom: "30px"}}
                className={styles.buttonContainer}
              >
                <Button
                  style={{cursor: "pointer"}}
                  // disabled={activeStep === 0 ? true : false}
                  click={
                    activeStep === 0
                      ? () => {
                          history.goBack();
                        }
                      : handleBack
                  }
                  text="Back"
                  backgroundColor="#1E1E1E"
                  left="20px"
                />
                <Button
                  style={{cursor: "pointer"}}
                  right="20px"
                  disabled={isSubmitting || isUploadingImage}
                  click={activeStep === pageSteps.length - 1 ? handleFinish : handleNext}
                  text={
                    activeStep === pageSteps.length - 1
                      ? isSubmitting
                        ? "Uploading..."
                        : "Submit"
                      : "Next"
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default HorizontalLinearStepper;
