import {Card, Select, Input, Col, Row, message} from "antd";
import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import Table from "../../components/tables/serviceProvider";
import "./serviceProvider.css";
import {useQuery} from "react-query";
import {getGovernorates} from "../../services/static";
import {getProvidersList} from "../../services/providers";

function ServiceProvider({history}) {
  const [filter, setFilter] = useState({
    search: history.location?.state?.filter ? history.location.state.filter.search : "",
    location: history.location?.state?.filter
      ? history.location.state.filter.location
      : "All",
    status: history.location?.state?.filter
      ? history.location.state.filter.status
      : "All",
  });

  // search input
  const [searchInputValue, setSearchInputValue] = useState(
    history.location?.state?.filter ? history.location.state.filter.search : ""
  );

  const {
    error: errorLoadingGovernorates,
    isFetching: isFetchingGovernorates,
    data: governorates,
  } = useQuery("governorates", getGovernorates);
  const {
    isLoading: isLoadingServiceProviders,
    error: errorLoadingServiceProviders,
    data: serviceProviders,
  } = useQuery("serviceProviderList", getProvidersList);

  const handleFilterStatus = (value) => {
    // store filters in history state
    let state = {...history.location.state}; //browser
    state.filter = {...filter, status: value}; //new filter
    history.replace({...history.location, state});

    setFilter({...filter, status: value});
  };
  const handleFilterLocation = (value) => {
    // store filters in history state
    let state = {...history.location.state}; //browser
    state.filter = {...filter, location: value}; //new filter
    history.replace({...history.location, state});

    setFilter({...filter, location: value});
  };
  const onSearch = (value) => {
    // store filters in history state
    let state = {...history.location.state}; //browser
    state.filter = {...filter, search: value}; //new filter
    history.replace({...history.location, state});

    setFilter({...filter, search: value});
  };

  if (errorLoadingServiceProviders) {
    message.error("Unable to load service providers.");
    return;
  }

  return (
    <>
      <Card bordered={false}>
        <span className="title">Service Providers</span>
      </Card>
      <br />
      <Card bordered={false}>
        <Row>
          <span className="title filter">Filter:</span>
          <Col className="filter" xs={24} sm={24} md={8} lg={8}>
            Location:{" "}
            <Select
              value={filter.location}
              placeholder="select"
              style={{width: 200}}
              onChange={handleFilterLocation}
            >
              {errorLoadingGovernorates ? (
                <div>failed to load governorates</div>
              ) : isFetchingGovernorates ? (
                <div>Fetching...</div>
              ) : (
                <>
                  {<Select.Option value={"All"}>All</Select.Option>}
                  {governorates &&
                    governorates.data &&
                    governorates.data.map((item) => {
                      return <Select.Option value={item.id}>{item.name}</Select.Option>;
                    })}
                </>
              )}
            </Select>
          </Col>
          <Col className="filter" xs={24} sm={24} md={8} lg={8}>
            Status:{" "}
            <Select
              value={filter.status}
              placeholder="select"
              style={{width: 200}}
              onChange={handleFilterStatus}
            >
              <Select.Option value="All">All</Select.Option>
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="inactive">Inactive</Select.Option>
              <Select.Option value="rejected">Rejected</Select.Option>
              <Select.Option value="pending_approval">Pending Approval</Select.Option>
            </Select>
          </Col>
        </Row>
      </Card>
      <br />
      <Card bordered={false}>
        <Col xs={24} sm={10} md={8} lg={8} xl={12}>
          <Input.Search
            value={searchInputValue}
            placeholder="input search text"
            onSearch={onSearch}
            onChange={(e) => setSearchInputValue(e.target.value)}
            enterButton
            className="search-input"
            allowClear="true"
          />
        </Col>
        <br />
        <Table
          filtration={filter}
          data={serviceProviders?.data}
          isLoading={isLoadingServiceProviders}
        />
      </Card>
    </>
  );
}

export default withRouter(ServiceProvider);
