import {Menu, Button} from "antd";
import React, {useEffect, useState} from "react";
import {MenuUnfoldOutlined, MenuFoldOutlined} from "@ant-design/icons";
import "./mainPage.css";
import {version} from "../../package.json";
import {useLocation} from "react-router-dom";
import {buildTabs} from "./navTabs";

export default function SideMenu(props) {
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    let path = location.pathname.substring(1);
    if (path.includes("/")) {
      path = path.substring(0, path.indexOf("/"));
    }
    setSelectedKeys([path]);
  }, [location]);

  return (
    <Menu
      mode="inline"
      selectedKeys={selectedKeys}
      style={{height: "92vh", borderRight: 0}}
    >
      <Button
        type="primary"
        className="toggle-button"
        onClick={props.toggleAction}
        style={{marginBottom: 16}}
      >
        {React.createElement(props.isCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
      </Button>
      {buildTabs()}
      <Menu.Item
        style={{
          textAlign: "center",
          position: "absolute",
          bottom: 0,
          color: "#D1D1D1",
        }}
        disabled
      >
        {` v${version} `}
      </Menu.Item>
    </Menu>
  );
}
