import React from "react";
import {Redirect, Route} from "react-router-dom";

function ProtectedRoute(props) {
  const Component = props.component;
  const isAuthenticated = localStorage.getItem("tkn");

  return isAuthenticated ? (
    <Route path={props.path}>
      <Component />
    </Route>
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: {referrer: props.location.pathname},
      }}
    />
  );
}

export default ProtectedRoute;
