import {Tag} from "antd";
import React from "react";
import {isAdmin} from "../../utils/check-role";
import adminColumns from "../../views/serviceProviders/tabs/inventories/columns.js";
const superAdminColumns = [
  {
    title: "ID",
    dataIndex: "id",
    sorter: (record, nextRecord) => record.id > nextRecord.id,
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: (record, nextRecord) => record.name > nextRecord.name,
    searchable: true,
  },
  {
    title: "Description",
    dataIndex: "description",
    searchable: true,
  },
  {
    title: "Type",
    dataIndex: "inventory_type_value",
    sorter: (r, n) => r.inventory_type_id > n.inventory_type_id,
    searchable: true,
  },
  {
    title: "SKU",
    dataIndex: "sku",
    sorter: (record, nextRecord) => record.sku > nextRecord.sku,
    searchable: true,
  },
  {
    title: "Price (OMR)",
    dataIndex: "price",
    sorter: (record, nextRecord) => record.price > nextRecord.price,
  },
  {
    title: "Status",
    dataIndex: "is_active",
    sorter: (record, nextRecord) => record.is_active !== nextRecord.is_active,
    render: (text, record) => <Tag color={record.is_active ? "green" : null}>active</Tag>,
  },
];

export const getColumns = () => {
  return isAdmin() ? superAdminColumns : adminColumns;
};
