import {useForm, Controller} from "react-hook-form";
import {Button, Col, Row, TimePicker, Checkbox, Divider, message} from "antd";
import "antd/dist/antd.css";
import "./forms.css";
import moment from "moment";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useState} from "react";
import {useMutation} from "react-query";
import {signUpServiceProvider} from "../../services/auth";
import {editProviderAccount} from "../../services/providers";

const schema = yup.object().shape({});

function Step3(props) {
  const MutateProvider = useMutation(signUpServiceProvider, {
    onSuccess: (data) => {
      props.submitError(null);
      props.next();
    },
    onError: (error) => {
      props.submitError(error.response.statusText);
      props.next();
    },
  });

  const MutateOperationTime = useMutation(editProviderAccount, {
    onSuccess: (data) => {
      message.success("Provider information updates successfully!");
      props.refetch();
      props.setEdit(false);
      props.setLoadingText("Save Changes");
      props.setLoading(false);
    },
    onError: (error) => {
      message.error(error.response.statusText);
      props.setLoadingText("Save Changes");
      props.setLoading(false);
    },
  });

  const [operationTime, setOperationTime] = useState(
    props.formData && props.formData[0]
      ? props.formData
      : [
          {
            day: "Sunday",
            isClosed: false,
            startTime: "08:00",
            endTime: "17:00",
            index: 0,
          },
          {
            day: "Monday",
            isClosed: false,
            startTime: "08:00",
            endTime: "17:00",
            index: 1,
          },
          {
            day: "Tuesday",
            isClosed: false,
            startTime: "08:00",
            endTime: "17:00",
            index: 2,
          },
          {
            day: "Wednesday",
            isclosed: false,
            startTime: "08:00",
            endTime: "17:00",
            index: 3,
          },
          {
            day: "Thursday",
            isClosed: false,
            startTime: "08:00",
            endTime: "17:00",
            index: 4,
          },
          {
            day: "Friday",
            isClosed: true,
            startTime: "08:00",
            endTime: "17:00",
            index: 5,
          },
          {
            day: "Saturday",
            isClosed: true,
            startTime: "08:00",
            endTime: "17:00",
            index: 6,
          },
        ]
  );

  const {handleSubmit, control} = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    let isError = false;
    let time = operationTime.map((time) => {
      if (!time.isClosed && (time.startTime === "00:00" || time.endTime === "00:00")) {
        isError = true;
      }
      if (time.isClosed) {
        return {
          day: time.index + 1,
          is_closed: true,
        };
      } else {
        return {
          day: time.index + 1,
          start: time.startTime,
          end: time.endTime,
        };
      }
    });

    let myData = {...props.form1Data, ...props.form2Data, time};

    if (!isError) {
      if (props.isEdit) {
        props.setLoadingText("Saving Changes...");
        props.setLoading(true);
        MutateOperationTime.mutate({time});
      } else {
        MutateProvider.mutate(myData);
      }
    }
  };
  const getColumnItems = (dir) => {
    let columnItems = [];
    if (dir === "left") {
      for (let i = 0; i < 4; i++) {
        columnItems.push(operationTime[i]);
      }
    } else {
      for (let i = 4; i < operationTime.length; i++) {
        columnItems.push(operationTime[i]);
      }
    }
    return columnItems;
  };

  const DayComponent = (props) => {
    //we might need to use controller here
    return (
      <div className="row-padding">
        <Row>
          <Col sm={24} md={12}>
            {props.day && props.day.day}
          </Col>
          <Col>
            {props.day && !props.day.isClosed ? (
              <TimePicker.RangePicker
                defaultValue={[
                  moment(props.day && props.day.startTime, "h:mm"),
                  moment(props.day && props.day.endTime, "h:mm"),
                ]}
                disabled={true}
              />
            ) : (
              "Closed"
            )}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      {props.isViewAccount && <Divider orientation="left">Operation Period</Divider>}
      <div className="steps-div">
        <form id="accountForm" onSubmit={handleSubmit(onSubmit)}>
          {props.isViewAccount && !props.isEdit ? (
            <Row>
              <Col sm={24} md={12} lg={9}>
                {props.formData.map((item) => {
                  return <DayComponent control={control} day={item} />;
                })}
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col sm={24} md={12} style={{margin: "40px 0"}}>
                  {getColumnItems("left").map((time, index) => {
                    return (
                      <div key={time.index} className="row-padding">
                        <label>{time.day} </label>
                        <Row>
                          <Controller
                            as={
                              <>
                                <TimePicker.RangePicker
                                  format={"HH:mm"}
                                  style={{width: "70%"}}
                                  onChange={(e) => {
                                    let temp = [...operationTime];
                                    temp[time.index].startTime = e
                                      ? e[0].format("HH:mm")
                                      : "00:00";
                                    temp[time.index].endTime = e
                                      ? e[1].format("HH:mm")
                                      : "00:00";
                                    setOperationTime(temp);
                                  }}
                                  defaultValue={[
                                    moment(time.startTime, "h:mm"),
                                    moment(time.endTime, "h:mm"),
                                  ]}
                                  disabled={operationTime[time.index].isClosed}
                                />
                                <Checkbox
                                  style={{marginLeft: "20px"}}
                                  checked={operationTime[time.index].isClosed}
                                  onChange={(e) => {
                                    let temp = [...operationTime];
                                    temp[time.index].isClosed = !temp[time.index]
                                      .isClosed;
                                    setOperationTime(temp);
                                    time.isClosed = temp[time.index].isClosed;
                                  }}
                                >
                                  Closed
                                </Checkbox>
                              </>
                            }
                            name="operationTime"
                            control={control}
                          />
                        </Row>
                        <p className="invalid">
                          {!operationTime[time.index].isClosed &&
                          (time.startTime === "00:00" || time.endTime === "00:00")
                            ? "Operation Time is requeired"
                            : ""}
                        </p>
                      </div>
                    );
                  })}
                </Col>
                <Col sm={24} md={12} style={{margin: "40px 0"}}>
                  {getColumnItems().map((time) => {
                    return (
                      <div key={time.index} className="row-padding">
                        <label>{time.day} </label>
                        <Row>
                          <Controller
                            as={
                              <>
                                <TimePicker.RangePicker
                                  format={"HH:mm"}
                                  style={{width: "70%"}}
                                  onChange={(e) => {
                                    let temp = [...operationTime];
                                    temp[time.index].startTime = e
                                      ? e[0].format("HH:mm")
                                      : "00:00";
                                    temp[time.index].endTime = e
                                      ? e[1].format("HH:mm")
                                      : "00:00";
                                    setOperationTime(temp);
                                  }}
                                  defaultValue={[
                                    moment(time.startTime, "h:mm"),
                                    moment(time.endTime, "h:mm"),
                                  ]}
                                  disabled={operationTime[time.index].isClosed}
                                />
                                <Checkbox
                                  style={{marginLeft: "20px"}}
                                  checked={operationTime[time.index].isClosed}
                                  onChange={(e) => {
                                    let temp = [...operationTime];
                                    temp[time.index].isClosed = !temp[time.index]
                                      .isClosed;
                                    setOperationTime(temp);
                                    time.isClosed = temp[time.index].isClosed;
                                  }}
                                >
                                  Closed
                                </Checkbox>
                              </>
                            }
                            name="operationTime"
                            control={control}
                          />
                        </Row>
                        <p className="invalid">
                          {!operationTime[time.index].isClosed &&
                          (time.startTime === "00:00" || time.endTime === "00:00")
                            ? "Operation Time is requeired"
                            : ""}
                        </p>
                      </div>
                    );
                  })}
                </Col>
              </Row>
              {!props.isViewAccount ? (
                <div className="steps-action">
                  <Button type="primary" htmlType="submit" style={{float: "right"}}>
                    Finish
                  </Button>
                  <Button
                    style={{margin: "0 8px"}}
                    onClick={() => {
                      props.prev(operationTime);
                    }}
                  >
                    Back
                  </Button>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </form>
      </div>
    </>
  );
}

export default Step3;
