import React from "react";
import {Table} from "antd";
import "antd/dist/antd.css";

export default function DashboardTable({data, columns, isLoading = false}) {
  return (
    <Table
      columns={columns}
      pagination={{
        defaultPageSize: 5,
      }}
      dataSource={data}
      loading={isLoading}
    />
  );
}
