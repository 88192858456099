const isAdmin = () => {
  return localStorage.getItem("role") === "admin";
};
const isOwner = () => {
  return localStorage.getItem("role") === "owner";
};
const isTech = () => {
  return localStorage.getItem("role") === "tech";
};
const isAdminOrOwner = () => {
  const role = localStorage.getItem("role");
  return role === "admin" || role === "owner";
};
const isAdminOrClient = () => {
  const role = localStorage.getItem("role");
  return role === "admin" || role === "client";
};
const isClient = () => {
  return localStorage.getItem("role") === "client";
};
const getRole = () => {
  return localStorage.getItem("role");
};

module.exports = {
  isAdmin,
  isOwner,
  isTech,
  isAdminOrOwner,
  isClient,
  getRole,
  isAdminOrClient,
};
