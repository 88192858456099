import React, {useState, useEffect} from "react";
import {Card, Row, Col, Menu, DatePicker, Popover} from "antd";
import DashboardComponent from "./DashboardComponent";
import Chart from "react-apexcharts";
import {useQuery} from "react-query";
import {getTicketLeadTime} from "../../services/dashboard";
import styles from "./TotalCostOfSpareParts.module.css";
import LoadingComponent from "../LoadingComponent";
import {CalendarOutlined} from "@ant-design/icons";

const {RangePicker} = DatePicker;
const LeadGraph = (props) => {
  const {
    isFetching: isLoadingTicketLeadTime,
    data: ticketLeadTime,
    refetch: refetchLeadTime,
  } = useQuery(
    "getTicketsLeadTime",
    () => {
      return getTicketLeadTime({
        start: dates && dates[0]?.format("YYYY-MM-DD"),
        end: dates && dates[1]?.format("YYYY-MM-DD"),
      });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const [currentData, setCurrentData] = useState();
  const [totalAverage, setTotalAverage] = useState();
  const [dates, setDates] = useState();

  useEffect(() => {
    let sum = 0;
    let ticketLeadTimeData = ticketLeadTime?.data.map((item) => {
      if (!isNaN(item.average)) {
        sum += item.average;
      }
      return {
        x: item.month,
        y: !isNaN(item.average) ? item.average : 0,
      };
    });
    setTotalAverage(ticketLeadTime?.data[0] ? sum / ticketLeadTime?.data.length : "-");
    setCurrentData(ticketLeadTimeData);
  }, [ticketLeadTime]);

  useEffect(() => {
    refetchLeadTime({
      start: dates && dates[0].format("YYYY-MM-DD"),
      end: dates && dates[1].format("YYYY-MM-DD"),
    });
  }, [dates]);

  const series = [
    {
      name: "Average Lead",
      data: currentData,
    },
  ];
  const options = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      seriesName: "Lead Time",
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    xaxis: {
      axisTicks: {
        show: true,
        offsetX: 20,
      },
      tickPlacement: "between",
    },
    grid: {
      show: true,
      position: "front",
    },
    fill: {
      type: "gradient",
      gradient: {
        colorStops: [
          {
            color: "rgba(0,0,0,0.5)",
            opacity: 0,
          },
        ],
      },
    },
  };

  const TotalTime = (props) => {
    return (
      <Card
        style={{
          marginTop: 24,
          marginBottom: 16,
          padding: 0,
          height: "128px",
        }}
        bordered={true}
      >
        <div className={styles.cardInner}>
          <p className={styles.cardTitle}>{props.title}</p>
        </div>
        <div className={styles.cardInner}>
          <p className={styles.value}>{props.value}</p>
        </div>
      </Card>
    );
  };

  return (
    <DashboardComponent
      noOverflow={true}
      style={props.style}
      title={`What is the lead time for service requests?`}
      id="time-lead-card"
    >
      {!isLoadingTicketLeadTime ? ( // check loading
        <>
          <Row className={styles.header}>
            <Col span={6}>
              <Menu
                mode="horizontal"
                defaultSelectedKeys={["1"]}
                style={{
                  borderBottom: "none",
                }}
              >
                <Menu.Item key="1">Lead Time</Menu.Item>
              </Menu>
            </Col>
            <Col span={17}>
              <Popover
                placement="bottom"
                content={
                  <>
                    <RangePicker
                      value={dates}
                      onChange={(val) => {
                        setDates(val);
                      }}
                    />
                    <br />
                  </>
                }
                title="Pick a period to show information "
              >
                <CalendarOutlined className="calender-icon" />
              </Popover>
            </Col>
          </Row>
          <Row>
            <Col className="gutter-row" span={15} xs={24} sm={24} md={24} lg={18}>
              <Chart
                options={options}
                series={series}
                type="area"
                style={{
                  marginBottom: 0,
                }}
                height="456px"
                width="100%"
              />
            </Col>
            <Col
              span={9}
              xs={24}
              sm={24}
              md={24}
              lg={6}
              style={{display: "grid", alignItems: "center"}}
            >
              <TotalTime
                title={"Average Lead Time"}
                value={Math.round((totalAverage + Number.EPSILON) * 100) / 100}
              />
            </Col>
          </Row>
        </>
      ) : (
        <LoadingComponent />
      )}
    </DashboardComponent>
  );
};

export default LeadGraph;
