import styles from "./Ticket.module.css";
import {Card, Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

const Ticket = (props) => {
  return (
    <Card
      style={{
        padding: 0,
        height: "128px",
        marginBottom: 8,
        marginTop: 8,
      }}
      bordered={false}
    >
      <div className={styles.cardInner}>
        <p className={styles.cardTitle}>{props.title}</p>
        <Tooltip title={props.tooltipTitle}>
          <span>
            <InfoCircleOutlined />
          </span>
        </Tooltip>
      </div>
      <div className={styles.cardInner}>
        <p className={styles.value}>{props.isLoading ? "-" : props.value}</p>
      </div>
    </Card>
  );
};
export default Ticket;
