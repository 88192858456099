import React, {useState, useEffect} from "react";
import {Input, Col, Row} from "antd";
import ContentWrapper from "../../contentWrapper/ContentWrapper";
import styles from "./Third.module.css";
import "./Third.css";
const Third = (props) => {
  const [phone, setPhone] = useState(props.third.get("Phone_Number"));
  const targetedInput = ["First_Name", "Last_Name", "Owner_Email"];
  useEffect(() => {
    let newMap = new Map(props.third);
    let key = "Phone_Number";
    newMap.set(key, phone);
    props.setThird(newMap);
  }, [phone]);

  const inputHandler = (e, index) => {
    const newMap = new Map(props.third);
    let key = targetedInput[index];
    newMap.set(key, e.target.value);
    props.setThird(newMap);
  };
  return (
    <ContentWrapper>
      <div className={styles.root3}>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <label style={{paddingTop: "5px"}} className={styles.textContainer}>
              First Name*
            </label>
            <Input
              placeholder="First Name"
              className={styles.inputContainer}
              onBlur={(e) => {
                inputHandler(e, 0);
              }}
              defaultValue={props.third.get(targetedInput[0])}
            />
            <p className="invalid">{props.errors.First_Name}</p>
          </Col>
          <Col className="gutter-row" span={12}>
            <label style={{paddingTop: "5px"}} className={styles.textContainer}>
              Last Name*
            </label>
            <Input
              placeholder="Last Name"
              className={styles.inputContainer}
              onBlur={(e) => {
                inputHandler(e, 1);
              }}
              defaultValue={props.third.get(targetedInput[1])}
            />{" "}
            <p className="invalid">{props.errors.Last_Name}</p>
          </Col>
        </Row>
        <label style={{paddingTop: "5px"}} className={styles.textContainer}>
          Owner's Email*
        </label>
        <Input
          placeholder="example@email.com"
          className={styles.inputContainer}
          onBlur={(e) => {
            inputHandler(e, 2);
          }}
          defaultValue={props.third.get(targetedInput[2])}
        />{" "}
        <p className="invalid">{props.errors.Owner_Email}</p>
        <div className={"thirdStepPhoneNumber"} id="phone-input">
          <label style={{paddingTop: "5px"}} className={styles.textContainer}>
            Owner's phone number*
          </label>
          <Input
            type={"number"}
            style={{paddingTop: "5px"}}
            addonBefore="+968"
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            defaultValue={phone}
          />
          <p className="invalid">{props.errors.Phone_Number}</p>
        </div>
      </div>
    </ContentWrapper>
  );
};
export default Third;
