import React, {useEffect, useState} from "react";
import {getMyPartsList, getInventories} from "../../services/inventories";
import {useQuery} from "react-query";
import {withRouter} from "react-router-dom";
import {Card, Col, Input, Table, Row, Button, message} from "antd";
import {getColumns} from "./columns";
import {Content} from "antd/es/layout/layout";
import searchNow from "../../utils/search-helper";
import styles from "./inventory.module.css";
import {isAdmin} from "../../utils/check-role";

function Inventory({history}) {
  const {error, isLoading, data} = useQuery(
    isAdmin() ? "getInventories" : "getMyPartsList",
    isAdmin() ? getInventories : getMyPartsList
  );
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    setFilteredData(searchNow(data?.data, search, getColumns()));
  }, [data?.data, search]);

  if (error) {
    message.error("Unable to load inventories");
  }

  const handleSearch = (value) => {
    setSearch(value);
  };

  return (
    <>
      <Card bordered={false}>
        <span className="title">Inventory</span>
      </Card>
      <br />
      <Content>
        <Card>
          <Row>
            <Col xs={24} sm={10} md={8} lg={8} xl={12}>
              <Input.Search
                placeholder="input search text"
                onSearch={handleSearch}
                enterButton
                allowClear="true"
              />
            </Col>
            <Col style={{textAlign: "right"}} xs={24} sm={10} md={8} lg={8} xl={12}>
              {isAdmin() ? (
                <Button onClick={() => history.push("/inventory/new")} type="primary">
                  + Add Spare Part
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <br />
          <Table
            rowKey={"id"}
            rowClassName={styles.inventoryRow}
            onRow={(record) => {
              return {
                onClick: () => {
                  console.log(record);
                  history.push(`/inventory/${record.id}`);
                },
              };
            }}
            columns={getColumns()}
            loading={isLoading}
            dataSource={filteredData}
            pagination={{
              pageSizeOptions: ["10", "15", "20"],
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSize: history.location?.state?.currentSize
                ? history.location.state.currentSize
                : 10,
              onShowSizeChange: (page, size) => {
                let state = {...history.location.state};
                state.currentSize = size;
                history.replace({...history.location, state});
              },
              current: history.location?.state?.currentPage
                ? history.location.state.currentPage
                : 1,
              onChange: (currentPage) => {
                let state = {...history.location.state};
                state.currentPage = currentPage;
                history.replace({...history.location, state});
              },
            }}
          />
        </Card>
      </Content>
    </>
  );
}

export default withRouter(Inventory);
