import {Card, Steps, Breadcrumb} from "antd";
import {Layout} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import Step1 from "../../components/forms/newRequestStep1";
import Step2 from "../../components/forms/newRequestStep2";
const {Step} = Steps;
const {Header, Content} = Layout;

export default function NewServiceProvider() {
  const history = useHistory();
  const [current, setCurrent] = useState(0);
  const [myForms1Data, setMyForms1Data] = useState({});
  const [myForms2Data, setMyForms2Data] = useState({});
  const next = (data) => {
    setCurrent(current + 1);
    setMyForms1Data(data);
  };

  const prev = (data) => {
    setCurrent(current - 1);
    setMyForms2Data(data);
  };
  const steps = [
    {
      title: "Customer Details",
      content: <Step1 next={next} formData={myForms1Data} />,
    },
    {
      title: "Issue Details",
      content: <Step2 prev={prev} formData={myForms2Data} form1Data={myForms1Data} />,
    },
  ];
  return (
    <Layout>
      <Header className="breadcrumb-header">
        <Breadcrumb style={{margin: "16px 0"}}>
          <Breadcrumb.Item>
            <Link to="/service-requests">Service Request</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Create Service Request</Breadcrumb.Item>
        </Breadcrumb>
        <h2>
          <ArrowLeftOutlined
            onClick={() => {
              history.goBack();
            }}
          />{" "}
          Create Service Request
        </h2>
      </Header>
      <Content style={{margin: "24px 0px 0"}}>
        <Card className="site-layout-background" style={{padding: 24, minHeight: "77vh"}}>
          <Steps current={current} className="steps-header">
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div id="contentOfStep">{steps[current].content}</div>
        </Card>
      </Content>
    </Layout>
  );
}
