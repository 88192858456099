import React, {useEffect, useState} from "react";
import {Input, Col, Row, Space, message, List, Divider} from "antd";
import {useQuery} from "react-query";
import "antd/dist/antd.css";
import {getProvidersList} from "../../../services/providers";
import EditAssignedList from "../../../components/forms/newSparePartStep2";
import {getPartsAssignmentList} from "../../../services/inventories";

import {MinusCircleOutlined} from "@ant-design/icons";

function AssignServiceProvider(props) {
  const isView = true;
  const [searchText, setSearchText] = useState("");
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [options, setOptions] = useState([]);
  const {
    isLoading: isLoadingProvidersList,
    error: errorLoadingProvidersList,
    data: providersList,
  } = useQuery("providersList", getProvidersList);
  const {isFetching: isLoadingAssignment, data: AssignmentList, refetch} = useQuery(
    "assignmentList",
    () => {
      return getPartsAssignmentList(props.id);
    }
  );

  if (errorLoadingProvidersList) {
    message.error(JSON.stringify(providersList.statusText));
  }
  useEffect(() => {
    setSearchText("");
  }, [props.isEdit]);

  const filteredList = AssignmentList?.data
    .filter((shop) => {
      if (searchText === "" || props.isEdit) {
        return true;
      } else {
        return shop.shop_name.toLowerCase().includes(searchText.toLowerCase().trim());
      }
    })
    .map((shop) => {
      return {
        element: {shop_name: shop.shop_name, id: shop.shop_id},
        quantity: 0,
        current: shop.quantity,
      };
    });

  useEffect(() => {
    if (AssignmentList?.data && providersList?.data) {
      const arr = AssignmentList?.data.map((item) => item.shop_id);
      let myOptions = [];
      for (let i = 0; i < providersList?.data.length; i++) {
        if (!arr.includes(providersList?.data[i].id)) {
          myOptions.push(providersList?.data[i]);
        }
      }
      setOptions(myOptions);
    }
  }, [providersList?.data, AssignmentList?.data]);

  const viewAssignedProvider = () => {
    return (
      <Space style={{margin: "30px 4%", width: "90%"}} direction="vertical">
        {filteredList?.length > 0 && (
          <Row style={{width: "70%"}}>
            <Col span={16} style={{padding: "0px", marginBottom: "12px"}}>
              <label>
                <strong>Service Provider</strong>
              </label>
            </Col>
            <Col span={8} style={{padding: "0px", marginBottom: "12px"}}>
              <label>
                <strong>Quantity</strong>
              </label>
            </Col>
          </Row>
        )}
        <List
          dataSource={filteredList}
          loading={isLoadingProvidersList && isLoadingAssignment}
          pagination={{
            pageSizeOptions: ["10", "15", "20"],
            defaultPageSize: 10,
            showSizeChanger: true,
          }}
          renderItem={(v, index) => (
            <List.Item style={{border: "none"}}>
              <Row style={{width: "70%"}}>
                <Col span={16} style={{padding: "0px", marginBottom: "12px"}}>
                  <label>{v.element.shop_name}</label>
                </Col>
                <Col span={5} style={{padding: "0px", marginBottom: "12px"}}>
                  <Input
                    defaultValue={v.current}
                    disabled={isView}
                    onChange={(e) => {
                      let newList = [...selectedProviders];
                      newList[index].quantity = e.target.value;
                      setSelectedProviders(newList);
                    }}
                  />
                </Col>
                {!isView && (
                  <Col span={1} style={{padding: "0px", marginLeft: "10px"}}>
                    <MinusCircleOutlined
                      onClick={() => {
                        let newList = [...selectedProviders];
                        let newOptions = [...options];
                        let removed = newList.splice(index, 1);
                        setSelectedProviders(newList);
                        newOptions.push(removed[0].element);
                        setOptions(newOptions);
                      }}
                    />
                  </Col>
                )}
              </Row>
            </List.Item>
          )}
        />
      </Space>
    );
  };

  const editAssignedProvider = () => {
    return (
      <EditAssignedList
        id={props.id}
        isEdit={props.isEdit}
        setEdit={props.setEdit}
        setLoading={props.setLoading}
        setLoadingText={props.setLoadingText}
        refetch={refetch}
        formData={{options: options, selectedProviders: filteredList}}
      />
    );
  };

  return (
    <div>
      <Row>
        <Col md={props.isEdit ? 24 : 16}>
          <Divider orientation="left">Service Providers</Divider>
        </Col>{" "}
        {!props.isEdit && (
          <Col md={8} style={{paddingLeft: "1%"}}>
            <Input.Search
              placeholder="Search service provider"
              allowClear
              enterButton
              onChange={(e) => {
                console.log(e.target.value);
                setSearchText(e.target.value);
              }}
            />
          </Col>
        )}
      </Row>
      {!props.isEdit && (
        <>
          <p className="ant-upload-hint" style={{marginLeft: "4%"}}>
            You are viewing the list of Service Providers who have this spare part.
          </p>
          <br />
        </>
      )}
      {props.isEdit ? editAssignedProvider() : viewAssignedProvider()}
    </div>
  );
}

export default AssignServiceProvider;
