import {Card, Breadcrumb, Tabs, Tag} from "antd";
import {Layout} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {useParams, Link, useHistory} from "react-router-dom";
import {Col, Divider, Row, Select, Modal, Input, message, Button} from "antd";
import {useForm, Controller} from "react-hook-form";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import "antd/dist/antd.css";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useQuery, useMutation} from "react-query";
import {getTechnicianById, editTechnicianById} from "../../services/technician";
import {isOwner} from "../../utils/check-role";
import {disableUser, enableUser} from "../../services/users";
const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

const {Header, Content} = Layout;
const {TabPane} = Tabs;

export default function ServiceProviderDetails() {
  let {id} = useParams();
  const [isView, setisView] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Save changes");

  useEffect(() => {
    setLoadingText(loading ? "Saving changes..." : "Save changes");
  }, [loading]);

  const history = useHistory();
  const {
    isLoading: isLoadingTechnicianInfo,
    error: errorLoadingTechnicianInfo,
    data: technicianInfo,
    refetch,
  } = useQuery("technicianInfo", () => getTechnicianById(id));

  const editInfo = useMutation(editTechnicianById, {
    async onSuccess() {
      message.success("Technician information updated successfully");
      setLoading(false);
      await refetch();
      setisView(true);
    },
    onError() {
      setLoading(false);
      message.error("Problem Editing technician");
    },
  });

  const {handleSubmit, errors, control, setValue, getValues} = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setLoading(true);
    editInfo.mutate({...data, id: technicianInfo?.data.id});
  };
  const selectBeforePhone = (
    <Select defaultValue="http://" className="select-before" disabled={true}>
      <Select.Option value="http://">+968</Select.Option>
    </Select>
  );

  const disableOptions = {
    async onSuccess() {
      await refetch();
    },
    onError() {
      message.error("Problem disabling user");
    },
  };

  const enableOptions = {
    async onSuccess() {
      await refetch();
    },
    onError() {
      message.error("Problem enabling user");
    },
  };

  const disable = useMutation(
    "disable",
    () => disableUser(technicianInfo?.data.id),
    disableOptions
  );

  const enable = useMutation(
    "enable",
    () => enableUser(technicianInfo?.data.id),
    enableOptions
  );

  const getDisableButton = () => {
    return (
      <Button
        danger
        onClick={() => {
          Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: `Are you sure you want to disable ${
              technicianInfo?.data.first_name + " " + technicianInfo?.data.last_name
            } ?`,
            content:
              "This will result in technician being disabled and hidden from the public. technician won't be able to login until you reactivate it.",
            async onOk() {
              await disable.mutateAsync();
            },
            onCancel() {},
          });
        }}
      >
        Disable Account
      </Button>
    );
  };

  const getEnableButton = () => {
    return (
      <Button
        onClick={() => {
          Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: `Are you sure you want to activate ${
              technicianInfo?.data.first_name + " " + technicianInfo?.data.last_name
            }?`,
            content:
              "This will result in technician being enabled and shown to public. Technician will be able to login again.",
            async onOk() {
              await enable.mutateAsync();
            },
            onCancel() {},
          });
        }}
      >
        Enable Account
      </Button>
    );
  };

  if (isLoadingTechnicianInfo) {
    return <div>Loading...</div>;
  }

  if (errorLoadingTechnicianInfo) {
    message.error("Failed to load technician information!");
  }

  return (
    <Layout>
      <Header className="breadcrumb-header">
        <Breadcrumb style={{margin: "16px 0"}}>
          <Breadcrumb.Item>
            <Link to="/providers">Technician</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {" "}
            {technicianInfo?.data.first_name + " " + technicianInfo?.data.last_name}
          </Breadcrumb.Item>
        </Breadcrumb>
        <h3>
          <ArrowLeftOutlined
            onClick={() => {
              history.goBack();
            }}
          />{" "}
          {technicianInfo?.data.first_name + " " + technicianInfo?.data.last_name} {"  "}
          {technicianInfo?.data.is_active ? (
            <Tag color="green">active</Tag>
          ) : (
            <Tag>disabled</Tag>
          )}
        </h3>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Info" key="1" />
        </Tabs>
      </Header>

      {isOwner ? (
        <div style={{marginTop: "24px"}}>
          <Button
            disabled={loading}
            onClick={() => {
              !isView &&
                Modal.confirm({
                  icon: <ExclamationCircleOutlined />,
                  title: "Are you sure you want to cancel?",
                  content: (
                    <span>You will lose all the changes you’ve made on this page.</span>
                  ),
                  onOk() {
                    //reset all feild to original
                    setisView(true);
                    setValue("firstName", technicianInfo.data.first_name);
                    setValue("lastName", technicianInfo.data.last_name);
                    setValue("phone", technicianInfo.data.phone_number);
                  },
                  onCancel() {},
                });

              isView && setisView(!isView);
            }}
            type={isView ? "primary" : ""}
          >
            {isView ? "Edit" : "Cancel"}
          </Button>
          {!isView ? (
            <Button
              disabled={loading}
              type="primary"
              onClick={() => {
                Modal.confirm({
                  icon: <ExclamationCircleOutlined />,
                  title: "Are you sure you want to save changes?",
                  okButtonProps: {form: "techForm", key: "submit", htmlType: "submit"},
                  async onOk() {},
                  onCancel() {},
                });
              }}
              style={{float: "right"}}
            >
              {loadingText}
            </Button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      <Content style={{margin: "24px 0px 0"}}>
        <Card className="site-layout-background" style={{padding: 24, minHeight: "77vh"}}>
          <div>
            <Row>
              <Col md={16}>
                <Divider orientation="left">Technician Info</Divider>
              </Col>{" "}
              <Col md={8} style={{paddingLeft: "3%"}}>
                Status:{" "}
                {technicianInfo?.data.is_active ? (
                  <Tag color="green">active</Tag>
                ) : (
                  <Tag>disabled</Tag>
                )}
                {isOwner()
                  ? technicianInfo?.data.is_active
                    ? getDisableButton()
                    : getEnableButton()
                  : null}
              </Col>
            </Row>
            <form id="techForm" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={9}>
                  <div className="row-padding">
                    <Controller
                      render={() => (
                        <label>
                          First Name
                          <Input
                            disabled={isView}
                            value={getValues().firstName}
                            onChange={(e) => {
                              setValue("firstName", e.target.value);
                            }}
                            defaultValue={
                              technicianInfo
                                ? technicianInfo.data.first_name
                                : "...loading"
                            }
                          />
                        </label>
                      )}
                      name="firstName"
                      control={control}
                      defaultValue={technicianInfo?.data.first_name}
                    />

                    <p className="invalid">{errors.firstName?.message}</p>
                  </div>
                  <div className="row-padding">
                    <Controller
                      render={() => (
                        <label>
                          Last Name
                          <Input
                            disabled={isView}
                            value={getValues().lastName}
                            onChange={(e) => {
                              setValue("lastName", e.target.value);
                            }}
                            defaultValue={technicianInfo?.data.last_name}
                          />
                        </label>
                      )}
                      name="lastName"
                      defaultValue={technicianInfo?.data.last_name}
                      control={control}
                    />
                    <p className="invalid">{errors.lastName?.message}</p>
                  </div>
                </Col>
                <Col md={14} style={{paddingLeft: "6%"}}>
                  <div className="row-padding">
                    <Controller
                      render={() => (
                        <label>
                          Phone Number{" "}
                          <Col span="17">
                            <Input
                              disabled={isView}
                              addonBefore={selectBeforePhone}
                              defaultValue={technicianInfo?.data.phone_number}
                              value={getValues().phone}
                              onChange={(e) => {
                                setValue("phone", e.target.value);
                              }}
                            />
                          </Col>
                        </label>
                      )}
                      name="phone"
                      defaultValue={technicianInfo?.data.phone_number}
                      control={control}
                    />
                    <p className="invalid">{errors.phone?.message}</p>
                  </div>

                  <div className="row-padding">
                    <Controller
                      as={
                        <label>
                          Email
                          <Row>
                            <Col md={17}>
                              <Input disabled={true} value={technicianInfo?.data.email} />
                            </Col>
                            {localStorage.getItem("role") === "admin" ? (
                              <Col style={{marginLeft: "1%"}}>
                                {localStorage.getItem("role") === "owner" ? (
                                  <Button type="primary">Change Email</Button>
                                ) : (
                                  ""
                                )}
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </label>
                      }
                      name="email"
                      control={control}
                      value={technicianInfo?.data.email}
                    />
                    <p className="invalid">{errors.email?.message}</p>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Card>
      </Content>
    </Layout>
  );
}
