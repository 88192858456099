import {Card, Breadcrumb, Button, Input, Divider, Row, Col, Select, message} from "antd";
import {Layout} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import React, {useState, useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import "antd/dist/antd.css";

import {useForm, Controller} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useMutation} from "react-query";
import {createTechnician} from "../../services/technician";
const {Header, Content} = Layout;

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email().required("Email is required"),
  phone: yup
    .string()
    .test(
      "len",
      "Must be 8 digits",
      (val) => val && (val.toString().length === 8 || val.toString().length === 0)
    ),
});
export default function NewTechnician() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Create Technician");
  const {handleSubmit, errors, control} = useForm({
    resolver: yupResolver(schema),
  });
  const addTechnician = useMutation(createTechnician, {
    async onSuccess() {
      setLoading(false);
      message.success("Technician added successfully");
      history.push("/technicians");
    },
    onError(error) {
      setLoading(false);
      if (error.response.data?.error) {
        message.error("Problem Adding technician," + error.response.data.error.message);
      } else {
        message.error("Problem Adding technician");
      }
    },
  });
  useEffect(() => {
    setLoadingText(loading ? "Creating Technician..." : "Create Technician");
  }, [loading]);

  const onSubmit = async (data) => {
    setLoading(true);
    addTechnician.mutate(data);
    //Call api
  };
  const selectBeforePhone = (
    <Select defaultValue="http://" className="select-before" disabled={true}>
      <Select.Option value="http://">+968</Select.Option>
    </Select>
  );

  return (
    <Layout>
      <Header className="breadcrumb-header">
        <Breadcrumb style={{margin: "16px 0"}}>
          <Breadcrumb.Item>
            <Link to="/technicians">Technicians</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Create Technician</Breadcrumb.Item>
        </Breadcrumb>
        <h2>
          <ArrowLeftOutlined
            onClick={() => {
              history.goBack();
            }}
          />{" "}
          Create Technician
        </h2>
      </Header>
      <Content style={{margin: "24px 0px 0"}}>
        <Card className="site-layout-background" style={{padding: 24}}>
          <div>
            <Divider orientation="left">Technician Info</Divider>

            <form id="techForm" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={9}>
                  <div className="row-padding">
                    <Controller
                      as={
                        <label>
                          First Name*
                          <Input />
                        </label>
                      }
                      name="firstName"
                      control={control}
                    />
                    {errors.firstName && (
                      <span className="invalid">{errors.firstName.message}</span>
                    )}
                  </div>
                  <div className="row-padding">
                    <Controller
                      as={
                        <label>
                          Last Name*
                          <Input />
                        </label>
                      }
                      name="lastName"
                      control={control}
                    />
                    {errors.lastName && (
                      <span className="invalid">{errors.lastName.message}</span>
                    )}
                  </div>
                </Col>
                <Col md={9} style={{marginLeft: "6%"}}>
                  <div className="row-padding">
                    <Controller
                      as={
                        <label>
                          Phone Number
                          <Input addonBefore={selectBeforePhone} />
                        </label>
                      }
                      name="phone"
                      control={control}
                    />
                    {errors.phone && (
                      <span className="invalid">{errors.phone.message}</span>
                    )}
                  </div>

                  <div className="row-padding">
                    <Controller
                      as={
                        <label>
                          Email*
                          <Input />
                        </label>
                      }
                      name="email"
                      control={control}
                    />
                    {errors.email && (
                      <span className="invalid">{errors.email.message}</span>
                    )}
                  </div>
                </Col>
              </Row>
              <Divider />
              <Button
                disabled={loading}
                type="primary"
                htmlType="submit"
                style={{float: "right"}}
              >
                {loadingText}
              </Button>
            </form>
          </div>
        </Card>
      </Content>
    </Layout>
  );
}
