import {Col, Layout, Row, Avatar, Menu, Dropdown} from "antd";
import "./mainPage.css";
import OnsorLogo from "../assets/images/onsor-icon.png";
import {UserOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
const {Header} = Layout;

const handleClick = (history) => {
  localStorage.clear();
  history.push("/login");
};

const menu = (history) => {
  return (
    <Menu style={{width: 100, float: "right"}}>
      <Menu.Item key="0" onClick={() => handleClick(history)}>
        Logout
      </Menu.Item>
    </Menu>
  );
};

export default function MyHeader() {
  const history = useHistory();
  return (
    <Header className="page-header">
      <Row className="header-content">
        <Col span="12">
          <img src={OnsorLogo} alt="Onsor logo" /> <span> Service Provider Platform</span>
        </Col>
        <Col span="12" style={{textAlign: "right"}}>
          <Dropdown overlay={() => menu(history)} trigger={["hover"]}>
            <div onClick={(e) => e.preventDefault()} style={{cursor: "pointer"}}>
              <Avatar icon={<UserOutlined />} />
              <span style={{marginLeft: 10, marginRight: 4}}>
                {localStorage.getItem("name")}
              </span>
            </div>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
}
