import api from "../api";

export const getInventories = async () => {
  return await api.get("/inventories");
};

export const getShopInventories = async (id) => {
  return await api.get(`/inventories/owners/${id}`);
};

export const getMyPartsList = async (ticketId) => {
  return api.get(`/inventories/owners/self?ticketId=${ticketId}`);
};

export const getMyPartsListFromCreate = async () => {
  return api.get(`/inventories/owners/self?from=create`);
};

export const createSparePart = async (data) => {
  return api.post(`/inventories`, {
    name: data.sparePartName,
    description: data.description,
    sku: data.sku,
    image_guid: data.file?.length > 0 ? data.file[0].guid : null,
    inventory_type_id: data.inventoryTypes,
    price: parseFloat(data.price),
  });
};

export const assignSparePart = async (data) => {
  let assignment = data.shops.map((item) => {
    return {shop_id: item.element.id, quantity: parseInt(item.quantity)};
  });
  return api.post(`/inventories/assignment/${data.id}`, assignment);
};

export const getPartsById = async (id) => {
  return api.get(`/inventories/${id}`);
};

export const getPartsAssignmentList = async (id) => {
  return api.get(`/inventories/assignment/${id}`);
};

export const disablePart = async (id) => {
  return await api.put(`/inventories/${id}/status`, {
    is_active: false,
  });
};

export const enablePart = async (id) => {
  return await api.put(`/inventories/${id}/status`, {
    is_active: true,
  });
};

export const editSparePart = async (data) => {
  return api.put(`/inventories/${data.id}`, {
    name: data.sparePartName,
    description: data.description,
    sku: data.sku,
    image_guid: data.file?.length > 0 ? data.file[0].guid : null,
    inventory_type_id: data.inventoryTypes,
    price: parseFloat(data.price),
  });
};
