import dotenv from "dotenv";
import axios from "axios";
import {getToken} from "./utils/token";

dotenv.config();

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    Pragma: "no-cache",
    "Cache-control": "no-cache",
    timeout: 1000,
  },
});

api.interceptors.request.use(function (config) {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
