import api from "../api";

export const disableUser = async (id) => {
  return await api.put("/users/action", {
    user_id: id,
    is_enabled: false,
  });
};

export const enableUser = async (id) => {
  return await api.put("/users/action", {
    user_id: id,
    is_enabled: true,
  });
};

export const rejectOwner = async (id, note) => {
  return await api.put(`/users/owners/${id}`, {
    is_approved: 2,
    reject_note: note,
  });
};

export const approveOwner = async (id) => {
  return await api.put(`/users/owners/${id}`, {
    is_approved: 1,
  });
};

export const getMyProfile = async () => {
  return await api.get(`/users/my-profile`);
};
